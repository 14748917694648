import { redirect } from 'redux-first-router'

export const GOAL_PLANNER = '/planner'
export const GOAL_PLANNER_GOAL_TYPE = {
  path: '/planner/:goalType',
}
const TRANSACTION = '/transaction/:id'
const BILLING_PLAN_SELECT = '/plans/select'
const BILLING_PLAN_CONFIRM = '/plans/confirm'
const MY_TEAM = '/my-team'
export const INVITE_A_FRIEND = '/invite-a-friend'

const TRANSITION_MEMBERSHIP_MONEY_GOALS = '/transition/membership/setup/money-goals'
const PWM_BLACKDIAMOND_MEMBERSHIP_CHOICE = '/pwm-and-digital'

const CAREER_GOALS_QUESTIONS = '/career-goals-question'
export const CAREER_GOALS_ANSWER_ROOT = '/career-goals-answer'
const CAREER_GOALS_ANSWER = `${CAREER_GOALS_ANSWER_ROOT}/:selectedCareerGoal`
const CHAT_INTRO = '/chat-intro'

// only redirect logic for PWM - non PWM routes go to where they were previously
export const REDIRECT = '/redirect'

// supports legacy url sent on emails
const RESTRICTED = {
  path: '/restricted',
  thunk: (dispatch) => {
    dispatch(
      redirect({
        type: 'DOCUMENT_UPLOADER',
        payload: { type: 'identity' },
      })
    )
  },
}

const TRANSACTIONS_SUMMARY = '/investing-transactions/:account?'

// Banking maintenance error
const BANKING_MAINTENANCE_MESSAGE = '/banking/maintenance'

export const miscFraudRestrictedRoutes = {
  GOAL_PLANNER,
  GOAL_PLANNER_GOAL_TYPE,
}

export default {
  BANKING_MAINTENANCE_MESSAGE,
  GOAL_PLANNER,
  GOAL_PLANNER_GOAL_TYPE,
  TRANSACTION,
  BILLING_PLAN_SELECT,
  BILLING_PLAN_CONFIRM,
  MY_TEAM,
  RESTRICTED,
  TRANSITION_MEMBERSHIP_MONEY_GOALS,
  PWM_BLACKDIAMOND_MEMBERSHIP_CHOICE,
  TRANSACTIONS_SUMMARY,
  INVITE_A_FRIEND,
  CAREER_GOALS_QUESTIONS,
  CAREER_GOALS_ANSWER_ROOT,
  CAREER_GOALS_ANSWER,
  CHAT_INTRO,
}
