import update from 'immutability-helper'
import * as C from 'app/actions/actionTypes'

const defaultState = {
  goals: [],
  accounts: [],
  projections: {},
  models: {},
}

const draftPlan = (state = defaultState, action) => {
  switch (action.type) {
    case C.RESET_DRAFT: {
      return defaultState
    }

    case C.SET_DRAFT_GOAL: {
      const { goal } = action

      let newState = state

      const goalIndex = state.goals.findIndex(g => g.id === goal.id)
      if (goalIndex > -1) {
        newState = update(state, {
          goals: {
            $splice: [[goalIndex, 1, goal]],
          },
        })
      } else {
        newState = update(state, {
          goals: {
            $push: [goal],
          },
        })
      }

      return newState
    }

    case C.SET_DRAFT_ACCOUNT: {
      const { account } = action

      let newState = state

      const accountIndex = state.accounts.findIndex(a => a.id === account.id)
      if (accountIndex > -1) {
        newState = update(state, {
          accounts: {
            $splice: [[accountIndex, 1, account]],
          },
        })
      } else {
        newState = update(state, {
          accounts: {
            $push: [account],
          },
        })
      }

      return newState
    }

    case C.SET_DRAFT_PROJECTIONS: {
      const { projections, id } = action

      const newState = update(state, {
        projections: {
          [`${id}`]: {
            $set: projections,
          },
        },
      })

      return newState
    }

    case C.SET_DRAFT_MODEL: {
      const { model, id } = action

      const newState = update(state, {
        models: {
          [`${id}`]: {
            $set: model,
          },
        },
      })

      return newState
    }

    default:
      return state
  }
}

export default draftPlan
