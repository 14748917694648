import React from 'react'
import PropTypes from 'prop-types'

import Analytics from 'js/Analytics'

const ExternalLink = ({
  children,
  className,
  href,
  download,
  styles,
  testId,
  trackEvent,
  trackEventProperties,
  onClick,
}) => {
  const handleClick = () => {
    if (trackEvent) Analytics.track(trackEvent, trackEventProperties)
    onClick()
  }

  return (
    <a
      style={styles}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      className={className}
      data-test-id={testId}
      download={download}
      role="link"
      onClick={handleClick}>
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  download: PropTypes.bool,
  styles: PropTypes.object,
  testId: PropTypes.string,
  trackEvent: PropTypes.string,
  trackEventProperties: PropTypes.object,
  onClick: PropTypes.func,
}

ExternalLink.defaultProps = {
  className: '',
  download: false,
  trackEvent: undefined,
  styles: {},
  testId: '',
  trackEventProperties: {},
  onClick: () => {},
}

export default ExternalLink
