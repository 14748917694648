import React from 'react'
import PropTypes from 'prop-types'
import spacing from '../FooterSpacing.module.scss'
import classnames from 'classnames'

const FooterCopy = ({ children }) => {
  const classes = classnames(spacing.FooterCopy)

  return <div className={classes}>{children}</div>
}

export default FooterCopy

FooterCopy.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
