/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, all } from 'redux-saga/effects'

import ErrorHelper from 'js/ErrorHelper'

import { fetchForeignAccount } from 'app/requests/foreignAccount'

import {
  getForeignAccount,
  getForeignAccountFailed,
  setForeignAccount,
} from './reducer'
import { filterForeignAccounts } from './utils'

const notifyError = (error) => {
  if (error.response) {
    error.response.json().then((errors) => {
      ErrorHelper.notifyError(errors)
    })
  } else {
    ErrorHelper.notifyError(error)
  }
}

export const onGetForeignAccount = function* () {
  try {
    const { data } = yield call(fetchForeignAccount)
    const foreignAccount = filterForeignAccounts(data)

    yield put(setForeignAccount(foreignAccount))
  } catch (e) {
    notifyError(e)
    yield put(getForeignAccountFailed)
  }
}

const watchForeignAccount = function* () {
  yield all([
    takeLatest(getForeignAccount.type, onGetForeignAccount),
  ])
}

export default watchForeignAccount
