import update from 'immutability-helper'
import * as C from 'app/actions/actionTypes'

const defaultState = {
  investmentPlan: null,
  investmentPlanInfo: window.financial_plan_info,
  isFetching: false,
  investmentPlanLoaded: false,
  failedMicrodeposits: false,
  externalAccounts: [],
  externalAccountsLoaded: false,
  goals: [],
  goalsLoaded: false,
  accounts: [],
  accountsLoaded: false,
  models: {},
  projections: {},
}

const investmentPlan = (state = defaultState, action) => {
  switch (action.type) {
    case C.FETCH_INVESTMENT_PLAN: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case C.SET_INVESTMENT_PLAN: {
      const { plan } = action

      const newState = update(state, {
        investmentPlan: {
          $set: plan,
        },
        investmentPlanLoaded: {
          $set: true,
        },
        isFetching: {
          $set: false,
        },
      })

      return newState
    }

    case C.SET_INVESTMENT_PLAN_INFO: {
      const { info } = action

      const newState = update(state, {
        investmentPlanInfo: {
          $set: info,
        },
      })

      return newState
    }

    case C.SET_EXTERNAL_ACCOUNTS: {
      const { externalAccounts } = action

      return {
        ...state,
        externalAccounts,
        externalAccountsLoaded: true,
      }
    }

    case C.SET_EXTERNAL_ACCOUNT: {
      const { externalAccount } = action

      let newState = state

      const accountIndex = state.externalAccounts.findIndex((a) => a.id === externalAccount.id)
      if (accountIndex > -1) {
        newState = update(state, {
          externalAccounts: {
            $splice: [[accountIndex, 1, externalAccount]],
          },
        })
      } else {
        newState = update(state, {
          externalAccounts: {
            $push: [externalAccount],
          },
        })
      }

      return newState
    }

    case C.REMOVE_EXTERNAL_ACCOUNT: {
      const { id } = action

      let newState = state

      const accountIndex = state.externalAccounts.findIndex((a) => a.id === id)
      if (accountIndex > -1) {
        newState = update(state, {
          externalAccounts: {
            $splice: [[accountIndex, 1]],
          },
        })
      }

      return newState
    }

    case C.SET_GOALS: {
      const { goals } = action

      return {
        ...state,
        goals,
        goalsLoaded: true,
      }
    }

    case C.ADD_GOAL_BY_TYPE: {
      const { goal } = action

      let newState = state

      const goalIndex = state.goals.findIndex((g) => g.goalType === goal.goalType)
      if (goalIndex > -1) {
        const addedGoal = {
          ...state.goals[goalIndex],
          ...goal,
        }
        delete addedGoal.deleted
        newState = update(state, {
          goals: {
            $splice: [[goalIndex, 1, addedGoal]],
          },
        })
      } else {
        newState = update(state, {
          goals: {
            $push: [goal],
          },
        })
      }

      return newState
    }

    case C.REMOVE_GOAL_BY_TYPE: {
      const { goalType } = action

      let newState = state

      const goalIndex = state.goals.findIndex((g) => g.goalType === goalType)
      if (goalIndex > -1) {
        newState = update(state, {
          goals: {
            $splice: [[goalIndex, 1]],
          },
        })
      }

      return newState
    }

    case C.REMOVE_GOAL: {
      const { id } = action

      let newState = state

      const goalIndex = state.goals.findIndex((g) => g.id === id)
      if (goalIndex > -1) {
        const deletedGoal = {
          ...state.goals[goalIndex],
          deleted: true,
        }
        newState = update(state, {
          goals: {
            $splice: [[goalIndex, 1, deletedGoal]],
          },
        })
      }

      return newState
    }

    case C.MOVE_GOAL: {
      const { id, afterId } = action

      let newState = state

      const movingGoal = state.goals.find((g) => g.id === id)
      const movingGoalIndex = state.goals.findIndex((g) => g.id === id)
      const afterGoalIndex = state.goals.findIndex((g) => g.id === afterId)

      newState = update(newState, {
        goals: {
          $splice: [
            [movingGoalIndex, 1],
            [afterGoalIndex, 0, movingGoal],
          ],
        },
      })

      return newState
    }

    case C.SET_GOAL: {
      const { goal } = action

      let newState = state

      const goalIndex = state.goals.findIndex((g) => g.id === goal.id)
      if (goalIndex > -1) {
        newState = update(state, {
          goals: {
            $splice: [[goalIndex, 1, goal]],
          },
        })
      } else {
        newState = update(state, {
          goals: {
            $push: [goal],
          },
        })
      }

      return newState
    }

    case C.RESET_GOAL: {
      const { id, accountIds } = action

      let newState = state

      const goalIndex = state.goals.findIndex((g) => g.id === id)
      if (goalIndex > -1) {
        newState = update(newState, {
          goals: {
            $splice: [[goalIndex, 1]],
          },
          models: {
            [`${id}`]: {
              $set: null,
            },
          },
        })

        accountIds.forEach((accountId) => {
          newState = update(newState, {
            models: {
              [`${accountId}`]: {
                $set: null,
              },
            },
          })
        })
      }

      return newState
    }

    case C.SET_ACCOUNTS: {
      const { accounts } = action

      return {
        ...state,
        accounts,
        accountsLoaded: true,
      }
    }

    case C.SET_ACCOUNT: {
      const { account } = action

      let newState = state

      const accountIndex = state.accounts.findIndex((a) => a.id === account.id)
      if (accountIndex > -1) {
        newState = update(state, {
          accounts: {
            $splice: [[accountIndex, 1, account]],
          },
        })
      } else {
        newState = update(state, {
          accounts: {
            $push: [account],
          },
        })
      }

      return newState
    }

    case C.UPDATE_GOAL_DATA: {
      const { goal } = action
      const { investmentPlan } = state
      let newState = state

      const goalIndex = investmentPlan.selected_goals.findIndex((g) => g.goal_id === goal.goal_id)

      if (goalIndex > -1) {
        newState = update(state, {
          investmentPlan: {
            $set: update(investmentPlan, {
              selected_goals: {
                $splice: [[goalIndex, 1, goal]],
              },
            }),
          },
        })
      }

      return newState
    }

    case C.SET_MODEL: {
      const { model, id } = action

      const newState = update(state, {
        models: {
          [`${id}`]: {
            $set: model,
          },
        },
      })

      return newState
    }

    case C.SET_PROJECTIONS: {
      const { projections, id } = action

      const newState = update(state, {
        projections: {
          [`${id}`]: {
            $set: projections,
          },
        },
      })

      return newState
    }

    case C.RESET_PROJECTIONS: {
      const newState = update(state, {
        projections: {
          $set: defaultState.projections,
        },
      })

      return newState
    }

    default:
      return state
  }
}

export default investmentPlan
