const BW_GOAL_INVESTING_ROOT = '/investing/setup'
export const RECOMMENDED_ACTION = '/activation/recommended-action'
export const ONE_TIME_DEPOSIT = `${BW_GOAL_INVESTING_ROOT}/one-time-deposit`
export const MONTHLY_DEPOSIT = `${BW_GOAL_INVESTING_ROOT}/monthly-deposit`
export const GOAL_HORIZON = `${BW_GOAL_INVESTING_ROOT}/timeline`
export const GOAL_EDIT_OVERVIEW = `${BW_GOAL_INVESTING_ROOT}/goal-edit-overview`

const routesMap = {
  RECOMMENDED_ACTION,
  ONE_TIME_DEPOSIT,
  MONTHLY_DEPOSIT,
  GOAL_HORIZON,
  GOAL_EDIT_OVERVIEW,
}

export default routesMap
