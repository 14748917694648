/* eslint no-console: "off", no-undef: "off" */

import Bugsnag from '@bugsnag/js'
import { EVError } from 'app/content/errorTypes'
import { BREADCRUMB_TYPES, REGEX_BLACKLIST } from 'app/content/bugsnag'
/**
 * Error reporting helper for JS/frontend errors.
 * Provides functions for reporting errors to various services, as well as fns
 * to initialize and set up these services.
 *
 * Note that this is exporting a single instance of ErrorHelper so all imports
 * import from the same, single global instance, instead of creating a new
 * instance of ErrorHelper every time.
 * (https://k94n.com/es6-modules-single-instance-pattern)
 *
 * Best Practices:
 * - Wrap calls to external services in a check to see if the
 * service is loaded, e.g `if (window.Bugsnag)`. This avoids excessive
 * "[service] is undefined" errors in envs that are not running the service,
 * e.g. localhost.
 *
 * Tips:
 * - To test external service calls, simply run calls in your browser console.
 * e.g. `Bugsnag.notify(error, opts)`
 *
 */

const bugsnagConfig = {
  apiKey: '466be3979b14063a9b8a784bdf513c31',
}

/**
 * Rails envs you want to enable a specified service for.
 * To enable a service, add its Rails env string to the relevant array.
 */
const environments = {
  bugsnag: ['staging', 'production', 'testone'],
}

class ErrorHelper {
  /**
   * Returns true or false for whether a service should be enabled for a
   * certain Rails environment.
   * Note that this function is for determining whether we WANT this service
   * to be enabled, not for whether it actually IS enabled.
   *
   * @param {string} service - one of Bugsnag, or others in future
   * @param {string} railsEnv - one of development, testone, staging,
   * or production
   * @returns {bool} true if the service should be enabled for the current
   * rails env, or false if service should be disabled instead
   *
   */
  shouldEnableForEnvironment(service, railsEnv) {
    return environments[service].indexOf(railsEnv) !== -1
  }

  /**
   * Returns true if the service is enabled in the app, or false if not.
   *
   * @param {string} service - one of Bugsnag, or others in future
   *
   */
  serviceIsEnabled(service) {
    // Bugsnag is enabled if the window has a Bugsnag object, meaning the
    // notifier module has successfully loaded, and that object has an API Key,
    // meaning Bugsnag has successfully initialized.
    if (service && service.toLowerCase() === 'bugsnag') {
      return window.bugsnagClient !== undefined
    }
  }

  /**
   * Notifies a custom error to Bugsnag, defaults to EVError class
   * https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/
   *
   * @type {string|Error} error
   * @type {object} metadata - optional, nested mapping of key/value pairs
   * with additional diagnostic information. In Bugsnag, this will be displayed
   * in a tab in the error labeled "Metadata" with a row for each key/value.
   * @type {string} severity (optional) - one of "info", "warning", or "error"
   */
  notifyError(error, metaData, severity) {
    const errorObj = error instanceof Error ? error : new EVError(error)

    if (this.serviceIsEnabled('Bugsnag')) {
      try {
        window.bugsnagClient.notify(errorObj, (event) => {
          event.addMetadata('metadata', metaData)
          event.severity = severity
        })
      } catch (err) {
        console.warn('Could not report error to Bugsnag', err)
      }
    }

    // Always print the error to console too
    console.error(errorObj, metaData)
  }

  /**
   * Initializes Bugsnag with correct API keys per env project.
   *
   */
  initBugsnag(railsEnv) {
    // If we want to enable Bugsnag, and the Bugsnag object is in window (i.e.
    // the notifier is successfully running), enable Bugsnag by configuring it
    if (this.shouldEnableForEnvironment('bugsnag', railsEnv) && Bugsnag) {
      try {
        window.bugsnagClient = Bugsnag.start({
          apiKey: bugsnagConfig.apiKey,
          autoBreadcrumbs: true,
          autoDetectErrors: false, // avoids busgnag reporting all failed Promises
          autoTrackSessions: true, // replaces autoCaptureSessions
          enabledBreadcrumbTypes: BREADCRUMB_TYPES, // replaces autoBreadcrumbs
          enabledReleaseStages: environments.bugsnag, // replaces notifyReleaseStages
          redactedKeys: REGEX_BLACKLIST, // masks sensitive information
          releaseStage: railsEnv,
          onError: (event) => {
            this.identifyUser(event)
          },
        })
      } catch (err) {
        console.log('error', err) // leave!
        this.notifyError('Could not set Bugsnag API key', err)
      }
    }
  }

  /**
   * Identifies a user by canonical ID to services.
   * Avoid use of any PII in production.
   *
   * @type {obj} user
   * @type {string} railsEnv
   */
  identifyUser(event) {
    if (window.user && this.serviceIsEnabled('Bugsnag')) {
      try {
        this.identifyUserToBugsnag(event, window.user, window.railsEnv)
      } catch (err) {
        this.notifyError('Failed to identify user to Bugsnag', err)
      }
    }
  }

  /**
   * Identifies user to Bugsnag, an exception monitoring tool.
   * Avoid use of any PII in production.
   * https://docs.bugsnag.com/platforms/javascript/#identifying-users
   *
   * @type {obj} user
   * @type {string} railsEnv
   */
  identifyUserToBugsnag(event, user, railsEnv) {
    const canonicalId = user.user_id
    const email = user.email || user.unconfirmedEmail

    const { bugsnagClient } = window

    if (railsEnv !== 'production') {
      // development, staging
      event.setUser(canonicalId, email)
    } else if (railsEnv === 'production') {
      event.setUser(canonicalId)
    }
  }

  /**
   * Provide additional diagnostic data to Bugsnag error reports for any
   * unhandled exceptions.
   * Note that if you want to provide custom metadata and you're already
   * calling notifyError above, just provide the metadata in that function.
   * provideCustomDiagnostics is meant as a setup step to ensure that any
   * unhandled exceptions (i.e. errors that are not being custom reported by
   * notifyError) can still capture the metadata you want.
   *
   * @type {object} customMetadata - object with the custom diagnostics you want
   * the Bugsnag bug report to capture. (Please see note on Formatting below)
   * The `onError` call (previously `beforeNotify`) will ensure that your desired diagnostics are
   * calculated at the point when the exception occurs.
   *
   * Formatting: Each top-level key in your custom metadata object will become a new tab
   * in a Bugsnag error report; therefore, in order to avoid cluttering it up
   * with too many tabs, try to put your data under few, descriptive keys.
   *
   * e.g. { APIRequestInfo: { info1: x, info2: y } } will create a new tab
   * named "APIRequestInfo" and list info1 & info2 as rows inside it.
   * https://docs.bugsnag.com/platforms/javascript/#sending-diagnostic-data
   *
   * Note: Beware that if any keys you provide already exist on the Bugsnag
   * metadata object, they will be overwritten, not merged!
   */
  provideCustomDiagnostics(customMetadata) {
    if (this.serviceIsEnabled('Bugsnag')) {
      window.bugsnagClient.onError = (event) => {
        event.updateMetadata('diagnostics', customMetadata)
      }
    }
  }

  /**
   * Leaves a breadcrumb for error logs.
   * Breadcrumbs are a log of user actions leading up to an error.
   * Bugsnag: https://docs.bugsnag.com/platforms/javascript/customizing-breadcrumbs/#adding-manual-breadcrumbs
   *
   * @type {string} msg
   * @type {object} metadata - any additional info you want to provide.
   * For Bugsnag, metadata must be only one lvl deep & values no more than
   * 140 chars each.
   */
  leaveBreadcrumb(msg, metadata) {
    if (this.serviceIsEnabled('Bugsnag')) {
      window.bugsnagClient.leaveBreadcrumb(msg, metadata)
    }
  }
}

export default new ErrorHelper()
