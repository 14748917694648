import { EForeignAccountStatus } from 'app/redux/foreignAccount/schema'

const {
  ACTIVE,
  PENDING_MANUAL,
  PENDING_MANUAL_IDENTITY_REVIEW,
  PENDING_AUTOMATIC_IDENTITY_REVIEW,
  AWAITING_CLIENT_DOC_UPLOAD,
  MISSING_ACH_INFORMATION,
} = EForeignAccountStatus

export const isForeignAccount = (account) => account && account.type === 'foreignAccounts'

export const getForeignAccountLabel = (foreignAccount) => {
  if (!foreignAccount) return null

  if (shouldDisplayBankInformation(foreignAccount)) {
    const { institution, maskedAccountNumber } = foreignAccount
    let name = 'External Bank'

    if (institution) {
      name = institution
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    }

    if (maskedAccountNumber) {
      const lastFour = maskedAccountNumber.substr(maskedAccountNumber.length - 4)
      name += ` (...${lastFour})`
    }

    return name
  } else return 'Unverified Bank'
}

export const hasForeignAccountData = (fa) => fa && !!Object.keys(fa).length

const checkFaStatus = (fa, statuses) => fa && statuses.includes(fa.status)

export const shouldDisplayBankInformation = (fa) =>
  checkFaStatus(fa, [
    PENDING_AUTOMATIC_IDENTITY_REVIEW,
    PENDING_MANUAL_IDENTITY_REVIEW,
    AWAITING_CLIENT_DOC_UPLOAD,
    ACTIVE,
  ])

export const isVerifiedForeignAccount = (fa) => checkFaStatus(fa, [ACTIVE])

export const hasUnverifiedForeignAccount = (fa) => fa && fa.status !== ACTIVE

export const isPendingForeignAccount = (fa) => checkFaStatus(fa, [PENDING_MANUAL])

export const doesRequirePlaidDocument = (fa) => checkFaStatus(fa, [AWAITING_CLIENT_DOC_UPLOAD])

export const isMissingAchInformation = (fa) => checkFaStatus(fa, [MISSING_ACH_INFORMATION])

export const plaidVerificationInProgress = (fa) =>
  checkFaStatus(fa, [PENDING_MANUAL_IDENTITY_REVIEW, PENDING_AUTOMATIC_IDENTITY_REVIEW])

export const isInManualReview = (fa) => checkFaStatus(fa, [PENDING_MANUAL_IDENTITY_REVIEW])
