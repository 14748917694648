/* eslint-disable no-case-declarations */
import { all, select, put, take, takeEvery, call } from 'redux-saga/effects'
import { redirect, push } from 'redux-first-router'

import { SET_USER, TOGGLE_PRODUCT_INTRO } from 'app/actions/actionTypes'
import { setRouteData } from 'app/redux/routes/reducer'
import routes, { routesIndex as R } from 'app/routes/index'
import EXCLUSIONS, { getExclusionsByUser } from 'app/utils/membershipExclusions'

import GOAL_TYPES from 'app/content/goalTypes'

import { ONBOARDING_INVESTING_EDIT_GOAL_ROOT } from 'app/routes/onboardingInvestingRoutes'

const handleGoalSelectionAccess = function* () {
  let {
    auth: { user },
  } = yield select()

  if (!user) {
    yield take(SET_USER)
    ;({
      auth: { user },
    } = yield select())
  }

  const restricted = getExclusionsByUser(user, EXCLUSIONS.ADD_GOAL)

  if (restricted) {
    yield call(push, `${ONBOARDING_INVESTING_EDIT_GOAL_ROOT}/${GOAL_TYPES.BUILD_WEALTH_GOAL}`)
  }
}

const handleToggleRecommendedAction = function* (action) {
  if (action && action.payload && action.payload.useWindowRedirect) {
    // this is needed to prevent the user for going back into the
    // onboarding flow once she reaches the Recommended Product Screen.
    // If user clicks back on browser, she will be redirected to the dashboard
    window.location.href = routes.ACTIVATION_EXPERIENCE

    return
  }

  yield put(setRouteData({ route: 'ACTIVATION_EXPERIENCE' }))
  yield put(
    redirect({
      type: R.ACTIVATION_EXPERIENCE,
    })
  )
}

const watchMembership = function* () {
  yield all([
    takeEvery(R.ONBOARDING_INVESTING_GOALS, handleGoalSelectionAccess),
    takeEvery(TOGGLE_PRODUCT_INTRO, handleToggleRecommendedAction),
  ])
}

export default watchMembership
