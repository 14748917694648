import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  railsEnv: undefined,
  windowMode: 'screen-xxs',
  isMobile: true, // to be depreacted. use selector instead!
  isTablet: false, // to be depreacted. use selector instead!
  isDesktop: false, // to be depreacted. use selector instead!
  media: {
    isMobilePt: true,
    isMobile: true,
    isTabletPortrait: false,
    isSmallDevice: false,
    isLargeDevice: false, // to replace current isDesktop (includes tablet landscape!)
    isRealDesktop: false,
  },
  windowWidth: 0,
  windowHeight: 0,
  pageNavOpen: false,
  showDashboardLoader: false,
  siteNavOpen: false,
  siteNavIndex: 0,
  loaderType: null,
  hasFPSError: false,
  flashMessage: window.flashMessages ? window.flashMessages.notice : null,
  restrictionModalsSeen: [],
  lastInviteAFriendModalSession: null,
  shouldShowInviteAFriendModal: false,
  sessionCount: 0,
}
// legacy media
const windowModes = {
  0: 'screen-xxs',
  480: 'screen-xs',
  768: 'screen-sm',
  1024: 'screen-md',
  1200: 'screen-lg',
  1440: 'screen-xl',
}
const mobileNavModes = ['screen-xxs', 'screen-xs']
const tabletNavModes = ['screen-sm']
const desktopNavModes = ['screen-md', 'screen-lg', 'screen-xl']

// design system media
const ds_media = {
  XS: 480,
  SM: 768,
  MD: 1024,
  LG: 1200, // desktop
  XL: 1440,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setEnvironment(state, action) {
      const railsEnv = action.payload

      return {
        ...state,
        railsEnv,
      }
    },
    setWindowMode(state, action) {
      const dimensions = action.payload

      // legacy
      let windowMode = windowModes[0] // Default to smallest size
      for (const width in windowModes) {
        if (dimensions.w >= width) {
          windowMode = windowModes[width]
        }
      }
      // design system
      const media = {
        isMobilePt: dimensions.w < ds_media.XS,
        isMobile: dimensions.w < ds_media.SM,
        isTabletPortrait: dimensions.w >= ds_media.SM && dimensions.w < ds_media.MD,
        isSmallDevice: dimensions.w < ds_media.MD,
        isLargeDevice: dimensions.w >= ds_media.MD, // to replace current isDesktop. includes tablet landscape
        isRealDesktop: dimensions.w >= ds_media.LG,
      }

      return {
        ...state,
        windowMode,
        isMobile: mobileNavModes.indexOf(windowMode) > -1,
        isTablet: tabletNavModes.indexOf(windowMode) > -1,
        isDesktop: desktopNavModes.indexOf(windowMode) > -1,
        media,
        windowHeight: dimensions.h,
        windowWidth: dimensions.w,
      }
    },
    openSiteNav(state, action) {
      const scrollTop = action.payload

      return {
        ...state,
        siteNavIndex: 0,
        siteNavOpen: true,
        scrollTop,
      }
    },
    updateSiteNav(state, action) {
      const siteNavIndex = action.payload

      return {
        ...state,
        siteNavIndex,
        siteNavOpen: true,
      }
    },
    closeSiteNav(state) {
      return {
        ...state,
        siteNavOpen: false,
      }
    },
    openPageNav(state) {
      return {
        ...state,
        pageNavOpen: true,
      }
    },
    closePageNav(state) {
      return {
        ...state,
        pageNavOpen: false,
      }
    },
    setFlashMessage(state, action) {
      const message = action.payload

      return {
        ...state,
        flashMessage: message,
      }
    },
    setBannerMessage(state, action) {
      const message = action.payload

      return {
        ...state,
        bannerMessage: message,
      }
    },
    showLoader(state, action) {
      const loaderType = action.payload

      return {
        ...state,
        showDashboardLoader: true,
        loaderType,
      }
    },
    hideLoader(state) {
      return {
        ...state,
        showDashboardLoader: false,
        loaderType: null,
      }
    },
    showFPSError(state) {
      return {
        ...state,
        hasFPSError: true,
      }
    },
    hideFPSError(state) {
      return {
        ...state,
        hasFPSError: false,
        showDashboardLoader: false,
        loaderType: null,
      }
    },
    markRestrictionModalAsSeen(state, action) {
      state.restrictionModalsSeen.push(action.payload)
    },
    unmarkRestrictionModalsAsSeen(state) {
      state.restrictionModalsSeen = []
    },
    updateShouldShowInviteAFriendModal(state) {
      return {
        ...state,
        shouldShowInviteAFriendModal: false, // Changes for https://ellevest.atlassian.net/browse/ELVST-35115
      }
    },
    updateLastInviteAFriendModalSession(state, action) {
      return {
        ...state,
        lastInviteAFriendModalSession: action.payload,
      }
    },
    setUserSessionCount(state) {
      return {
        ...state,
        sessionCount: state.sessionCount + 1,
      }
    },
  },
})

export const {
  setEnvironment,
  setWindowMode,
  openSiteNav,
  updateSiteNav,
  closeSiteNav,
  openPageNav,
  closePageNav,
  setFlashMessage,
  setBannerMessage,
  showLoader,
  hideLoader,
  showFPSError,
  hideFPSError,
  markRestrictionModalAsSeen,
  unmarkRestrictionModalsAsSeen,
  updateLastInviteAFriendModalSession,
  updateShouldShowInviteAFriendModal,
  setUserSessionCount,
} = systemSlice.actions

export default systemSlice.reducer
