import { ISleeveHistoryRecord, ISleeveHistoryDatum } from './schema'

export const addDaysToDate = (initialDate: Date, days: number): Date => {
  const date = new Date(initialDate.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

export const differenceInDays = (date1: Date, date2: Date): number => {
  const millisecondsPerDay = 1000 * 3600 * 24
  const differenceInMilliseconds = date2.getTime() - date1.getTime()

  // Need to round because with daylight savings time changes,
  // the days me be an hour longer or shorter.
  return Math.round(differenceInMilliseconds / millisecondsPerDay)
}

export const formatSleeveHistoryRecord = (
  sleeveHistoryRecord: ISleeveHistoryRecord
): ISleeveHistoryDatum => {
  const { attributes } = sleeveHistoryRecord
  const { date, marketValue } = attributes
  const xValue = stringToDate(date)
  const yValue = parseFloat(marketValue)

  return { xValue, yValue }
}

export const formatSleeveHistoryRecordArray = (
  sleeveHistoryRecords: Array<ISleeveHistoryRecord>
): Array<ISleeveHistoryDatum> => {
  const output = []

  sleeveHistoryRecords.forEach((record, index) => {
    if (index === 0) {
      output.push(formatSleeveHistoryRecord(record))
      return
    }

    // Check if days are missing.
    // This is because the API only returns data for business days,
    // but the grabber feels wonky when dates are missing.
    const previousDatum = output[output.length - 1]
    const previousDate = previousDatum.xValue
    const expectedDate = addDaysToDate(previousDate, 1)
    const recordDate = stringToDate(record.attributes.date)

    // If the two dates are off by more than an hour off,
    // then its because there are days missing between them.
    // Note: The dates may be an hour off because of daylight savings time changes.
    // The line below checks if they are more than 12 hours apart instead
    // of 1 hour, just to be super clear.
    const isMissingDates = Math.abs(recordDate.getTime() - expectedDate.getTime()) > 43200000
    if (!isMissingDates) {
      output.push(formatSleeveHistoryRecord(record))
      return
    }

    // If days are missing, add them in.
    // Re-use the previous day's yValue
    const daysMissing = differenceInDays(expectedDate, recordDate)

    for (let i = 0; i < daysMissing; i++) {
      output.push({
        xValue: addDaysToDate(previousDatum.xValue, i + 1),
        yValue: previousDatum.yValue,
      })
    }

    output.push(formatSleeveHistoryRecord(record))
  })

  return output
}

export const stringToDate = (dateString: string): Date => new Date(`${dateString}T00:00:00`)
