/**
 * Account Type constant definitions
 * label - text displayed in the UI
 * key - how the account type is represented on the B/E
 */

const ACCOUNT_401K = {
  label: 'Traditional 401(k)',
  key: 'account_401k',
}

const TRADITIONAL_401K_ACCOUNT = {
  label: 'Traditional 401(k)',
  key: 'traditional_401k_account',
}

const ROTH_401K_ACCOUNT = {
  label: 'Roth 401(k)',
  key: 'roth_401k_account',
}

const ACCOUNT_403B = {
  label: '403(b)',
  key: 'account_403b',
}

export const ROTH_IRA_ACCOUNT = {
  label: 'Roth IRA',
  key: 'roth_ira_account',
}

export const TRADITIONAL_IRA_ACCOUNT = {
  label: 'Traditional IRA',
  key: 'traditional_ira_account',
}

export const SEP_IRA_ACCOUNT = {
  label: 'SEP IRA',
  key: 'sep_ira_account',
}

const ACCOUNT_529 = {
  label: '529',
  key: 'account_529',
}

export const INVESTMENT_ACCOUNT = {
  label: 'Investment Account',
  key: 'investment_account',
}

const CHECKING_ACCOUNT = {
  label: 'Checking Account',
  key: 'checking_account',
}

// not for banking
const SAVINGS_ACCOUNT = {
  label: 'Savings Account',
  key: 'savings_account',
}

const CREDIT_UNION_ACCOUNT = {
  label: 'Credit Union',
  key: 'credit_union_account',
}

const OTHER_TAX_DEFERRED_ACCOUNT = {
  label: 'Other Tax Deferred Account',
  key: 'other_tax_deferred_account',
}

const OTHER_TAXABLE_ACCOUNT = {
  label: 'Other Taxable Account',
  key: 'other_taxable_account',
}

export const BROKERAGE_ACCOUNT = {
  label: 'Investment Account',
  key: 'brokerage_account',
}

export const EXTERNAL_ACCOUNT = {
  label: 'Outside Account',
  key: 'outside_account',
}

const ACCOUNT_40X = {
  label: '401(k)/403(b)',
  key: 'account_40x',
}

export const FOREIGN_ACCOUNT = {
  label: 'Bank Account',
  key: 'foreignAccounts',
}

export const index = [
  ACCOUNT_401K,
  ACCOUNT_403B,
  TRADITIONAL_401K_ACCOUNT,
  ROTH_401K_ACCOUNT,
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  ACCOUNT_529,
  INVESTMENT_ACCOUNT,
  CHECKING_ACCOUNT,
  SAVINGS_ACCOUNT,
  CREDIT_UNION_ACCOUNT,
  OTHER_TAX_DEFERRED_ACCOUNT,
  OTHER_TAXABLE_ACCOUNT,
  BROKERAGE_ACCOUNT,
  ACCOUNT_40X,
  FOREIGN_ACCOUNT,
]

export const all = {
  ACCOUNT_401K,
  TRADITIONAL_401K_ACCOUNT,
  ROTH_401K_ACCOUNT,
  ACCOUNT_403B,
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  ACCOUNT_529,
  INVESTMENT_ACCOUNT,
  CHECKING_ACCOUNT,
  SAVINGS_ACCOUNT,
  CREDIT_UNION_ACCOUNT,
  OTHER_TAX_DEFERRED_ACCOUNT,
  OTHER_TAXABLE_ACCOUNT,
  BROKERAGE_ACCOUNT,
  ACCOUNT_40X,
}

// this list is from belle-js but uses the constants in this file
// consider using one of the other account type lists which differ slightly
export const validFolioAccounts = {
  [ACCOUNT_401K.key]: ACCOUNT_401K.label,
  [ACCOUNT_403B.key]: ACCOUNT_403B.label,
  [ROTH_401K_ACCOUNT.key]: ROTH_401K_ACCOUNT.label,
  [TRADITIONAL_401K_ACCOUNT.key]: TRADITIONAL_401K_ACCOUNT.label,
  [ROTH_IRA_ACCOUNT.key]: ROTH_IRA_ACCOUNT.label,
  [TRADITIONAL_IRA_ACCOUNT.key]: TRADITIONAL_IRA_ACCOUNT.label,
  [SEP_IRA_ACCOUNT.key]: SEP_IRA_ACCOUNT.label,
  [ACCOUNT_529.key]: ACCOUNT_529.label,
  [INVESTMENT_ACCOUNT.key]: INVESTMENT_ACCOUNT.label,
  [CHECKING_ACCOUNT.key]: CHECKING_ACCOUNT.label,
  [SAVINGS_ACCOUNT.key]: SAVINGS_ACCOUNT.label,
  [CREDIT_UNION_ACCOUNT.key]: CREDIT_UNION_ACCOUNT.label,
  [OTHER_TAX_DEFERRED_ACCOUNT.key]: OTHER_TAX_DEFERRED_ACCOUNT.label,
  [OTHER_TAXABLE_ACCOUNT.key]: OTHER_TAXABLE_ACCOUNT.label,
  [BROKERAGE_ACCOUNT.key]: BROKERAGE_ACCOUNT.label,
  [ACCOUNT_40X.key]: ACCOUNT_40X.label,
}

export const ellevest = {
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  INVESTMENT_ACCOUNT,
}

export const folio = {
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  BROKERAGE_ACCOUNT,
}

export const external = {
  TRADITIONAL_401K_ACCOUNT,
  ROTH_401K_ACCOUNT,
  ACCOUNT_403B,
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  ACCOUNT_529,
  INVESTMENT_ACCOUNT,
  CHECKING_ACCOUNT,
  SAVINGS_ACCOUNT,
  CREDIT_UNION_ACCOUNT,
  OTHER_TAX_DEFERRED_ACCOUNT,
  OTHER_TAXABLE_ACCOUNT,
}

export const retirementExternal = [
  TRADITIONAL_401K_ACCOUNT,
  ROTH_401K_ACCOUNT,
  ACCOUNT_403B,
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  OTHER_TAX_DEFERRED_ACCOUNT,
]

export const iraTransfer = {
  TRADITIONAL_IRA_ACCOUNT,
  ROTH_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
}

export const transferrable = [
  TRADITIONAL_401K_ACCOUNT,
  ROTH_401K_ACCOUNT,
  ACCOUNT_403B,
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
  INVESTMENT_ACCOUNT,
]

export const depositOrder = [
  TRADITIONAL_401K_ACCOUNT,
  ROTH_401K_ACCOUNT,
  ACCOUNT_403B,
  ROTH_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  OTHER_TAX_DEFERRED_ACCOUNT,
  ACCOUNT_529,
  CREDIT_UNION_ACCOUNT,
  INVESTMENT_ACCOUNT,
  SEP_IRA_ACCOUNT,
  OTHER_TAXABLE_ACCOUNT,
  SAVINGS_ACCOUNT,
  CHECKING_ACCOUNT,
]

export const employerAccounts = [
  TRADITIONAL_401K_ACCOUNT.key,
  ACCOUNT_403B.key,
  ROTH_401K_ACCOUNT.key,
]
