import makeRoutesIndex from 'app/routes/makeRoutesIndex'

const ACCOUNTS = '/accounts/:path?'
const ACCOUNTS_ROOT = '/accounts'

export const SEP_IRA_ACCOUNT = `${ACCOUNTS_ROOT}/sep_ira_account`
export const TRADITIONAL_IRA_ACCOUNT = `${ACCOUNTS_ROOT}/traditional_ira_account`
export const ROTH_IRA_ACCOUNT = `${ACCOUNTS_ROOT}/roth_ira_account`
export const INVESTMENT_ACCOUNT = `${ACCOUNTS_ROOT}/brokerage_account`

const routesMap = {
  ACCOUNTS,
  SEP_IRA_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  ROTH_IRA_ACCOUNT,
  INVESTMENT_ACCOUNT,
}

export const routesIndex = makeRoutesIndex(routesMap)

export default routesMap
