import { clientAppRequest, formatData } from './requests'

export const getClient = (include = '') => clientAppRequest(`/api/v1/clients/me?${include}`)

export const updateClient = data =>
  clientAppRequest('/api/v1/clients/me', 'PUT', {
    body: formatData({ client: data }),
  })

export const createClient = data =>
  clientAppRequest('/api/v1/clients', 'POST', {
    body: formatData({ client: data }),
  })

export const acceptAgreements = () => {
  clientAppRequest('/api/v1/clients/me/accept_agreements', 'POST')
}

export const updateClientWithInvesting = (data, forceCipFailure = false) =>
  clientAppRequest('/api/v1/clients/me', 'PUT', {
    body: formatData({
      client: {
        enroll_in_investing: true,
        force_cip_failure: forceCipFailure,
        ...data,
      },
    }),
  })
