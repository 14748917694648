/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, all } from 'redux-saga/effects'

/** js */
import ErrorHelper from 'js/ErrorHelper'

import {
  getAttributesFromResponse,
  getAttributesArrayFromResponse,
} from 'app/requests/adapters/apiAdapter'
import {
  fetchOnboardingProgress,
  updateOnboardingProgress,
  fetchAccountInfo,
  fetchFinancialPlanInfo,
  fetchBillingPlans,
} from 'app/requests/onboarding'
import { fetchForeignAccount } from 'app/requests/foreignAccount'

import {
  getOnboardingData,
  setOnboardingDataSuccess,
  setOnboardingDataFailed,
  getBillingPlans,
  setBillingPlansSuccess,
  setBillingPlansFailed,
  getOnboardingStep,
  setOnboardingStep,
  setOnboardingStepSuccess,
  setOnboardingStepFailed,
} from './reducer'
import { EOnboardingSteps, getStepValueByName, getStepNameByValue } from './schema'
import { filterForeignAccounts } from '../foreignAccount/utils'
import { setForeignAccount } from '../foreignAccount/reducer'

// Fetches for onboarding step
export const onGetOnboardingStep = function* () {
  try {
    const response = yield call(fetchOnboardingProgress)
    const { onboardingStep } = getAttributesFromResponse(response)

    // NOTE: We are sending & receiving a step string from the BE, however
    // inside the CA, the step will have a numeric value (see enum on schema file)
    const stepValue = onboardingStep && getStepValueByName(onboardingStep)

    yield put(setOnboardingStepSuccess(stepValue))

    if (!stepValue) {
      yield put(setOnboardingStep(EOnboardingSteps.onboarding_initiated))
    }
  } catch (e) {
    ErrorHelper.notifyError(e)
    yield put(setOnboardingStepFailed())
  }
}

// Loads data (account info + financial plan) according to onboarding step
export const onGetOnboardingData = function* (action) {
  const { payload } = action // payload is set value

  const requiresAccountInfoFetch = payload > EOnboardingSteps.onboarding_initiated
  const requiresFinancilaPlanFetch = payload > EOnboardingSteps.account_info_completed
  const requiresForeignAccountFetch = payload > EOnboardingSteps.financial_plan_info_completed

  const requestCalls = []

  if (requiresAccountInfoFetch) {
    requestCalls.push(call(fetchAccountInfo))
  }
  if (requiresFinancilaPlanFetch) {
    requestCalls.push(call(fetchFinancialPlanInfo))
  }
  if (requiresForeignAccountFetch) {
    requestCalls.push(call(fetchForeignAccount))
  }

  if (!requestCalls.length) return

  try {
    const [accountResponse, financialPlanResponse, foreignAccountResponse] = yield all(requestCalls)
    const accountInfo = getAttributesFromResponse(accountResponse)
    const successData = [accountInfo]

    if (requiresFinancilaPlanFetch) {
      const financialPlanInfo = getAttributesFromResponse(financialPlanResponse)
      successData.push(financialPlanInfo)
    }

    if (requiresForeignAccountFetch) {
      const foreignAccount = filterForeignAccounts(foreignAccountResponse?.data)

      if (foreignAccount) {
        yield put(setForeignAccount(foreignAccount))
      }
    }

    yield put(setOnboardingDataSuccess(successData))
  } catch (e) {
    ErrorHelper.notifyError(e)
    yield put(setOnboardingDataFailed())
  }
}

// Fetches membership information to display on screens
export const onGetBillingPlans = function* () {
  try {
    const response = yield call(fetchBillingPlans)
    const billingPlans = getAttributesArrayFromResponse(response)

    yield put(setBillingPlansSuccess(billingPlans))
  } catch (e) {
    ErrorHelper.notifyError(e)
    yield put(setBillingPlansFailed)
  }
}

// TODO: test with endpoint
export const onSetOnboardingStep = function* (action) {
  const { payload } = action // step value
  const nextStepName = getStepNameByValue(payload)
  try {
    const response = yield call(() => updateOnboardingProgress(nextStepName))
    const { onboardingStep } = getAttributesFromResponse(response)
    // // NOTE: We send & receive a step strign from the BE, however
    // // inside the CA, the step will have a numeric value
    const stepValue = getStepValueByName(onboardingStep)

    yield put(setOnboardingStepSuccess(stepValue))
  } catch (e) {
    ErrorHelper.notifyError(e)
    yield put(setOnboardingStepFailed())
  }
}

const watchOnboarding = function* () {
  yield all([
    takeLatest(getOnboardingStep.type, onGetOnboardingStep),
    takeLatest(getOnboardingData.type, onGetOnboardingData),
    takeLatest(getBillingPlans.type, onGetBillingPlans),
    takeLatest(setOnboardingStep.type, onSetOnboardingStep),
  ])
}

export default watchOnboarding
