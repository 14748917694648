/**
 * Goal Type constant definitions
 */

const BUILD_WEALTH_GOAL = 'build_wealth_goal'
const RETIREMENT_GOAL = 'retirement_goal'
const HOUSE_GOAL = 'house_goal'
const CHILDREN_GOAL = 'children_goal'
const BUSINESS_GOAL = 'business_goal'
const EMERGENCY_GOAL = 'emergency_goal'
const BIG_SPEND_GOAL = 'big_spend_goal'

export default {
  BUILD_WEALTH_GOAL,
  RETIREMENT_GOAL,
  HOUSE_GOAL,
  CHILDREN_GOAL,
  BUSINESS_GOAL,
  EMERGENCY_GOAL,
  BIG_SPEND_GOAL,
}

export const index = [
  BUILD_WEALTH_GOAL,
  RETIREMENT_GOAL,
  HOUSE_GOAL,
  CHILDREN_GOAL,
  BUSINESS_GOAL,
  EMERGENCY_GOAL,
  BIG_SPEND_GOAL,
]
