import { IReducerState } from '../schema'

export enum EForeignAccountStatus {
  ACTIVE = 'active',
  PENDING_MANUAL = 'pending_manual',
  PENDING_AUTOMATIC = 'pending_automatic',
  PENDING_FETCH_INFORMATION = 'pending_fetch_information',
  PENDING_AUTOMATIC_IDENTITY_REVIEW = 'pending_automatic_identity_review',
  PENDING_MANUAL_IDENTITY_REVIEW = 'pending_manual_identity_review',
  AWAITING_CLIENT_DOC_UPLOAD = 'awaiting_client_doc_upload',
  MISSING_ACH_INFORMATION = 'missing_ach_information',
  PENDING_MANUAL_ACH_UPDATE = 'pending_manual_ach_update',
  FAILED_IDENTIFICATION = 'failed_identification',
  CANCELLED = 'canceled',
  FAILED = 'failed',
}

export interface IPendingTransactionRecord {
  account_id: number,
  account_link_id: number,
  amount: string,
  canceled_at: string | null,
  children_count: number,
  client_id: number,
  completed_at: string | null,
  contribution_year: number,
  created_at: string,
  created_by_id: string | null,
  created_by_type: string | null,
  failed_at: string | null,
  folio_ach_deposit_id: number,
  id: number,
  initiated_at: string,
  parent_id: string | number | null,
  processed_at: string | null,
  reciprocal_account_id: number,
  request_date: string,
  status: string,
  unique_id: string,
  updated_at: string,
}

export interface IForeignAccount {
  accountType: string,
  active: boolean,
  id: string,
  institution: string,
  maskedAccountNumber: string,
  nameOnAccount: string |null,
  pendingTransactions: Array<IPendingTransactionRecord>,
  plaidItemId: string,
  recent: boolean,
  routingNumber: string,
  status: string,
  type: string,
}

export interface IForeignAccountRestrictions {
  restricted: boolean
  restrictedBy?: string
  documentationGroup?: string
}

export interface IForeignAccountReducer {
  foreignAccount?: IForeignAccount | undefined
  foreignAccountLoaded: boolean
  foreignAccountMissing: boolean
  foreignAccountRestrictions:IForeignAccountRestrictions
  isLoading: boolean
}

