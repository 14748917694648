const ROOT = '/update-information'

/* ROUTES */
const UPDATE_INFORMATION = ROOT
const UPDATE_INFORMATION_SUCCESS = `${ROOT}/success`
const UPDATE_INFORMATION_SOW_BACKFILL = `${ROOT}/employment-details`
const UPDATE_INFORMATION_SOW_BACKFILL_CITIZENSHIP = `/update-country-of-citizenship`

export default {
  UPDATE_INFORMATION,
  UPDATE_INFORMATION_SUCCESS,
  UPDATE_INFORMATION_SOW_BACKFILL,
  UPDATE_INFORMATION_SOW_BACKFILL_CITIZENSHIP,
}
