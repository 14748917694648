import { all, call, put, takeLatest } from 'redux-saga/effects'

import { FETCH_ACCOUNTS, FETCH_INVESTMENT_PLAN } from 'app/actions/actionTypes'
import {
  setAccounts,
  setInvestmentPlan,
  fetchInvestmentPlanFailed,
} from 'app/actions/investmentPlan'

import { getAccounts, getPlan } from 'app/requests/investmentPlan'

import { formatAccounts } from 'app/utils/accounts'

import ErrorHelper from 'js/ErrorHelper'

const onFetchInvestmentPlan = function*(action) {
  try {
    const { legacy } = action.payload
    let data = yield call(getPlan, action.payload.legacy)

    if (!legacy) {
      ;({ data } = data)
    }

    yield put(setInvestmentPlan(data))
  } catch (e) {
    if (e.response) {
      if (e.response.status !== 404) {
        e.response.json().then(errors => {
          ErrorHelper.notifyError('Failed to fetch investment plan', { errors })
        })
      }
    } else {
      ErrorHelper.notifyError(e)
    }

    yield put(fetchInvestmentPlanFailed())
  }
}

export const onFetchAccounts = function*() {
  try {
    const data = yield call(getAccounts, 'holdings,cashflows,auto_deposits')
    const mappedAccounts = yield call(formatAccounts, data.data, data.included)

    yield put(setAccounts(mappedAccounts))
  } catch (e) {
    ErrorHelper.notifyError(e)
  }
}

const watchInvestmentPlan = function*() {
  yield all([
    takeLatest(FETCH_INVESTMENT_PLAN, onFetchInvestmentPlan),
    takeLatest(FETCH_ACCOUNTS, onFetchAccounts),
  ])
}

export default watchInvestmentPlan
