export const showDeprecationMessage = (fileName: string, replacement?: string): void => {
  const store = require('app/store')
  const state = store.default.getState()
  const { railsEnv } = state.system

  if (railsEnv && railsEnv === 'development') {
    console.log(`******* ${fileName} FILE WILL BE DEPRECATED! ********`)
    if (replacement) {
      console.log(`******* ${fileName} will be replaced with ${replacement} ********`)
    }
  }
}

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_]\w)/g, g => g[1].toUpperCase())

/** Creates a timeout promise for an async function **/
export const timeoutPromise = (ms: number): Promise<unknown> =>
  new Promise(resolve => setTimeout(resolve, ms))

export const getImageSourceSet = (imgPath: string, imgExtension: string): string =>
  `${imgPath}@2x.${imgExtension} 2x, ${imgPath}@3x.${imgExtension} 3x,`

export const getImageSourceSetFromSource = (imgPath: string): string => {
  const [base, ext] = imgPath.split('.')
  return `${base}@2x.${ext} 2x, ${base}@3x.${ext} 3x,`
}

export const getImageSourcePropsFromSource = (src: string): { src: string; srcSet: string } => ({
  srcSet: getImageSourceSetFromSource(src),
  src,
})

export const objectHasKey = (object: unknown, key: string): boolean =>
  Object.prototype.hasOwnProperty.call(object, key)

export const objectHasKeyEqualTo = (object: unknown, key: string, value: unknown): boolean =>
  objectHasKey(object, key) && object[key] === value

export const getNestedKeyFromObject = (pathKey: [], object: unknown): unknown | null =>
  pathKey.reduce((acc, key) => (acc && acc[key] ? acc[key] : null), object)
