import { all, call, put, takeLatest } from 'redux-saga/effects'

import { fetchClient, fetchClientFailure, fetchClientSuccess } from 'app/redux/client/reducer'
import { getClient } from 'app/requests/clients'
import { formatClient } from 'app/utils/client'

const onFetchClient = function* () {
  try {
    const { data, included } = yield call(getClient, 'include=primary_address')
    const client = formatClient(data, included)
    yield put(fetchClientSuccess(client))
  } catch (error) {
    yield put(fetchClientFailure(error))
  }
}

const watchClient = function* () {
  yield all([yield takeLatest(fetchClient.type, onFetchClient)])
}

export default watchClient
