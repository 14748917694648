import update from 'immutability-helper'

export const arrayToSentence = (array) =>
  array.reduce((acc, val, i, arr) => {
    if (i === arr.length - 1) return `${acc}${val}`
    if (i === arr.length - 2) return `${acc}${val} and `
    return `${acc}${val}, `
  }, '')

export const amendUserFolioAccount = (user, folioMember, account) => {
  const { folioAccounts } = folioMember
  const { accountId } = account

  const accountIndex = folioAccounts.findIndex((a) => a.accountId === accountId)

  if (accountIndex > -1) {
    const newAccount = update(folioAccounts[accountIndex], { $merge: account })
    const newFolioMember = update(folioMember, {
      folioAccounts: {
        $splice: [[accountIndex, 1, newAccount]],
      },
    })
    const newUser = update(user, {
      folioMember: {
        $set: newFolioMember,
      },
    })

    return newUser
  }
}

/* TODO: Replace with 'pluralize' from 'pluralize'!
 *  Converts a string into plural according
 *  to numberof items given
 *  @param {string, [one of number, array, object]}
 */
export const pluralize = (label, items) => {
  let count = 0

  if (typeof items === 'number') count = items
  else if (typeof items === 'object') {
    count = Array.isArray(items) ? items.length : Object.keys(items).length
  }

  if (count === 1) return label
  else return `${label}s`
}

export const formatPhoneNumber = (number) =>
  number ? `(${number.substr(0, 3)}) ${number.substr(3, 3)} - ${number.substr(6)}` : ''

export const ordinalize = (integer) => {
  const suffix = ['st', 'nd', 'rd'][((((integer + 90) % 100) - 10) % 10) - 1] || 'th'
  return `${integer}${suffix}`
}
