// learn
export const LEARN_PRODUCT = '/learn/resource'
export const PRODUCT_CHECKOUT = `/learn/checkout`
export const CHECKOUT_CONFIRMATION = '/learn/checkout/confirm'

const routesMap = {
  LEARN_PRODUCT,
  PRODUCT_CHECKOUT,
  CHECKOUT_CONFIRMATION,
}

export default routesMap
