import update from 'immutability-helper'

import * as C from 'app/actions/actionTypes'

const defaultState = {
  modalIsOpen: false,
  openModals: [],
  openModalData: {},
  activeModal: undefined,
  scrollTop: undefined,
}

const modals = (state = defaultState, action) => {
  const { modalName } = action
  const modalData = {}
  const modalIndex = state.openModals.indexOf(modalName)

  switch (action.type) {
    case C.OPEN_MODAL:
      if (modalIndex < 0) {
        modalData[modalName] = action.modalData || {}

        const newScrollTop = state.modalIsOpen ? (
          state.scrollTop
        ) : (
          document.documentElement.scrollTop || document.body.scrollTop
        )

        let newState = update(state, {
          openModals: {
            $push: [modalName],
          },
          openModalData: {
            $merge: modalData,
          },
          modalIsOpen: {
            $set: true,
          },
          scrollTop: {
            $set: newScrollTop,
          },
        })

        newState = update(newState, {
          activeModal: {
            $set: newState.openModals[newState.openModals.length - 1],
          },
        })

        return newState
      } else {
        return state
      }

    case C.CLOSE_MODAL:
      if (modalIndex > -1) {
        let newState = update(state, {
          openModals: {
            $splice: [[modalIndex, 1]],
          },
          openModalData: {
            [`${modalName}`]: {
              $set: null,
            },
          },
          modalIsOpen: {
            $set: state.openModals.length > 1,
          },
        })
        newState = update(newState, {
          activeModal: {
            $set: newState.openModals[newState.openModals.length - 1],
          },
          scrollTop: {
            $set: newState.openModals.length > 0 ? newState.scrollTop : undefined,
          },
        })

        return newState
      } else {
        return state
      }
    default:
      return state
  }
}

export default modals
