import { createSlice } from '@reduxjs/toolkit'
import update from 'immutability-helper'

export const initialState = {
  notifications: [],
  reminders: [],
  alerts: [],
  announcements: [],
  notificationsLoaded: false,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state) {
      return {
        ...state,
        notificationsLoaded: false,
      }
    },
    getNotificationsFailure(state) {
      return {
        ...state,
        notifications: [],
        alerts: [],
        reminders: [],
        announcements: [],
        notificationsLoaded: true,
      }
    },
    setNotifications(state, action) {
      const { alerts, reminders, announcements } = action.payload
      return {
        ...state,
        alerts,
        reminders,
        announcements,
        notifications: [...alerts, ...reminders, ...announcements],
        notificationsLoaded: true,
      }
    },
    removeNotification(state, action) {
      // Only reminders can be manually dismissed.
      const { reminders, alerts, announcements } = state
      const { notificationId } = action.payload

      const notificationIndex = reminders.findIndex((n) => n.id === notificationId)

      if (notificationIndex > -1) {
        const newReminders = update(reminders, {
          $splice: [[notificationIndex, 1]],
        })

        return {
          ...state,
          reminders: newReminders,
          notifications: [...alerts, ...newReminders, ...announcements],
        }
      }
      return state
    },
    dismissNotificationsByBaseType(state) {
      return state
    },
    dismissNotification(state) {
      return state
    },
  },
})

export const {
  getNotifications,
  getNotificationsFailure,
  setNotifications,
  removeNotification,
  dismissNotificationsByBaseType,
  dismissNotification,
} = notificationsSlice.actions

export default notificationsSlice.reducer
