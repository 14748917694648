import { redirect } from 'redux-first-router'

const ONBOARDING_ROOT = {
  path: '/onboarding',
  thunk: (dispatch, getState) => {
    // Redirect to Membership Value Props  (step 1)
    let query
    if (getState().location.query) {
      query = getState().location
    }
    dispatch(redirect({ type: 'ONBOARDING', meta: { query } }))
  },
}

const routes = {
  ONBOARDING_ROOT,
  ONBOARDING_MEMBERSHIP_INTRO_PLATFORM_FOR_WOMEN: '/onboarding/membership/intro/platform-for-women',
  ONBOARDING_MEMBERSHIP_INTRO_AUTOMATED_INVESTING:
    '/onboarding/membership/intro/automated-investing',
  ONBOARDING_MEMBERSHIP_INTRO_FINANCIAL_GUIDANCE: '/onboarding/membership/intro/financial-guidance',
  ONBOARDING_MEMBERSHIP_INTRO_GET_STARTED: '/onboarding/membership/intro/get-started',
  ONBOARDING_MEMBERSHIP_ELLEVEST_INTRO: '/onboarding/membership/setup/intro',
  ONBOARDING_MEMBERSHIP_VALUE_PROPS: '/onboarding/membership/setup/value-props',
  ONBOARDING_MEMBERSHIP_MADLIBS: '/onboarding/membership/setup/madlibs',
  ONBOARDING_MEMBERSHIP_MADLIBS_STEPS: '/onboarding/membership/setup/madlibs-steps',
  ONBOARDING_MEMBERSHIP_MONEY_GOALS: '/onboarding/membership/setup/money-goals',
  ONBOARDING_MEMBERSHIP_NET_WORTH: '/onboarding/membership/setup/net-worth',
  ONBOARDING_MEMBERSHIP_PROFILE_LOADER: '/onboarding/membership/setup/profile-loader',
  ONBOARDING_MEMBERSHIP_OPTIONS: '/onboarding/membership/setup/select-tier',
  ONBOARDING_MEMBERSHIP_PERSONAL_INFO_INTRO: '/onboarding/membership/checkout/personal-info/intro',
  ONBOARDING_MEMBERSHIP_PERSONAL_INFO: '/onboarding/membership/checkout/personal-info',
  ONBOARDING_MEMBERSHIP_PERSONAL_INFO_VERIFY:
    '/onboarding/membership/checkout/personal-info/verify',
  ONBOARDING_MEMBERSHIP_PERSONAL_INFO_CONFIRMATION:
    '/onboarding/membership/checkout/personal-info/confirmation',
  ONBOARDING_MEMBERSHIP_PAYMENT_SELECTION: '/onboarding/membership/payment-selection',
  ONBOARDING_MEMBERSHIP_LINK_BANK: '/onboarding/membership/checkout/link-bank',
  ONBOARDING_MEMBERSHIP_BILLING_OPTIONS: '/onboarding/membership/checkout/billing',
  ONBOARDING_MEMBERSHIP_AGREEMENTS: '/onboarding/membership/checkout/agreements',
  ONBOARDING_MEMBERSHIP_COMPLETE: '/onboarding/membership/checkout/complete',

  ONBOARDING_MEMBERSHIP_BY_WOMEN_FOR_WOMEN: '/onboarding/membership/setup/by-women-for-women',
  ONBOARDING_MEMBERSHIP_EASY_TO_INVEST: '/onboarding/membership/setup/easy-to-invest',
  ONBOARDING_MEMBERSHIP_INVESTING_ALGORITHM: '/onboarding/membership/setup/investing-algorithm',
  ONBOARDING_MEMBERSHIP_HOW_IT_WORKS: '/onboarding/membership/setup/how-it-works',
  ONBOARDING_MEMBERSHIP_PROFILE_INTRO: '/onboarding/membership/setup/profile-intro',
  ONBOARDING_MEMBERSHIP_PRIVATE_WEALTH_MANANGEMENT:
    '/onboarding/membership/setup/private-wealth-management',
}

export const introRoutes = [
  routes.ONBOARDING_MEMBERSHIP_INTRO_PLATFORM_FOR_WOMEN,
  routes.ONBOARDING_MEMBERSHIP_INTRO_AUTOMATED_INVESTING,
  routes.ONBOARDING_MEMBERSHIP_INTRO_FINANCIAL_GUIDANCE,
  routes.ONBOARDING_MEMBERSHIP_INTRO_GET_STARTED,
]

export default routes
