import { createSlice } from '@reduxjs/toolkit'

import { IMembershipsReducer } from './schema'

export const initialState: IMembershipsReducer = {
  isLoading: false,
  membershipTiers: [],
  membershipTiersLoaded: false,
  membershipTiersFailed: false,
}

const membershipsSlice = createSlice({
  name: 'memberships',
  initialState,
  reducers: {
    getMembershipTiers(state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    setMembershipTiers(state, action) {
      const { payload } = action

      return {
        ...state,
        isLoading: false,
        membershipTiers: payload,
        membershipTiersLoaded: true,
      }
    },
    setMembershipTiersFailure(state) {
      return {
        ...state,
        isLoading: false,
        membershipTiersFailed: true,
      }
    },
  },
})

export const {
  getMembershipTiers,
  setMembershipTiers,
  setMembershipTiersFailure,
} = membershipsSlice.actions

export default membershipsSlice.reducer
