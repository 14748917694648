import React from 'react'
import * as ReactDOM from 'react-dom'
import LoggedOutFooter from './LoggedOutFooter'
import FooterCopy from './FooterCopy'
import FooterNavigation from './FooterNavigation'
import { SocialNavigation, Icon } from 'belle-js'

import icons from 'belle-js/lib/icons.svg'
import richIcons from 'belle-js/lib/icons-rich.svg'

import styles from './Footer.module.scss'

import ExternalLink from 'app/components/ExternalLink/ExternalLink'

/**
 * test-id elements:
 * - footer__faqLink
 * - footer__privacyLink
 * - footer__careersLink
 * - footer__termsOfServiceLink
 * - footer__advLink
 * - footer__linkedInLink
 * - footer__twitterLink
 * - footer__instagramLink
 * - footer__testFPSLink
 */

const ClientAppFooter = () => {
  const socialIconSize = 50

  const s3Url = 'https://s3.amazonaws.com/production.assets.ellevest.com'

  return (
    <div id="app-footer-container">
      <LoggedOutFooter>
        <Icon icon="all" iconPath={icons} />
        <Icon icon="all" iconPath={richIcons} />
        <div className={styles.footerContainer}>
          <div className={styles.mediaContainer}>
            <div className={styles.socialNavigationContainer}>
              <SocialNavigation layout="inline" faded={true}>
                <li>
                  <ExternalLink
                    href="https://www.instagram.com/ellevest"
                    testId="footer__instagramLink">
                    <Icon icon="instagram_circle" width={socialIconSize} height={socialIconSize} />
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink
                    href="https://www.linkedin.com/company/10208395"
                    testId="footer__linkedInLink">
                    <Icon icon="linkedin_circle" width={socialIconSize} height={socialIconSize} />
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink href="https://twitter.com/ellevest" testId="footer__twitterLink">
                    <Icon icon="twitter_circle" width={socialIconSize} height={socialIconSize} />
                  </ExternalLink>
                </li>
              </SocialNavigation>
            </div>
          </div>
          <div className={styles.linkContainer}>
            <div className={styles.navigationContainer}>
              <FooterNavigation>
                <div className={styles.navSectionsContainer}>
                  <div className={styles.navSectionGroup}>
                    <li className={styles.navSection}>
                      <span>Methodology</span>
                      <ul>
                        <li>
                          <ExternalLink href="https://assets.website-files.com/5e6a6600949076ab67ab2487/5f7f692105c2ca31424ad6ce_Ellevest%20Private%20Wealth%20Management%20Whitepaper%20July%202020.pdf">
                            Wealth Management Methodology
                          </ExternalLink>
                        </li>
                      </ul>
                    </li>
                    <li className={styles.navSection}>
                      <span>Legal</span>
                      <ul>
                        <li>
                          <ExternalLink
                            href="http://secure.ellevest.com/current/membership_agreement"
                            testId="footer__advLink">
                            Plan Agreement
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink
                            href={window.sec_registration_wrap_url}
                            testId="footer__advLink">
                            ADV Form Part 2
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink
                            href="https://www.ellevest.com/current/customer-relationship-summary"
                            testId="footer__advLink">
                            Form ADV Part 3 CRS
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink href="https://www.ellevest.com/current/pwm-form-adv-part2">
                            Private Wealth ADV
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink href="/privacy_policy" data-test-id="footer__privacyLink">
                            Privacy
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink
                            href={`${s3Url}/documents/Ellevest%20CCPA%20Website%20Privacy%20Policy%20Updated%20031220.pdf`}>
                            California Privacy Policy
                          </ExternalLink>
                        </li>
                      </ul>
                    </li>
                  </div>
                  <div className={styles.navSectionGroup}>
                    <li className={styles.navSection}>
                      <span>Company</span>
                      <ul>
                        <li>
                          <ExternalLink href={`${window.marketing_app_url}/about-us`}>
                            About Us
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink
                            href={`${window.marketing_app_url}/careers`}
                            data-test-id="footer__careersLink">
                            Careers
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink href={`${window.marketing_app_url}/security`}>
                            Security
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink
                            href={`${window.marketing_app_url}/terms-of-service`}
                            data-test-id="footer__termsOfServiceLink">
                            Terms of Service
                          </ExternalLink>
                        </li>
                      </ul>
                    </li>
                    <li className={styles.navSection}>
                      <span>Support</span>
                      <ul>
                        <li>
                          <ExternalLink
                            href="https://ellevest.zendesk.com/"
                            testId="footer__faqLink">
                            FAQ
                          </ExternalLink>
                        </li>
                        <li>
                          <ExternalLink href={`${window.marketing_app_url}/accessibility`}>
                            Accessibility
                          </ExternalLink>
                        </li>
                      </ul>
                    </li>
                  </div>
                </div>
              </FooterNavigation>
            </div>

            <div className={styles.footerCopyContainer}>
              © {new Date().getFullYear()} Ellevest, Inc. All rights reserved.
            </div>
            <div className={styles.footerDisclaimer}>
              The information provided is current as of the date of this writing and for
              informational purposes only. It should not be relied upon as investment advice or
              recommendations, does not constitute a solicitation to buy or sell securities, and
              should not be considered specific legal, investment or tax advice. Investing entails
              risk, including the possible loss of principal, and past performance is not predictive
              of future results. Ellevest, Inc. is an SEC-registered investment adviser.
            </div>

            {window.financial_plan && window.fps_test_link && (
              <FooterCopy>
                <ExternalLink
                  href={`${window.fps_test_link}${window.financial_plan.financial_plan_id}`}
                  testId="footer__testFPSLink">
                  Test FPS
                </ExternalLink>
              </FooterCopy>
            )}
          </div>
        </div>
      </LoggedOutFooter>
    </div>
  )
}

// Render into places where _static_footer.html.erb is still being used
const staticFooterEl = document.getElementById('staticFooter')
if (staticFooterEl) {
  ReactDOM.render(<ClientAppFooter />, staticFooterEl)
}

export default ClientAppFooter

ClientAppFooter.displayName = 'ClientAppFooter'
