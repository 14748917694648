import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'

import * as C from 'app/actions/actionTypes'

const defaultState = {
  advice: [],
}

const planner = (state = defaultState, action) => {
  let newState

  switch (action.type) {
    case C.INIT_PLANNER_SESSION: {
      const { sessionId } = action

      newState = update(state, {
        sessionId: {
          $set: sessionId || uuidv4(),
        },
      })

      return newState
    }

    case C.REMOVE_ADVICE: {
      const { goalId } = action
      const { advice } = state
      const goalIndex = advice.findIndex((adviceObj) => adviceObj.id === goalId)

      newState = update(state, {
        advice: {
          $set: update(state.advice, {
            $splice: [[goalIndex, 1]],
          }),
        },
      })

      return newState
    }

    case C.ADD_ADVICE_SUCCESS: {
      const { advice } = action

      newState = update(state, {
        advice: {
          $set: update(state.advice, {
            $push: [advice],
          }),
        },
      })

      return newState
    }

    default: {
      return state
    }
  }
}

export default planner
