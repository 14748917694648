import { folio } from 'app/content/accountTypes'
export const SETTINGS_MY_SERVICE_BASE = 'my-service'

export const SETTINGS_MY_SERVICE_PATHS = {
  MY_SERVICE_BANKING_INVESTING: 'banking-investing',
  MY_SERVICE_LEARNING_COACHING: 'learning-coaching',
  MY_SERVICE_SELECT: 'select',
  MY_SERVICE_BILLING_OPTIONS: 'billing',
  MY_SERVICE_MEMBERSHIP_AGREEMENTS: 'membership-agreements',
}

export const MEMBERSHIP_PATHS = {
  BILLING_PLAN: SETTINGS_MY_SERVICE_BASE,
  REWARDS: 'rewards',
}

export const IRA_ACCOUNT_PATHS = {
  ROTH_IRA_ACCOUNT: folio.ROTH_IRA_ACCOUNT.key,
  SEP_IRA_ACCOUNT: folio.SEP_IRA_ACCOUNT.key,
  TRADITIONAL_IRA_ACCOUNT: folio.TRADITIONAL_IRA_ACCOUNT.key,
}

export const ACCOUNT_PATHS = {
  INVESTMENT_ACCOUNT: folio.BROKERAGE_ACCOUNT.key,
  RETIREMENT_ACCOUNT: 'retirement_account',
  ...IRA_ACCOUNT_PATHS,
}

export const DOCUMENTS_PATH = {
  BANKING_DOCUMENTS: 'banking_documents',
  INVESTING_DOCUMENTS: 'investing_documents',
}

export const USER_SETTINGS_PATH = {
  USER_SETTINGS: 'user_settings',
  BANK_ACCOUNT: 'bank_account',
  FINANCIAL: 'financial',
  LOGIN: 'login',
  PERSONAL: 'personal',
  PROFILE: 'profile',
}

export const SETTINGS_PATHS = {
  ...MEMBERSHIP_PATHS,
  ...ACCOUNT_PATHS,
  ...DOCUMENTS_PATH,
  ...USER_SETTINGS_PATH,
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  STATEMENTS: 'statements', // redirects to INVESTING_DOCUMENTS
}
