import { redirect } from 'redux-first-router'

const ONBOARDING_MADLIBS = '/onboarding/madlibs'
const ONBOARDING_GOALS = '/onboarding/goals'
const ONBOARDING_ACCOUNTS = '/onboarding/accounts'
const ONBOARDING_EDIT_GOAL = '/onboarding/goals/:goalType'

const ONBOARDING_ROOT = {
  path: '/onboarding',
  thunk: (dispatch, getState) => {
    // Redirect to Madlibs (step 1)
    let query
    if (getState().location.query) {
      ;({ query } = getState().location)
    }
    dispatch(redirect({ type: 'ONBOARDING', meta: { query } }))
  },
}

export default {
  ONBOARDING_ROOT,
  ONBOARDING_MADLIBS,
  ONBOARDING_GOALS,
  ONBOARDING_ACCOUNTS,
  ONBOARDING_EDIT_GOAL,
}
