import makeRoutesIndex from 'app/routes/makeRoutesIndex'

const CANCELLATION_ROOT = '/cancellation'
const FUNDED_IRA_ROOT = `${CANCELLATION_ROOT}/IRA`
const QUICK_CANCEL_ROOT = `${CANCELLATION_ROOT}/quick-cancel`

// common
export const CANCELLATION_FLOW_ENTRY = `${CANCELLATION_ROOT}/entry`
export const CANCELLATION_REMINDER_ENTRY = `${CANCELLATION_ROOT}/reminder/entry`
export const CANCELLATION_SURVEY = `${CANCELLATION_ROOT}/survey`
export const CANCELLATION_PROMOTION_SUCCESS = `${CANCELLATION_ROOT}/promotion/success`
export const CANCELLATION_PROMOTION_ERROR = `${CANCELLATION_ROOT}/promotion/error`
export const CANCELLATION_ERROR = `${CANCELLATION_ROOT}/error`
export const CANCELLATION_CONFIRM = `${CANCELLATION_ROOT}/confirm`
export const CANCELLATION_CONTACT_CX = `${CANCELLATION_ROOT}/contact-cx`

// automated request
export const CANCELLATION_OVERVIEW = `${CANCELLATION_ROOT}/overview`
export const CANCELLATION_CHECKLIST_STEP_ONE = `${CANCELLATION_ROOT}/delete-recurring`
export const CANCELLATION_CHECKLIST_STEP_TWO = `${CANCELLATION_ROOT}/cancel-direct-deposits`
export const CANCELLATION_CHECKLIST_STEP_THREE = `${CANCELLATION_ROOT}/withdraw-balance`
export const CANCELLATION_CHECKLIST_SUBMIT = `${CANCELLATION_ROOT}/submit`
export const CANCELLATION_REQUEST_SUBMITTED = `${CANCELLATION_ROOT}/request-submitted`
export const CANCELLATION_REQUEST_PENDING = `${CANCELLATION_ROOT}/request-pending`

// quick cancel
export const QUICK_CANCEL_CONFIRM = `${QUICK_CANCEL_ROOT}/confirm`
export const CANCELLATION_SUCCESS = `${QUICK_CANCEL_ROOT}/success`

// funded IRA
export const FUNDED_IRA_OVERVIEW = `${FUNDED_IRA_ROOT}/overview`
export const FUNDED_IRA_WITHDRAW = `${FUNDED_IRA_ROOT}/withdraw`
export const FUNDED_IRA_START_WITHDRAW = `${FUNDED_IRA_ROOT}/start-withdraw`
export const FUNDED_IRA_CANCELLATION_SUBMIT = `${FUNDED_IRA_ROOT}/submit`

// annual users
export const ANNUAL_PLAN_NOTICE = `${CANCELLATION_ROOT}/annual-plan-notice`
export const SUBMIT_CANCEL_REMINDER = `${CANCELLATION_ROOT}/submit-cancel-reminder`
export const CANCEL_REMINDER_REQUESTED = `${CANCELLATION_ROOT}/cancel-reminder-request-submitted`

const routesMap = {
  CANCELLATION_REQUEST_SUBMITTED,
  CANCELLATION_SUCCESS,
  CANCELLATION_FLOW_ENTRY,
  QUICK_CANCEL_CONFIRM,
  CANCELLATION_SURVEY,
  CANCELLATION_ERROR,
  CANCELLATION_OVERVIEW,
  CANCELLATION_CONFIRM,
  FUNDED_IRA_OVERVIEW,
  FUNDED_IRA_WITHDRAW,
  FUNDED_IRA_START_WITHDRAW,
  FUNDED_IRA_CANCELLATION_SUBMIT,
  CANCELLATION_CHECKLIST_STEP_ONE,
  CANCELLATION_CHECKLIST_STEP_TWO,
  CANCELLATION_CHECKLIST_STEP_THREE,
  CANCELLATION_CHECKLIST_SUBMIT,
  CANCELLATION_CONTACT_CX,
  CANCELLATION_REQUEST_PENDING,
  CANCELLATION_PROMOTION_SUCCESS,
  CANCELLATION_PROMOTION_ERROR,
  ANNUAL_PLAN_NOTICE,
  SUBMIT_CANCEL_REMINDER,
  CANCEL_REMINDER_REQUESTED,
  CANCELLATION_REMINDER_ENTRY,
}

export default routesMap

export const routesIndex = makeRoutesIndex(routesMap)
