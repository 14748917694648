export const BREADCRUMB_TYPES = [
  'navigation',
  'request',
  'process',
  'user',
  'state',
  'error',
  'manual',
]

export const REGEX_BLACKLIST = [
  /email/i,
  /name/i,
  /address/i,
  /street/i,
  /city/i,
  /state/i,
  /country/i,
  /code/i,
  /phone/i,
  /mobile/i,
  /ssn/i,
  /identification/i,
  /birthdate/i,
  /account/i,
  /employer/i,
  /tax/i,
  /password/i,
  /username/i,
  /social/i,
  /employment/i,
]
