import { v4 as uuidv4 } from 'uuid'

import { clientAppRequest, formatData } from './requests'

export const getPlan = (legacyFormat = true) =>
  clientAppRequest(`/api/v1/financial_plans/me?legacy_format=${legacyFormat}`)

export const refreshForecast = (legacyFormat = true) =>
  clientAppRequest(`/api/v1/financial_plans/me/refresh_forecast?legacy_format=${legacyFormat}`)

export const lookupAgeByZipcode = (zipcode) =>
  clientAppRequest(`/api/zip_code_lookup?zip_code=${zipcode}`)

export const createPlan = (data) =>
  clientAppRequest(`/api/v1/financial_plans`, 'POST', {
    body: formatData({
      financial_plan: { ...data },
    }),
  })

export const updatePlan = (data) =>
  clientAppRequest(`/api/v1/financial_plans/me`, 'PUT', {
    body: formatData({
      financial_plan: { ...data },
    }),
  })

export const getExternalAccounts = () => clientAppRequest(`/api/v1/external_accounts`)

export const updateExternalAccount = (externalAccountId, data) =>
  clientAppRequest(`/api/goal_settings/external_accounts/${externalAccountId}`, 'PUT', {
    body: formatData(data),
  })

export const createExternalAccount = (data) =>
  clientAppRequest('/api/goal_settings/external_accounts', 'POST', {
    body: formatData(data),
  })

export const saveExternalAccounts = (data, retirementOnly = false) =>
  clientAppRequest(`/api/v1/external_accounts/collection`, 'PUT', {
    body: formatData({
      external_accounts: data,
      retirement_only: retirementOnly,
    }),
  })

export const getGoals = (includes = '') => clientAppRequest(`/api/v1/goals?include=${includes}`)

export const createGoal = (data) =>
  clientAppRequest(`/api/v1/goals`, 'POST', {
    body: formatData({
      goal: data,
      session_id: uuidv4(),
    }),
  })

export const saveGoalsCollection = (data) =>
  clientAppRequest(`/api/v1/goals/collection`, 'PATCH', {
    body: formatData({
      goals: data,
      session_id: uuidv4(),
    }),
  })

export const updateGoalsCollection = (data) =>
  clientAppRequest(`/api/goals_collection`, 'PUT', {
    body: formatData(data),
  })

export const validateGoals = (data) =>
  clientAppRequest(`/api/goals_collection/validate`, 'POST', {
    body: formatData({
      ...data,
    }),
  })

export const deleteGoal = (id, includes = '') =>
  clientAppRequest(`/api/v1/goals/${id}?include=${includes}`, 'DELETE')

export const getGoal = (goalId, includes) =>
  clientAppRequest(`/api/v1/goals/${goalId}?include=${includes}`)

export const getProjectionsForAccount = (accountId) =>
  clientAppRequest(`/api/v1/accounts/${accountId}/projections`)

export const getProjectionsForGoal = (goalId) =>
  clientAppRequest(`/api/v1/goals/${goalId}/projections`)

export const updateGoalPortfolioType = (goalId, type, persist = false) =>
  clientAppRequest(`/api/v1/goals/${goalId}`, 'PUT', {
    body: formatData({
      goal: {
        model_set_type: type,
      },
      persist,
      session_id: uuidv4(),
    }),
  })

export const updateGoal = (goalId, goalData, persist = false) =>
  clientAppRequest(`/api/v1/goals/${goalId}`, 'PUT', {
    body: formatData({
      goal: goalData,
      persist,
      session_id: uuidv4(),
    }),
  })

export const getAccounts = (includes = '') =>
  clientAppRequest(`/api/v1/accounts?include=${includes}`)

export const getAccount = (accountId) => clientAppRequest(`/api/v1/accounts/${accountId}`)

export const createAccount = (data) =>
  clientAppRequest(`/api/v1/accounts`, 'POST', {
    body: formatData({
      ...data,
    }),
  })

export const updateRetirementTarget = (goalId, goalData, persist = false) =>
  clientAppRequest(`/api/v1/goals/${goalId}/target`, 'PUT', {
    body: formatData({
      goal: goalData,
      persist,
      session_id: uuidv4(),
    }),
  })

/** Activate and Deactivate Goals */
export const setGoalState = (financialPlanId, goalId, data) =>
  clientAppRequest(`/api/financial_plan/${financialPlanId}/goal/${goalId}`, 'PUT', {
    body: formatData(data),
  })

export const updateComposition = (planId, goalId, accountId, data) =>
  clientAppRequest(
    `/api/financial_plan/${planId}/goal/${goalId}/account/${accountId}/update_composition`,
    'PUT',
    {
      body: formatData(data),
    }
  )
