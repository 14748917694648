import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  isFetched: false,
  error: null,
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    fetchClient(state) {
      return {
        ...state,
      }
    },
    fetchClientSuccess(state, action) {
      return {
        ...initialState,
        isFetched: true,
        data: action.payload,
      }
    },
    fetchClientFailure(state, action) {
      return {
        ...initialState,
        isFetched: true,
        error: action.payload,
      }
    },
  },
})

export const { fetchClient, fetchClientSuccess, fetchClientFailure } = clientSlice.actions

export default clientSlice.reducer
