export const ONBOARDING_INVESTING_GOALS = '/onboarding/investing/setup/goals'
export const ONBOARDING_INVESTING_EDIT_GOAL_ROOT = '/onboarding/investing/setup/edit-goal'
export const ONBOARDING_INVESTING_STARTING_WITH_YOU = '/onboarding/investing/setup/start-with-you'

export const ONBOARDING_INVESTING_SCHEDULE_DEPOSITS_ROOT =
  '/onboarding/investing/checkout/schedule-deposits'

const routes = {
  ONBOARDING_INVESTING_MAKE_A_DIFFERENCE: '/onboarding/investing/setup/make-a-difference',
  ONBOARDING_INVESTING_INVEST_FOR_IMPACT: '/onboarding/investing/setup/invest-for-impact',
  ONBOARDING_INVESTING_MONTHLY_DEPOSIT: '/onboarding/investing/setup/monthly-deposit',
  ONBOARDING_INVESTING_ONE_TIME_DEPOSIT: '/onboarding/investing/setup/one-time-deposit',
  ONBOARDING_INVESTING_GOALS,
  ONBOARDING_INVESTING_EDIT_GOAL: `${ONBOARDING_INVESTING_EDIT_GOAL_ROOT}/:goalType`,
  ONBOARDING_INVESTING_STARTING_WITH_YOU,
  ONBOARDING_INVESTING_FULL_PICTURE: '/onboarding/investing/setup/full-picture',
  ONBOARDING_INVESTING_INFLATION_INFO: '/onboarding/investing/setup/inflation-info',
  ONBOARDING_INVESTING_REACH_YOUR_TARGET: '/onboarding/investing/setup/reach-your-target',
  ONBOARDING_INVESTING_ADD_OUTSIDE_RETIREMENT_ACCOUNTS:
    '/onboarding/investing/setup/add-outside-retirement-accounts',
  ONBOARDING_INVESTING_PERSONAL_INFO_INTRO: '/onboarding/investing/checkout/personal-info/intro',
  ONBOARDING_INVESTING_PERSONAL_INFO: '/onboarding/investing/checkout/personal-info',
  ONBOARDING_INVESTING_LINK_BANK: '/onboarding/investing/checkout/link-bank',
  ONBOARDING_INVESTING_PERSONAL_INFO_SOCIAL: '/onboarding/investing/checkout/personal-info/ssn',
  ONBOARDING_INVESTING_PERSONAL_INFO_EMPLOYMENT:
    '/onboarding/investing/checkout/personal-info/employment',
  ONBOARDING_INVESTING_PERSONAL_INFO_REGULATORY:
    '/onboarding/investing/checkout/personal-info/regulatory',
  ONBOARDING_INVESTING_SCHEDULE_DEPOSITS: `${ONBOARDING_INVESTING_SCHEDULE_DEPOSITS_ROOT}/:goalType`,
  ONBOARDING_INVESTING_ESIGN: '/onboarding/investing/checkout/esign',
  ONBOARDING_INVESTING_DEPOSIT_CONFIRMATION: '/onboarding/investing/checkout/deposit-confirmation',
  ONBOARDING_INVESTING: '/onboarding/investing',
  ONBOARDING_RETIREMENT: '/onboarding/retirement',
  ONBOARDING_INVESTING_RETIREMENT_TARGET_LOADER: '/onboarding/investing/setup/target-loader',
  ONBOARDING_INVESTING_RETIREMENT_TARGET: '/onboarding/investing/setup/target',
  ONBOARDING_INVESTING_OUTSIDE_RETIREMENT_ACCOUNTS_SUMMARY:
    '/onboarding/investing/setup/outside-retirement-accounts-summary',
  ONBOARDING_INVESTING_RETIREMENT_GOAL_LOADER: '/onboarding/investing/setup/retirement-goal-loader',
  ONBOARDING_INVESTING_ACCOUNT_RECOMMENDATION_INFO:
    '/onboarding/investing/setup/account-recommendation-info',
}

export const retirementRoutes = [
  routes.ONBOARDING_INVESTING_STARTING_WITH_YOU,
  routes.ONBOARDING_INVESTING_FULL_PICTURE,
  routes.ONBOARDING_INVESTING_INFLATION_INFO,
  routes.ONBOARDING_INVESTING_RETIREMENT_TARGET_LOADER,
  routes.ONBOARDING_INVESTING_RETIREMENT_TARGET,
  routes.ONBOARDING_INVESTING_REACH_YOUR_TARGET,
  routes.ONBOARDING_INVESTING_ADD_OUTSIDE_RETIREMENT_ACCOUNTS,
  routes.ONBOARDING_INVESTING_OUTSIDE_RETIREMENT_ACCOUNTS_SUMMARY,
  routes.ONBOARDING_INVESTING_RETIREMENT_GOAL_LOADER,
  routes.ONBOARDING_INVESTING_ACCOUNT_RECOMMENDATION_INFO,
]

export default routes
