import React from 'react'
import PropTypes from 'prop-types'
import styles from './FooterNavigation.module.scss'
import spacing from '../FooterSpacing.module.scss'
import classnames from 'classnames'

/**
 * FooterNavigation
 *
 * A list of navigation items to display in the Footer. List items should be
 * passed in as children, and can include <span> for sub-nav headers and nested
 * <ul>s for sub-navigation.
 */

const FooterNavigation = ({ children }) => {
  const classes = classnames(styles.FooterNavigation, spacing.FooterNavigation)

  return (
    <nav className={classes}>
      <ul className={styles.list}>{children}</ul>
    </nav>
  )
}

export default FooterNavigation

FooterNavigation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
