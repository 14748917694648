import { createSlice } from '@reduxjs/toolkit'

import {IGlobalReducer} from './schema'

export const initialState: IGlobalReducer = {
  referral: {
    referrerAmount: 0,
    refereeAmount: 0,
    agreementUrl: null,
  },
  moneyMovementLimits: {
    bankingLimits: {
      monthlyLimit: 0,
      monthlyLimitPeriod: 0,
      oneTimeLimit: 0,
    },
  },
  globallyEnabledFeatureFlags: [],
  personCount: 0,
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    loadGlobalData(state) {
      return { ...state }
    },
    loadGlobalDataSuccess(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    loadGlobalDataFailure(state) {
      return { ...state }
    },
  },
})

export const { loadGlobalData, loadGlobalDataSuccess, loadGlobalDataFailure } = globalSlice.actions

export default globalSlice.reducer
