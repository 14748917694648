import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRouteData(state, action) {
      const { route, data } = action.payload
      let newState
      if (!state[route]) {
        newState = data
      } else {
        newState = { ...state[route], ...data }
      }

      return {
        ...state,
        [route]: newState,
      }
    },

    deleteRouteData(state, action) {
      const { route, keyToDelete } = action.payload
      const keys = keyToDelete.split('.')
      const routeData = state[route]

      // using the spread operator or Object.assign makes the test throw an error:
      // An immer producer returned a new value *and* modified its draft. Either return a new value *or* modify the draft.
      // Spread and Object.assign is considered modification
      const nextRouteData = JSON.parse(JSON.stringify(routeData))
      if (nextRouteData) {
        keys.reduce((acc, key, index) => {
          if (index === keys.length - 1) {
            delete acc[key]
            return true
          }

          return acc[key]
        }, nextRouteData)
      }
      // if the route has no routeData, delete the entire route key
      if (
        nextRouteData &&
        Object.keys(nextRouteData).length === 0 &&
        Object.getPrototypeOf(nextRouteData) === Object.prototype
      ) {
        const tempState = { ...state }
        delete tempState[route]
        return {
          ...tempState,
        }
      }
      return {
        ...state,
        [route]: { ...nextRouteData },
      }
    },

    routeChange(state) {
      return state
    },
  },
})

export const { setRouteData, deleteRouteData, routeChange } = routesSlice.actions

export default routesSlice.reducer
