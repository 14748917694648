import { isMobile, isTablet } from 'react-device-detect'

// eslint-disable-next-line no-nested-ternary
const getDeviceType = () => (isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop')

export default (category = '', name = '', properties = {}, options = {}, callback = () => {}) => {
  const Segment = window.analytics

  try {
    const pageProperties = {
      ...properties,
      deviceType: getDeviceType(),
    }

    Segment.page(category, name, pageProperties, options, callback)
  } catch (e) {
    console.warn('Analytics error: page attempted, Segment not found')
    callback()
  }
}
