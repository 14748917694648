const makeRoutesIndex = routesMap => {
  const routesIndex = {}

  for (const route in routesMap) {
    routesIndex[route] = `${route}`
  }

  return routesIndex
}

export default makeRoutesIndex
