import { all, call, put, takeLatest } from 'redux-saga/effects'

import { GET_USER, AUTH_SUCCESS } from 'app/actions/actionTypes'
import { setUser } from 'app/actions/auth'
import { setUserSessionCount } from 'app/redux/system/reducer'

import { getUser } from 'app/requests/auth'

import ErrorHelper from 'js/ErrorHelper'
import Cookies from 'js-cookie'

const SESSION_COUNT_COOKIE = '_ClientApplication_session_count'

const onGetUser = function*() {
  try {
    const data = yield call(getUser)
    yield put(setUser(data))
  } catch (error) {
    ErrorHelper.notifyError('Fetch user failed')
  }
}

const onAuthSuccess = function*() {
  try {
    const { included = [] } = yield call(getUser)

    if (included && included.length > 0) {
      const membership = included.find(p => p.type === 'personMemberships') || {}

      if (
        membership &&
        membership.attributes &&
        (membership.attributes.active || membership.attributes.deactivatedAt)
      ) {
        setSessionCount()
      }
    }
    // Adding this since the previous counter has validations and not been updated each time users login
    yield put(setUserSessionCount())
  } catch (error) {
    ErrorHelper.notifyError('Fetch user failed')
  }
}

const setSessionCount = () => {
  const sessionCount = Cookies.get(SESSION_COUNT_COOKIE)

  if (sessionCount) {
    // eslint-disable-next-line radix
    Cookies.set(SESSION_COUNT_COOKIE, parseInt(sessionCount) + 1)
  } else {
    Cookies.set(SESSION_COUNT_COOKIE, 1)
  }
}

const watchAuth = function*() {
  yield all([takeLatest(GET_USER, onGetUser), takeLatest(AUTH_SUCCESS, onAuthSuccess)])
}

export default watchAuth
