/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, all } from 'redux-saga/effects'
import ErrorHelper from 'js/ErrorHelper'
import {
  fetchSleeveHistoryData,
  setSleeveHistoryData,
  setSleeveHistoryDataFailure,
} from './reducer'

import { getSleeveHistoricalData } from 'app/requests/sleeveHistoricalData'

import { formatSleeveHistoryRecordArray } from './utils'

export const onFetchSleeveHistoryData = function* (action) {
  const accountId = action.payload

  try {
    const { data } = yield call(getSleeveHistoricalData, accountId)
    const sleeveHistoryData = yield formatSleeveHistoryRecordArray(data)

    yield put(setSleeveHistoryData({ sleeveHistoryData, accountId }))
  } catch (error) {
    ErrorHelper.notifyError('Failed to fetch sleeve historical data. ', { error })
    yield put(setSleeveHistoryDataFailure({ ...error, accountId }))
  }
}

const watchSleeveHistoryData = function* () {
  yield all([takeLatest(fetchSleeveHistoryData.type, onFetchSleeveHistoryData)])
}

export default watchSleeveHistoryData
