import { call, put, takeLatest } from 'redux-saga/effects'
import { getGlobalData } from 'app/requests/global'

import {
  loadGlobalData,
  loadGlobalDataSuccess,
  loadGlobalDataFailure,
} from 'app/redux/global/reducer'

// watches for action dispatched to the store
export const watchLoadGlobal = function*() {
  yield takeLatest(loadGlobalData.type, fetchGlobalData)
}

// function that makes the api request and returns a Promise for response
const fetchGlobalData = function*() {
  try {
    const response = yield call(getGlobalData)
    const payload = response.data.attributes
    yield put(loadGlobalDataSuccess(payload))
  } catch (error) {
    yield put(loadGlobalDataFailure(error))
  }
}
