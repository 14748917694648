import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import ErrorHelper from 'js/ErrorHelper'

import {
  getNotifications,
  removeNotification,
  getNotificationsFailure,
  setNotifications,
  dismissNotification,
  dismissNotificationsByBaseType,
} from './reducer'

import {
  getNotifications as getNotificationsRequest,
  dismissNotification as dismissNotificationRequest,
} from 'app/requests/notifications'

import { selectNotifications } from 'app/selectors/notifications'
import formatNotifications from 'app/utils/notifications'

export const onFetchNotifications = function*() {
  try {
    const response = yield call(getNotificationsRequest)
    const notifications = formatNotifications(response)
    yield put(setNotifications(notifications))
  } catch (error) {
    yield put(getNotificationsFailure({ error }))
  }
}

export const onDismissNotificationsByBaseType = function*(action) {
  const { baseType } = action.payload
  const notifications = yield select(selectNotifications)
  const notificationsWithBaseType = notifications.filter(n => n.base_type === baseType)

  for (let i = 0; i < notificationsWithBaseType.length; i++) {
    yield put(dismissNotification({ id: notificationsWithBaseType[i].id, updateUI: true }))
  }
}

export const onDismissNotification = function*(action) {
  const { id, updateUI } = action.payload
  try {
    yield call(() => dismissNotificationRequest(id))

    if (updateUI) {
      yield put(removeNotification({ notificationId: id }))
    }

    yield put(getNotifications())
  } catch (res) {
    if (res.response) {
      res.response.json().then(() => {
        ErrorHelper.notifyError(`Error attempting to dismiss notification ${id}`)
      })
    } else {
      ErrorHelper.notifyError(`Error attempting to dismiss notification ${id}`)
    }
  }
}

const watchNotifications = function*() {
  yield all([
    takeLatest(getNotifications.type, onFetchNotifications),
    takeLatest(dismissNotificationsByBaseType.type, onDismissNotificationsByBaseType),
    takeLatest(dismissNotification.type, onDismissNotification),
  ])
}

export default watchNotifications
