import { ValueOf } from 'app/types'
import { EBillingPlanFrequency, EBillingPlanIntervalUnit } from 'app/redux/membershipTiers/schema'
import { StepConfig } from '../../OnboardingApp/_layout/OnboardingProgressTracker/ProgressTracker/ProgressTracker'

export const onboardingSteps = {
  ONBOARDING_INITIATED: 'onboarding_initiated',
  ACCOUNT_INFO_COMPLETED: 'account_info_completed',
  FINANCIAL_PLAN_INFO_COMPLETED: 'financial_plan_info_completed',
  BANK_LINK_COMPLETED: 'bank_link_completed',
  ONBOARDING_COMPLETED: 'onboarding_completed',
  CONFIRMATION: 'confirmation',
}

export enum EOnboardingSteps {
  'onboarding_initiated', // 0
  'account_info_completed', // 1
  'financial_plan_info_completed', // 2
  'bank_link_completed', // 3
  'onboarding_completed', // 4
  'confirmation', // 5
}

export enum EPaymentMethod {
  CREDIT = 'credit_card',
  BANK = 'bank_link',
}

export enum EMobileWalletType {
  Google,
  Both, // Apple and Google pay
  None,
}

export const getStepValueByName = (step: string): number => EOnboardingSteps[step]
export const getStepNameByValue = (value: number): string => EOnboardingSteps[value]

export interface IAccountInfo {
  firstName?: string
  lastName?: string
  middleName?: string | undefined
  phoneNumber?: string
  zipcode?: string
  general?: { message: string }
}

export interface IFinancialPlanInfo {
  children?: string | number | null
  currentSalary?: number | null
  dateOfBirth?: string
  educationLevel?: string
  gender?: string
  general?: { message: string }
  householdIncome?: number | null
  maritalStatus?: string
}

export interface IFinancialPlanInfoAPI {
  children: number
  createdAt: string
  currentAge: number
  currentSalary: string
  dateOfBirth: string
  educationLevel: string
  gender: string
  householdIncome?: string | null
  maritalStatus: string
  zipcode: string
}

export interface IPaymentInfo {
  paymentMethod?: string
  paymentToken?: undefined
}

export interface IMembershipCreationData {
  recurlyPlanCode: string
  couponCode?: string
  membershipAgreement: boolean
  general?: { message: string }
  paymentMethod?: string
  paymentToken?: string // credit card token
}

export interface IBillingFrequencyInfo {
  recurlyPlanCode?: string
  couponCode?: string
}

export interface IBillingPlan {
  amount: number
  annualizedAmount: number
  discountPercentage: number
  frequency: EBillingPlanFrequency.MONTHLY | EBillingPlanFrequency.ANNUAL
  intervalUnit: EBillingPlanIntervalUnit.MONTH | EBillingPlanIntervalUnit.YEAR
  monthlyAmount: number
  recurlyPlanCode: string
}

export interface IOnboardingReducer {
  acccountInfo: IAccountInfo
  financialPlanInfo: IFinancialPlanInfo
  investing: IOnboardingInvesting
  isLoading: false
  isOnboardingDataSuccess: false
  isOnboardingDataFailed: false
  membership: IOnboardingMembership
  onboardingStep: ValueOf<EOnboardingSteps>
  onboardingStepSuccess: false
  onboardingStepFailed: false
  promoCode: string
  billingPlans: Array<IBillingPlan>
  billingPlansSuccess: boolean
  billingPlansFailed: boolean
}

// To be extracted
export interface IOnboardingInvesting {
  enableImpactPortfolio: boolean
  monthlyDeposit?: number
}

// To be deprecated!
export interface IOnboardingMembership {
  madlibsInfo: {
    onboarding_state: string
  }
  isEditingPersonalInformation: boolean
}

export interface IOnboardingContext {
  isDigitalWalletUser: boolean
  navigationStepsConfig: Array<StepConfig>
  paymentMethod: EPaymentMethod
  paymentMethodErrorModalData: Record<string, unknown>
  handleClickNext: () => void
  setIsDigitalWalletUser: (boolean) => void
  setDisplayError: React.Dispatch<React.SetStateAction<boolean>>
  setPaymentMethod: (EPaymentMethod) => void
  updateOnboardingStep: (hasForeignAccount?: boolean) => void
}
