/* eslint-disable no-unused-vars */
import { logout } from 'app/requests/auth'
import routes from 'app/routes/authRoutes'
import { getExclusionsByTier } from 'app/utils/membershipExclusions'

import ErrorHelper from 'js/ErrorHelper'

export const redirectToSignIn = () => {
  window.location.href = routes.SIGN_IN
}

export const signOutAndRedirect = () => {
  logout()
    .then(() => {
      redirectToSignIn()
    })
    .catch((res) => {
      if (res.response) {
        res.response.json().then(() => {
          ErrorHelper.notifyError('Unhandled API Response: Navigation Sign Out', {
            url: routes.SIGN_OUT,
          })
        })
      } else {
        ErrorHelper.notifyError('Sign Out success handler failed')
      }
    })
}


const DEFAULT_INCLUDED = [
  'folioMembers',
  'personMemberships',
  'membershipTiers',
  'membershipTierSets',
  'membershipTierBillingPlans',
]

export const handleUserIncludedData = (newUser, included) => {
  const data = {
    folioMembers: null,
    folioAccounts: [],
    referrals: [],
    personMemberships: null,
    personMembershipTiers: {},
    personMembershipTierSets: {},
    membershipTierBillingPlans: {},
  }

  for (const d of included) {
    if (d) {
      if (DEFAULT_INCLUDED.includes(d.type)) {
        data[d.type] = d
      } else if (d.type === 'referrals') {
        const referral = {
          inviteId: d.id,
          ...d.attributes,
        }
        data[d.type].push(referral)
      } else {
        data.folioAccounts.push(d)
      }
    }
  }

  if (data.folioMembers) {
    const { username, statements } = data.folioMembers.attributes

    const folioAccounts = data.folioAccounts.map(({ id, type, attributes }) => ({
      accountId: id,
      accountType: type,
      ...attributes,
    }))

    newUser.folioMember = {
      folioAccounts,
      folioUsername: username,
      statements,
    }
  }

  if (data.personMemberships) {
    newUser.personMembership = {
      ...data.personMemberships.attributes,
      membershipTier: data.membershipTiers.attributes,
      membershipTierSet: data.membershipTierSets.attributes,
      membershipTierBillingPlan: data.membershipTierBillingPlans.attributes,
    }
    newUser.personMembership.exclusions = getExclusionsByTier(
      newUser.personMembership.membershipTier.tierSetPosition
    )
  }

  if (data.referrals) newUser.invites.referrals = data.referrals

  return newUser
}
