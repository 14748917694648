import { redirect } from 'redux-first-router'
import { getPlan } from 'app/requests/investmentPlan'

export const TRANSFER_ACCOUNT_ROOT = '/transfer-account'

// TRANSFER FLOW (entrypoints)
const TRANSFER_ROOT = '/transfer'
const TRANSFER_ACCOUNT = `${TRANSFER_ACCOUNT_ROOT}/:type?`

// Member Creation Steps
const TRANSFER_INFO = '/transfer/info'
const TRANSFER_IDENTITY = '/transfer/identity'
const TRANSFER_EMPLOYMENT = '/transfer/employment'

// Deposit Flow
const TRANSFER_SELECT_GOALS = '/transfer/select-goals'
const TRANSFER_CONFIRM_DEPOSITS = '/transfer/confirm-deposits'
const TRANSFER_LINK_BANK = '/transfer/link-bank'

// Rollovers
const ROLLOVER_BENEFITS = '/rollover/benefits'
const ROLLOVER_STATUS = '/rollover/status'
const ROLLOVER_ACCOUNT_NOT_ELIGIBLE = '/rollover/account-not-eligible'

// External Account Transfer Steps (BAT, 40x, IRA)
const TRANSFER_ACCOUNT_DETAILS = '/transfer/account-details'
const TRANSFER_TRANSFER_DETAILS = '/transfer/transfer-details'
const TRANSFER_SELECT_GOAL = '/transfer/select-goal'
const TRANSFER_SELECT_ACCOUNT = '/transfer/select-account'
const TRANSFER_EXPLORE_PORTFOLIO = '/transfer/explore-portfolio'
const TRANSFER_CONFIRM_TRANSFER = '/transfer/confirm'
const TRANSFER_NEXT_STEPS = '/transfer/next-steps'
const TRANSFER_CALL_PROVIDER = '/transfer/call-provider'

// DIRECT DEPOSIT & WIRE TRANSFERS
const TRANSFER_FUNDS_INSTRUCTIONS = '/transfer-funds/instructions/:transferType'
/* Route is been used on email CTA! */
const TRANSFER_FUNDS_ALLOCATE = '/transfer-funds/allocate'

// Used for initializing 40x rollover flow (pre-BICE)
const TRANSFER_ACCOUNT_INFO = {
  path: '/transfer/account-info',
  thunk: dispatch => {
    dispatch(redirect({
      type: 'TRANSFER_ACCOUNT_DETAILS',
      query: { type: 'rollover_401k' }
    }))
  },
}

// Used for dropoff, and redirecting deprecated step URLs
const TRANSFER_DROPOFF = {
  path: '/transfer/:step',
  thunk: dispatch => {
    dispatch(redirect({ type: 'TRANSFER_ROOT' }))
  },
}

// Redirect from fund for deposit flow refactor
const FUND_ROOT = {
  path: '/fund',
  thunk: dispatch => {
    dispatch(redirect({ type: 'TRANSFER_ROOT', query: { type: 'deposit' } }))
  },
}

// This redirect needed for bank link dropoff email
const FUND_BANK_ACCOUNT = {
  path: '/fund/bank_account',
  thunk: dispatch => {
    dispatch(redirect({ type: 'TRANSFER_CONFIRM_DEPOSITS' }))
  },
}

const FUND_GOAL = {
  path: '/fund/:goalId',
  thunk: (dispatch, getState) => {
    const { location } = getState()
    const { goalId } = location.payload

    // TODO Investigate if doing a server-side redirect is possible here
    getPlan().then(data => {
      const { selected_goals } = data

      let validGoalId = false
      validGoalId =
        selected_goals &&
        selected_goals.find(g => g.goal_id === goalId && g.active && g.ellevest_accounts.length > 0)

      if (validGoalId) {
        dispatch(redirect({ type: 'TRANSFER_ROOT', query: { type: 'deposit', goalId } }))
      } else {
        dispatch(redirect({ type: 'TRANSFER_ROOT', query: { type: 'deposit' } }))
      }
    })
  },
}

const FUND_ROLLOVER_ROOT = {
  path: '/fund/rollover/:accountId',
  thunk: dispatch => {
    dispatch(redirect({ type: 'FUND_ROOT' }))
  },
}

const FUND_CIP_VERIFY = '/verify'

export const fundFraudRestrictedRoutes = {
  TRANSFER_ROOT,
  TRANSFER_IDENTITY,
  TRANSFER_EMPLOYMENT,
  TRANSFER_CONFIRM_DEPOSITS,
  TRANSFER_SELECT_GOALS,
  TRANSFER_ACCOUNT_ROOT,
  TRANSFER_ACCOUNT,
  TRANSFER_EXPLORE_PORTFOLIO,
  TRANSFER_CONFIRM_TRANSFER,
}

export default {
  ROLLOVER_BENEFITS,
  ROLLOVER_STATUS,
  ROLLOVER_ACCOUNT_NOT_ELIGIBLE,
  TRANSFER_ACCOUNT,
  TRANSFER_ACCOUNT_INFO,
  TRANSFER_FUNDS_INSTRUCTIONS,
  TRANSFER_FUNDS_ALLOCATE,
  TRANSFER_ROOT,
  TRANSFER_INFO,
  TRANSFER_IDENTITY,
  TRANSFER_EMPLOYMENT,
  TRANSFER_SELECT_GOALS,
  TRANSFER_SELECT_ACCOUNT,
  TRANSFER_ACCOUNT_DETAILS,
  TRANSFER_TRANSFER_DETAILS,
  TRANSFER_CALL_PROVIDER,
  TRANSFER_SELECT_GOAL,
  TRANSFER_EXPLORE_PORTFOLIO,
  TRANSFER_CONFIRM_DEPOSITS,
  TRANSFER_CONFIRM_TRANSFER,
  TRANSFER_LINK_BANK,
  TRANSFER_NEXT_STEPS,
  TRANSFER_DROPOFF,
  FUND_ROLLOVER_ROOT,
  FUND_BANK_ACCOUNT,
  FUND_GOAL,
  FUND_CIP_VERIFY,
  FUND_ROOT,
}
