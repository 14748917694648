/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { IFilterParams } from './schema'
import { useCamelCasing } from 'app/requests/adapters/apiAdapter'
import {
  appendCompletedTransactionsSlice,
  getTransactionSummaryFailure,
  getCompletedTransactionsSlice,
  getCompletedTransactionsSliceFailure,
  getTransactionSummary,
  setTransactionSummary,
  filterTransactionSummary,
  setFilteredTransactionSummary,
  setFilteredTransactionSummaryFailure,
} from './reducer'
import {
  fetchTransactionSummary,
  fetchCompletedTransactionsSlice,
  filterTransactionsSummary,
} from 'app/requests/transactionSummary'

import { selectCompletedTransactionsIndex } from 'app/selectors/transactionSummary'

const formatTransaction = ({ attributes, relationships, ...rest }, canIncludeAutoDeposit) => {
  const { autoDeposit } = relationships
  const formattedRelationships: Record<string, unknown> = {}

  if (canIncludeAutoDeposit) {
    formattedRelationships.autoDeposit = autoDeposit
  }

  return useCamelCasing({ ...attributes, ...formattedRelationships, ...rest })
}

const formatAutoDeposit = ({ attributes, rest }) => ({ ...attributes, rest })

export const formatTransactions = (transactions) => {
  let formatted = transactions.data.map((t) => formatTransaction(t, transactions.included))

  if (transactions.included) {
    const autoDeposits = transactions.included.filter((included) => included.type === 'autoDeposit')

    formatted = formatted.map((transaction) => {
      if (transaction.autoDeposit && transaction.autoDeposit.data) {
        const autoDeposit = autoDeposits.find(({ id }) => transaction.autoDeposit.data.id === id)

        if (!autoDeposit) return { ...transaction, autoDeposit: null }

        return {
          ...transaction,
          autoDeposit: formatAutoDeposit(autoDeposit),
        }
      }

      return { ...transaction, autoDeposit: null }
    })
  } else {
    // Eliminates residual autodeposit object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formatted = formatted.map(({ autoDeposit, ...transaction }) => transaction)
  }

  return formatted
}

const formatSummary = (rawSummary) => {
  const { upcoming, inProgress, complete, ...summary } = useCamelCasing(rawSummary)

  return {
    ...summary,
    upcomingTransactions: formatTransactions(upcoming),
    inProgressTransactions: formatTransactions(inProgress),
    completedTransactions: formatTransactions(complete),
  }
}

export const onFetchTransactionSummary = function* () {
  try {
    const rawSummary = yield call(fetchTransactionSummary)
    const transactionSummary = yield formatSummary(rawSummary)
    yield put(setTransactionSummary(transactionSummary))
  } catch (error) {
    yield put(getTransactionSummaryFailure(error))
  }
}

export const onFetchCompletedTransactionsSlice = function* (action: TFilterAction) {
  try {
    const sliceIndex = yield select(selectCompletedTransactionsIndex)
    const nextPage =  sliceIndex + 1
    const rawTransactionSlice = yield call(
      fetchCompletedTransactionsSlice,
      nextPage,
      action.payload
    )
    const completedTransactionsSlice = rawTransactionSlice.data.map(formatTransaction)
    yield put(appendCompletedTransactionsSlice(completedTransactionsSlice))
  } catch (error) {
    yield put(getCompletedTransactionsSliceFailure(error))
  }
}

type TFilterAction = PayloadAction<IFilterParams>

export const onFilterTransactions = function* (action: TFilterAction) {
  try {
    const rawSummary = yield call(filterTransactionsSummary, action.payload)
    const transactionSummary = yield formatSummary(rawSummary)
    yield put(setFilteredTransactionSummary(transactionSummary))
  } catch (error) {
    yield put(setFilteredTransactionSummaryFailure(error))
  }
}

const watchTransactionSummary = function* () {
  yield all([
    takeLatest(getTransactionSummary.type, onFetchTransactionSummary),
    takeLatest(getCompletedTransactionsSlice.type, onFetchCompletedTransactionsSlice),
    takeLatest(filterTransactionSummary.type, onFilterTransactions),
  ])
}

export default watchTransactionSummary
