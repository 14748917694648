import { EForeignAccountStatus } from 'app/redux/foreignAccount/schema'

// API ADAPTERS
export const flattenAccount = (account)  => {
  const { id, type, attributes = {} } = account || {}

  return { id, type, ...attributes }
}

export const filterForeignAccounts = (data)  => {
  let foreignAccount = undefined

  for (const accountData of data) {
    const acc = flattenAccount(accountData)

    if (acc.type === 'foreignAccounts' && acc.active && acc.status !== 'cancelled') {
      foreignAccount = acc
    }
  }

  return foreignAccount
}

// Foreign Account Restrictions
const {
  AWAITING_CLIENT_DOC_UPLOAD,
  MISSING_ACH_INFORMATION,
  PENDING_MANUAL_IDENTITY_REVIEW,
  PENDING_MANUAL_ACH_UPDATE,
} = EForeignAccountStatus

export const restrictedForeignAccountStatus = [
  AWAITING_CLIENT_DOC_UPLOAD,
  MISSING_ACH_INFORMATION,
  PENDING_MANUAL_IDENTITY_REVIEW,
  PENDING_MANUAL_ACH_UPDATE,
]

export const DOCUMENTATION_GROUPS = {
  [`${AWAITING_CLIENT_DOC_UPLOAD}`]: 'plaidNameMismatch',
  [`${MISSING_ACH_INFORMATION}`]: 'missingAchInformation',
  [`${PENDING_MANUAL_IDENTITY_REVIEW}`]: 'pendingManualStates',
  [`${PENDING_MANUAL_ACH_UPDATE}`]: 'pendingManualStates',
}


export const getDocumentationGroup = (restrictedBy) => {
  let documentationGroup = DOCUMENTATION_GROUPS.PLAID_NAME_MISMATCH

  if (restrictedBy === EForeignAccountStatus.MISSING_ACH_INFORMATION) {
    documentationGroup = DOCUMENTATION_GROUPS.MISSING_ACH_INFORMATION
  }

  if (
    restrictedBy === EForeignAccountStatus.PENDING_MANUAL_IDENTITY_REVIEW ||
    restrictedBy === EForeignAccountStatus.PENDING_MANUAL_ACH_UPDATE
  ) {
    documentationGroup = DOCUMENTATION_GROUPS.PENDING_MANUAL_STATES
  }

  return { documentationGroup }
}

export const getForeignAccountRestrictions = (foreignAccount, foreignAccountRestrictions)  => {
  const { status } = foreignAccount
  const isRestricted = restrictedForeignAccountStatus.includes(status)

  if (!isRestricted) return foreignAccountRestrictions

  return {
    restricted: isRestricted,
    restrictedBy: status,
    documentationGroup: DOCUMENTATION_GROUPS[status],
  }
}
