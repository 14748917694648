import { redirect } from 'redux-first-router'

const ONBOARDING_ROOT = {
  path: '/onboarding',
  thunk: (dispatch, getState) => {
    let query
    if (getState().location.query) {
      query = getState().location
    }
    console.log('redirecting ONBOARDING', query)
    dispatch(redirect({ type: 'ONBOARDING', meta: { query } }))
  },
}

const onboardingRoutes = {
  ONBOARDING_ROOT,
  ONBOARDING_SETUP_BUILTBYWOMENFORWOMEN: '/onboarding/setup/built-for-women-by-women',
  ONBOARDING_SETUP_GENDER_AWARE: '/onboarding/setup/gender-aware',
  ONBOARDING_SETUP_GUIDANCE: '/onboarding/setup/guidance',
  ONBOARDING_SETUP_HOW_TO_GET_STARTED: '/onboarding/setup/how-to-get-started',
  ONBOARDING_SETUP_ACCOUNT_INFO: '/onboarding/setup/account-info',
  ONBOARDING_SETUP_FINANCIAL_PLAN_INFO: '/onboarding/setup/financial-plan-info',
  ONBOARDING_SETUP_PLAN_OVERVIEW: '/onboarding/setup/plan-overview',
  ONBOARDING_SETUP_YOUR_GOALS: '/onboarding/setup/your-goals',
  ONBOARDING_SETUP_CHECKOUT_BILLING_FREQUENCY: '/onboarding/setup/checkout/billing-frequency',
  ONBOARDING_SETUP_CHECKOUT_PAYMENT_METHOD: '/onboarding/setup/checkout/payment-method',
  ONBOARDING_SETUP_CHECKOUT_CONFIRM: '/onboarding/setup/checkout/confirm',
  ONBOARDING_SETUP_CONFIRMATION: '/onboarding/setup/confirmation',
}

export default onboardingRoutes
