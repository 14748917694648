import { get } from 'lodash'
import store from 'app/store'

import {
  BROKERAGE_ACCOUNT,
  TRADITIONAL_IRA_ACCOUNT,
  ROTH_IRA_ACCOUNT,
  SEP_IRA_ACCOUNT,
} from 'app/content/accountTypes'
import GOAL_TYPES from 'app/content/goalTypes'
import { isCancelFinishedState } from 'app/utils/cancellation'

import { hasForeignAccountData } from 'app/utils/foreignAccount'
import { allowMembership } from 'app/utils/membershipExclusions'
import { INVESTING_VERIFICATION_STATUS } from 'app/constants/verificationStatus'

import { eolExperienceEnabled } from 'app/utils/featureFlags'

// membership
export const getMembershipTier = (user = {}) => get(user, 'personMembership.membershipTier', {})
export const noMembership = (user) => get(user, 'personMembership.membershipTier', null) == null

export const userHasActiveMembership = (user = {}) => user.personMembership?.active

export const userHasEllevestPlan = (user = {}) => {
  const { uniqueKey } = getMembershipTier(user)

  return uniqueKey === 'ellevest_plan_may_2023'
}

export const memberIsFraudRestricted = (user) =>
  user && user.personMembership && user.user_state && user.user_state.membershipRestriction

// this function help us identify users that signed up after an AB experiment is created
export const memberActivatedAfterAbExperiment = (user = {}, experiment = {}) => {
  const { personMembership } = user

  return personMembership?.activatedAt >= experiment.experiment_created_at
}

// user state
export const userIsCanceled = (user) =>
  eolExperienceEnabled(user) ||
  (!!user &&
    !!user.personMembership &&
    !!user.personMembership.deactivatedAt &&
    !user.personMembership.active) ||
  userHasCancelFinishedState()

export const userIsEsignedClient = (user) => user && user.user_state && user.user_state.hasESigned

export const userIsClient = (user) => get(user, 'user_state.is_client', false)

export const userIsLegacyClient = (user) => noMembership(user) && userIsClient(user)

export const userIsOnboarding = (user) => noMembership(user) && !userIsClient(user)

export const userNeedsEsignature = (goals) => {
  if (goals) {
    for (let i = 0; i < goals.length; i++) {
      const goal = goals[i]
      for (let j = 0; j < goal.ellevest_accounts.length; j++) {
        const account = goal.ellevest_accounts[j]
        if (!account.esigned) {
          return true
        }
      }
    }
  }

  return false
}

export const userHasNotActivatedPillar = (user) =>
  allowMembership(user) && !userIsEsignedClient(user)

// folio & IRA
export const userFolioUsername = (user) => get(user, 'folioMember.folioUsername')

export const userFolioAccounts = (user) => get(user, 'folioMember.folioAccounts', [])

export const userFolioAccount = (user, accountType) =>
  userFolioAccounts(user).find((a) => a.accountType === accountType)

// note: only the funded accounts will have a folio account
export const userBrokerageAccount = (user) => userFolioAccount(user, BROKERAGE_ACCOUNT.key)

export const userIRAAccounts = (user) =>
  userFolioAccounts(user).filter((acc) => acc.accountType !== BROKERAGE_ACCOUNT.key)

export const noIRAAccounts = (user) => !userIRAAccounts(user)

export const hasIRAAccounts = (user) => !!userIRAAccounts(user)

/**
 * Checks to see if the client has an esigned retirement goal
 * NOTE: funded === esigned in this context
 * @param {object} fundedGoals
 * @returns {boolean}
 */
export const hasEsignedRetirementGoal = (fundedGoals) =>
  !!fundedGoals.find((goal) => goal.goal_type === GOAL_TYPES.RETIREMENT_GOAL)

/**
 * Checks to see if a user has a funded ira account
 * the definition of funded for this use case is user having
 * ira account balance > 0
 * @param {object} fundedGoals
 * @returns {boolean}
 */
export const hasFundedIRA = (fundedGoals) => getFundedIRAAccounts(fundedGoals).length > 0

/**
 * Returns funded ira accounts
 * the definition of funded for this is user having
 * ira account balance > 0
 * @param {object} fundedGoals
 * @returns {object}
 */
export const getFundedIRAAccounts = (fundedGoals) => {
  const iraAccountTypes = [TRADITIONAL_IRA_ACCOUNT.key, ROTH_IRA_ACCOUNT.key, SEP_IRA_ACCOUNT.key]
  const retirementGoal = fundedGoals.find((goal) => goal.goal_type === GOAL_TYPES.RETIREMENT_GOAL)
  let fundedIRAAccounts = []
  if (retirementGoal) {
    fundedIRAAccounts = retirementGoal.ellevest_accounts.filter(
      (account) => iraAccountTypes.includes(account.account_type) && account.balance > 0
    )
  }

  return fundedIRAAccounts
}

/**
 * Returns funded IRA folio accounts
 * the definition of funded for this is user having
 * ira account balance > 0
 * @param {object} fundedGoals
 * @param {object} user
 * @returns {object}
 */
export const getFundedIRAFolioAccounts = (fundedGoals, user) => {
  const fundedIRAAccounts = getFundedIRAAccounts(fundedGoals)
  const folioIRAAccounts = userIRAAccounts(user)

  return folioIRAAccounts.filter((folioAccount) =>
    fundedIRAAccounts.some(
      (account) => folioAccount.accountId === account.bank_link.folio_account_id
    )
  )
}

// user verification
export const userRequiresInvestingDocs = (user) => {
  const { needsIdentityUpload, cip_status } = user['user_state']

  return needsIdentityUpload || cip_status === INVESTING_VERIFICATION_STATUS.FAILED_REVERIFICATION
}

export const userRequiresVerificationDocuments = (user) => userRequiresInvestingDocs(user)

export const usersInvestingVerificationInProcess = (user) => {
  const { cip_status } = user['user_state']

  return cip_status === INVESTING_VERIFICATION_STATUS.UPLOADED_IDENTITY_DOC
}

export const userVerificationInProgress = (user) => usersInvestingVerificationInProcess(user)

export const userCompletedInvestingVerification = (user) =>
  !userRequiresInvestingDocs(user) && !usersInvestingVerificationInProcess(user)

export const userIsVerified = (user) => userCompletedInvestingVerification(user)
// User's verification ends

// Runs methods above and returns an object with the common user states
export const getUserState = () => {
  const { foreignAccount, user } = store.getState()

  return {
    CANCELLED: userIsCanceled(user),
    FRAUD_RESTRICTED: memberIsFraudRestricted(user),
    RESTRICTED: memberIsFraudRestricted(user) || !userIsVerified(user),
    VERIFIED: userIsVerified(user),
    HAS_BROKEN_BANK_LINK: hasForeignAccountData(foreignAccount),
  }
}

export const getUserAge = () => {
  const { investmentPlan } = store.getState()

  return (
    investmentPlan &&
    investmentPlan.investmentPlanInfo &&
    investmentPlan.investmentPlanInfo.current_age
  )
}

/**
 * Indicates if a user is billed annually
 * @param {object} user
 * @returns {boolean}
 */
export const userIsBilledAnnually = (user) =>
  user?.personMembership?.membershipTierBillingPlan?.intervalUnit === 'year'

/**
 * Indicates if a user is billed monthly
 * @param {object} user
 * @returns {boolean}
 */
export const userIsBilledMonthly = (user) =>
  user?.personMembership?.membershipTierBillingPlan?.intervalUnit === 'month'

const userHasCancelFinishedState = () =>
  isCancelFinishedState(get(store.getState(), 'cancellationState.currentState'))

export default {
  userIsEsignedClient,
  userNeedsEsignature,
}
