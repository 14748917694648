/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'

const getNotifications = (state) => state.notifications

export const selectNotifications = (state) => state.notifications.notifications || []

export const alertsSelector = createSelector(
  [getNotifications],
  (notifications) => notifications.alerts || []
)

export const remindersSelector = createSelector(
  [getNotifications],
  (notifications) => notifications.reminders || []
)
