import { clientAppRequest, formatData } from './requests'

export const getUser = (authRequired = true) =>
  clientAppRequest('/api/v1/people/me', 'GET', {}, authRequired)

export const createUser = (data) =>
  clientAppRequest('/api/people', 'POST', {
    body: formatData({ person: data }),
  })

export const createEmailSubscription = (data) =>
  clientAppRequest('/api/v2/onboarding/email_signups', 'POST', {
    body: formatData(data),
  })

// Revise Sift Decision
export const confirmInformation = () =>
  clientAppRequest('/api/v1/people/confirm_information', 'POST')

export const login = (data) =>
  clientAppRequest('/api/people/sign_in', 'POST', {
    body: formatData({ person: data }),
  })

export const logout = () => clientAppRequest('/api/people/sign_out')

export const renewSession = () => clientAppRequest('/api/sessions/ping')

export const resendConfirmationEmail = (email) =>
  clientAppRequest(`/api/people/reconfirmations`, 'POST', {
    body: formatData({ person: { email } }),
  })

export const updateUser = (data) =>
  clientAppRequest('/api/v1/people/me', 'PUT', {
    body: formatData(data),
  })

export const updateAuthentication = (data) =>
  clientAppRequest('/api/v1/authentication', 'PUT', {
    body: formatData({ authentication: data }),
  })

export const resend2FACode = (insights = false, phoneNumberValidationToken) =>
  clientAppRequest('/api/v1/authentication', 'PUT', {
    body: formatData({
      authentication: {
        token: phoneNumberValidationToken,
        include_insights: insights,
      },
    }),
  })

export const validate2FACode = (pin, phoneNumberValidationToken) =>
  clientAppRequest('/api/v1/authentication', 'PUT', {
    body: formatData({
      authentication: { pin, token: phoneNumberValidationToken },
    }),
  })
