import React from 'react'
import PropTypes from 'prop-types'
import styles from './LoggedOutFooter.module.scss'

const LoggedOutFooter = ({ children }) => <footer className={styles.Footer}>{children}</footer>

export default LoggedOutFooter

LoggedOutFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
