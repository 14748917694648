import { clientAppRequest } from './requests'

/**
 * GET all notifications for current user
 */
export const getNotifications = () => clientAppRequest('/api/v1/notices')

/**
 * DELETE (acknowledge) a notification
 *
 * @param id - ID of notification to dismiss
 */
export const dismissNotification = id =>
  clientAppRequest(`/api/v1/notifications/${id}?type=UserNotification`, 'DELETE')

/**
 * DELETE (acknowledge) a global user notification
 *
 * @param id - ID of notification to dismiss
 */
export const dismissGlobalNotification = id =>
  clientAppRequest(`/api/v1/notifications/${id}?type=GlobalUserNotification`, 'DELETE')
