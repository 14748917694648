import { createSelector } from 'reselect'

export const getLocation = (state) => state.location

export const getLocationQuery = createSelector([getLocation], (location) => location.query || null)

export const getLocationPayload = createSelector(
  [getLocation],
  (location) => location.payload || null
)

export const getLocationPathname = createSelector(
  [getLocation],
  (location) => location.pathname || null
)

export const getLocationType = createSelector([getLocation], (location) => location.type || null)
