// Defines string constants for all Redux actions

/** Composed actions */
// These action are a combination of one or more individual actions
// The purpose of these is to be able fetch the entities in parallel which
// will give us a small performance boost
export const FETCH_USER_AND_INVESTMENT_PLAN = 'FETCH_USER_AND_INVESTMENT_PLAN'
export const REFRESH_APP = 'REFRESH_APP'

/** System */
const SET_ENVIRONMENT = 'SET_ENVIRONMENT'
const SET_WINDOW_MODE = 'SET_WINDOW_MODE'
const OPEN_SITE_NAV = 'OPEN_SITE_NAV'
const UPDATE_SITE_NAV = 'UPDATE_SITE_NAV'
const CLOSE_SITE_NAV = 'CLOSE_SITE_NAV'
const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE'
const SET_BANNER_MESSAGE = 'SET_BANNER_MESSAGE'
const OPEN_PAGE_NAV = 'OPEN_PAGE_NAV'
const CLOSE_PAGE_NAV = 'CLOSE_PAGE_NAV'
const SHOW_LOADER = 'SHOW_LOADER'
const HIDE_LOADER = 'HIDE_LOADER'
const SHOW_FPS_ERROR = 'SHOW_FPS_ERROR'
const HIDE_FPS_ERROR = 'HIDE_FPS_ERROR'

/** Authentication */
const GET_USER = 'GET_USER'
const SET_USER = 'SET_USER'
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
const AUTH_FAILED = 'AUTH_FAILED'
const AUTH_SUCCESS = 'AUTH_SUCCESS'
const UPDATE_FOLIO_ACCOUNT_SUCCESS = 'UPDATE_FOLIO_ACCOUNT_SUCCESS'

/** Onboarding */
const SET_EXTERNAL_ACCOUNTS = 'SET_EXTERNAL_ACCOUNTS'
const SET_EXTERNAL_ACCOUNT = 'SET_EXTERNAL_ACCOUNT'
const REMOVE_EXTERNAL_ACCOUNT = 'REMOVE_EXTERNAL_ACCOUNT'

/** GOALS */
const SET_GOALS = 'SET_GOALS'
const ADD_GOAL_BY_TYPE = 'ADD_GOAL_BY_TYPE'
const REMOVE_GOAL_BY_TYPE = 'REMOVE_GOAL_BY_TYPE'
const REMOVE_GOAL = 'REMOVE_GOAL'
const MOVE_GOAL = 'MOVE_GOAL'
const UPDATE_GOAL_DATA = 'UPDATE_GOAL_DATA'

/** ACCOUNTS **/
const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'
const SET_ACCOUNTS = 'SET_ACCOUNTS'
const SET_ACCOUNT = 'SET_ACCOUNT'

/** Dashboard */
const FETCH_INVESTMENT_PLAN = 'FETCH_INVESTMENT_PLAN'
const FETCH_INVESTMENT_PLAN_FAILED = 'FETCH_INVESTMENT_PLAN_FAILED'
const SET_INVESTMENT_PLAN = 'SET_INVESTMENT_PLAN'
const SET_INVESTMENT_PLAN_INFO = 'SET_INVESTMENT_PLAN_INFO'
const SET_GOAL = 'SET_GOAL'
const RESET_GOAL = 'RESET_GOAL'
const SET_MODEL = 'SET_MODEL'
const SET_PROJECTIONS = 'SET_PROJECTIONS'
const RESET_PROJECTIONS = 'RESET_PROJECTIONS'

const RESET_DRAFT = 'RESET_DRAFT'
const SET_DRAFT_GOAL = 'SET_DRAFT_GOAL'
const SET_DRAFT_ACCOUNT = 'SET_DRAFT_ACCOUNT'
const SET_DRAFT_PROJECTIONS = 'SET_DRAFT_PROJECTIONS'
const SET_DRAFT_MODEL = 'SET_DRAFT_MODEL'
const OPEN_GOAL_NAV = 'OPEN_GOAL_NAV'
const CLOSE_GOAL_NAV = 'CLOSE_GOAL_NAV'

/** FUNDING FLOWS */
const SET_FUNDING_FLOW_TYPE = 'SET_FUNDING_FLOW_TYPE'
const SET_FUNDING_FLOW = 'SET_FUNDING_FLOW'
const UPDATE_FUNDING_DATA = 'UPDATE_FUNDING_DATA'
const RESET_FUNDING_DATA = 'RESET_FUNDING_DATA'
const SET_ORIGIN_ACCOUNT = 'SET_ORIGIN_ACCOUNT'
const SET_PROVIDERS = 'SET_PROVIDERS'

/** Membership Tiers */

/** Goal Planner */
const INIT_PLANNER_SESSION = 'INIT_PLANNER_SESSION'
const REMOVE_ADVICE = 'REMOVE_ADVICE'
const ADD_ADVICE_SUCCESS = 'ADD_ADVICE_SUCCESS'

/** Modals */
const OPEN_MODAL = 'OPEN_MODAL'
const CLOSE_MODAL = 'CLOSE_MODAL'

const OPEN_AUTH_FLOW = 'OPEN_AUTH_FLOW'
const CLOSE_AUTH_FLOW = 'CLOSE_AUTH_FLOW'

/** Product intro */
const TOGGLE_PRODUCT_INTRO = 'TOGGLE_PRODUCT_INTRO'

export {
  SET_ENVIRONMENT,
  SET_WINDOW_MODE,
  OPEN_SITE_NAV,
  UPDATE_SITE_NAV,
  CLOSE_SITE_NAV,
  SET_FLASH_MESSAGE,
  SET_BANNER_MESSAGE,
  OPEN_PAGE_NAV,
  CLOSE_PAGE_NAV,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_FPS_ERROR,
  HIDE_FPS_ERROR,
  GET_USER,
  SET_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_FOLIO_ACCOUNT_SUCCESS,
  AUTH_FAILED,
  FETCH_INVESTMENT_PLAN,
  FETCH_INVESTMENT_PLAN_FAILED,
  SET_INVESTMENT_PLAN,
  SET_INVESTMENT_PLAN_INFO,
  SET_EXTERNAL_ACCOUNTS,
  SET_EXTERNAL_ACCOUNT,
  REMOVE_EXTERNAL_ACCOUNT,
  SET_GOALS,
  ADD_GOAL_BY_TYPE,
  REMOVE_GOAL_BY_TYPE,
  REMOVE_GOAL,
  MOVE_GOAL,
  SET_GOAL,
  RESET_GOAL,
  UPDATE_GOAL_DATA,
  FETCH_ACCOUNTS,
  SET_ACCOUNT,
  SET_ACCOUNTS,
  SET_MODEL,
  SET_PROJECTIONS,
  RESET_PROJECTIONS,
  RESET_DRAFT,
  SET_DRAFT_GOAL,
  SET_DRAFT_ACCOUNT,
  SET_DRAFT_PROJECTIONS,
  SET_DRAFT_MODEL,
  INIT_PLANNER_SESSION,
  REMOVE_ADVICE,
  ADD_ADVICE_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_AUTH_FLOW,
  CLOSE_AUTH_FLOW,
  SET_FUNDING_FLOW_TYPE,
  SET_FUNDING_FLOW,
  UPDATE_FUNDING_DATA,
  RESET_FUNDING_DATA,
  SET_ORIGIN_ACCOUNT,
  SET_PROVIDERS,
  OPEN_GOAL_NAV,
  CLOSE_GOAL_NAV,
  TOGGLE_PRODUCT_INTRO,
  AUTH_SUCCESS,
}
