import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  forceCipFailure: false,
}

const testingSlice = createSlice({
  name: 'testing',
  initialState,
  reducers: {
    forceCipFailure(state) {
      return {
        ...state,
        forceCipFailure: true,
      }
    },
  },
})

export const { forceCipFailure } = testingSlice.actions

export default testingSlice.reducer
