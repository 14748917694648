/* eslint-disable no-underscore-dangle */
export const updateSiftRegisteredUser = (user_id) => {
  if (window._sift) {
    window._sift.push(['_setUserId', user_id])
    window._sift.push(['_trackPageview'])
  }
}
/** Notifies Sift of route change */
export const updateSift = () => {
  window._sift && window._sift.push(['_trackPageview'])
}
