import { get } from 'lodash'

const EXCLUSIONS = {
  REFER_A_FRIEND: 'refer_a_friend',
  TRANSFER_BETWEEN_GOALS: 'transfer_between_goals',
  ADD_RETIREMENT_ACCOUNT: 'add_retirement_account',
  PUT_GOALS_ON_HOLD: 'put_goals_on_hold',
  ADD_GOAL: 'add_goal',
  TRANSFER_IRA: 'transfer_ira',
  GOAL_CREATION: {
    // Should match exactly with goal_type
    BUILD_WEALTH: 'build_wealth',
    RETIREMENT: 'retirement',
    HOME: 'home',
    SPLURGE: 'splurge',
    BUSINESS: 'business',
    KIDS: 'children',
  },
  ROLLOVER_401K: 'rollover_401k',
  BENEFICIARIES: 'beneficiaries',
  ON_TRACK_PROGRESS_BAR: 'on_track_progress_bar',
  // Nothing to do?
  CLOSE_BANKING_ACCOUNT: 'close_banking_account',
  CLOSE_INVESTING_ACCOUNT: 'close_investing_account',
  ADD_GOAL_MODAL: {
    ADD_RETIREMENT_ACCOUNT: 'add_goal_modal.add_retirement_account',
    ON_TRACK_PROGRESS_BAR: 'add_goal_modal.on_track_progress_bar',
    PUT_GOALS_ON_HOLD: 'add_goal_modal.put_goals_on_hold',
  },
  RETIREMENT_FLOWS: 'retirement_flows',
  TRANSFER_FLOW: {
    HIDE_EXTERNAL_ACCOUNTS: 'transfer_flow.hide_external_accounts',
    HIDE_IN_PROGRESS_ROLLOVERS: 'transfer_flow.hide_in_progress_rollovers',
    HIDE_IRA_ACCOUNTS: 'transfer_flow.hide_ira_accounts',
    HIDE_401K_ROLLOVERS: 'transfer_flow.hide_401k_rollovers',
  },
  HIDE_BUILD_WEALTH_SETUP_CARD: 'hide_build_wealth_setup_card',
  NAVIGATION: {
    SETUP_GOALS: 'setup_goals',
    SETUP_INVESTING: 'setup_investing',
    SETUP_RETIREMENT: 'setup_retirement',
  },
}

export default EXCLUSIONS

export const getExclusionsFromObject = (exclusions) =>
  Object.keys(exclusions).map((n) => exclusions[n])

const EXCLUSIONS_BY_TIER = {
  all: [EXCLUSIONS.REFER_A_FRIEND, EXCLUSIONS.PUT_GOALS_ON_HOLD],
  1: [
    EXCLUSIONS.ADD_GOAL,
    EXCLUSIONS.RETIREMENT_FLOWS,
    EXCLUSIONS.NAVIGATION.SETUP_GOALS,
    EXCLUSIONS.NAVIGATION.SETUP_RETIREMENT,
    ...getExclusionsFromObject(EXCLUSIONS.TRANSFER_FLOW),
  ],
  2: [EXCLUSIONS.ADD_GOAL, EXCLUSIONS.NAVIGATION.SETUP_GOALS],
  3: [
    EXCLUSIONS.ADD_GOAL_MODAL.ADD_RETIREMENT_ACCOUNT,
    EXCLUSIONS.ADD_GOAL_MODAL.ON_TRACK_PROGRESS_BAR,
    EXCLUSIONS.ADD_GOAL_MODAL.PUT_GOALS_ON_HOLD,
    EXCLUSIONS.HIDE_BUILD_WEALTH_SETUP_CARD,
    EXCLUSIONS.NAVIGATION.SETUP_INVESTING,
  ],
}

const MEMBERSHIP_RESTRICTED_URLS = [
  '/transfer-account/ira',
  '/dashboard/retirement_goal/summary',
  '/dashboard/retirement_goal/advice',
  '/dashboard/retirement_goal/portfolio',
  '/dashboard/retirement_goal/portfolio/edit',
  '/dashboard/retirement_goal/portfolio/external_accounts',
  '/dashboard/retirement_goal/edit',
  '/dashboard/retirement_goal/settings',
  '/dashboard/retirement_goal/transactions',
  '/transfer-account/40x',
  '/rollover_benefits',
  '/rollover/status',
  '/rollover/account-not-eligible',
  '/transfer/account-details?type=rollover_401k&external_origin=null',
  '/transfer/account-details?type=rollover_401',
  '/transfer-account/40x',
  '/upload/rollovers',
  '/upload/rollovers?id=',
]

export const getExclusionsByTier = (tier) =>
  [...EXCLUSIONS_BY_TIER.all, ...(EXCLUSIONS_BY_TIER[tier] || [])].reduce(
    (acc, cur) => Object.assign(acc, { [cur]: true }),
    {}
  )

export const getExclusionsByUser = (user, exclusion) =>
  exclusion in get(user, 'personMembership.exclusions', {})

export const allowMembership = (user) => get(user, 'personMembership.active', false)

export const getRestrictedUrlForMembership = ({ pathname, search = null }) =>
  MEMBERSHIP_RESTRICTED_URLS.includes(search ? `${pathname}?${search}` : pathname)

export const getMembershipTierPosition = (user) =>
  get(user, 'personMembership.membershipTier.tierSetPosition', null)

export const getMembershipTierUniqueKey = (user) =>
  get(user, 'personMembership.membershipTier.uniqueKey', null)

export const hasTier1 = (user) => getMembershipTierPosition(user) === 1
