import { CancellationState } from 'app/requests/cancellation'

export const isStartedCancelState = (state: CancellationState): boolean =>
  state === CancellationState.STARTED ||
  state === CancellationState.AUTOMATIC_CANCELATION ||
  state === CancellationState.NEEDS_REVIEW

export const isScheduledCancelState = (state: CancellationState): boolean =>
  state === CancellationState.SCHEDULED

export const isReminderScheduledCancelState = (state: CancellationState): boolean =>
  state === CancellationState.REMINDER_SCHEDULED

export const isCancelFinishedState = (state: CancellationState): boolean =>
  state === CancellationState.FINISHED
