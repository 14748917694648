/* eslint-disable camelcase */
import { clientAppRequest, formatData } from './requests'

export const fetchMembershipTiers = () => clientAppRequest('/api/v2/membership_tiers')

export const saveMembershipTier = (uniqueKey) =>
  clientAppRequest('/api/v1/person_memberships', 'POST', {
    body: formatData({
      person_membership: {
        membership_tier_unique_key: uniqueKey,
      },
    }),
  })

export const updateMembershipTier = (uniqueKey) =>
  clientAppRequest('/api/v1/person_memberships/me', 'PUT', {
    body: formatData({
      person_membership: {
        membership_tier_unique_key: uniqueKey,
      },
    }),
  })

export const migrateToMembershipTier = (membershipTierKey, couponCode, billingIntervalUnit) => {
  const data = {
    person_membership: {
      membership_tier_unique_key: membershipTierKey,
    },
  }

  if (billingIntervalUnit) {
    data.person_membership.billing_plan_interval_unit = billingIntervalUnit
  }

  if (couponCode) {
    data['coupon_code'] = couponCode
  }

  return clientAppRequest(
    '/api/v2/membership_transitions',
    'POST',
    {
      body: formatData(data),
    },
    true,
    true
  )
}

export const acceptAgreements = (couponCode, billingIntervalUnit) => {
  const client = { enroll_in_membership: true }

  if (billingIntervalUnit) {
    client.billing_plan_interval_unit = billingIntervalUnit
  }

  if (couponCode) {
    client['coupon_code'] = couponCode
  }

  return clientAppRequest('/api/v1/clients/me', 'PUT', {
    body: formatData({
      client,
    }),
  })
}

export const changeMembershipByTierKey = (uniqueKey) =>
  clientAppRequest('/api/v2/membership_changes', 'POST', {
    body: formatData({ membership_tier_unique_key: uniqueKey }),
  })

export const getBillingDetails = () => clientAppRequest('/api/v2/membership_billing', 'GET')

export const validateCouponCode = (couponCode, intervalUnit, membershipTierUniqueKey) => {
  const params = new URLSearchParams({
    billing_plan_interval_unit: intervalUnit,
    membership_tier_unique_key: membershipTierUniqueKey,
  })
  const request = `/api/v1/coupons/${couponCode}${params && `?${params}`}`

  return clientAppRequest(request, 'GET')
}

export const claimCancelCoupon = () =>
  clientAppRequest('/api/v1/person_memberships/me/claim_cancel_coupon', 'POST')

export const saveCancelationSurvey = (membership_cancellation_reason) =>
  clientAppRequest('/api/v2/membership_cancellation_reasons', 'POST', {
    body: formatData({ membership_cancellation_reason }),
  })
