export const formatClient = (data, included = '') => {
  const mappedRelationships = {}
  for (const rel in data.relationships) {
    const relation = data.relationships[rel].data

    if (relation && included) {
      const relationship = included.filter(incl => incl.id === relation.id).shift()
      if (relationship) {
        mappedRelationships[rel] = relationship.attributes
      }
    }
  }

  return {
    id: data.id,
    ...data.attributes,
    ...mappedRelationships,
  }
}
