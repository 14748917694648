/* eslint-disable camelcase */
import {
  IAccountInfo,
  IFinancialPlanInfo,
  IMembershipCreationData,
} from 'app/redux/onboarding/schema'
import { clientAppRequest, formatData } from './requests'

export const fetchFraudDetection = () =>
  clientAppRequest('/api/v2/onboarding/fraud_detection/me', 'GET')

export const fetchOnboardingProgress = () =>
  clientAppRequest('/api/v2/onboarding/progress/me', 'GET')

export const updateOnboardingProgress = (step: string) =>
  clientAppRequest('/api/v2/onboarding/progress/me', 'PUT', {
    body: formatData({
      onboardingStep: step,
    }),
  })

export const fetchAccountInfo = () => clientAppRequest('/api/v2/onboarding/account_info/me', 'GET')

export const createAccountInfo = (data: IAccountInfo) =>
  clientAppRequest('/api/v2/onboarding/account_info', 'POST', {
    body: formatData(data),
  })

export const fetchFinancialPlanInfo = () =>
  clientAppRequest('/api/v2/onboarding/financial_plans/me', 'GET')

export const createFinancialPlanInfo = (data: IFinancialPlanInfo) =>
  clientAppRequest('/api/v2/onboarding/financial_plans/me', 'PUT', {
    body: formatData(data),
  })
// Plan code may change in the future, /api/v2/onboarding/billing_plans/:plan_code
export const fetchBillingPlans = () =>
  clientAppRequest('/api/v2/onboarding/billing_plans/ellevest_plan_may_2023', 'GET')

export const createMembership = (data: IMembershipCreationData) =>
  clientAppRequest('/api/v2/onboarding/memberships', 'POST', {
    body: formatData(data),
  })

export const validatePromoCode = (couponCode: string) =>
  clientAppRequest(`/api/v2/onboarding/coupon_codes/${couponCode}`, 'GET')
