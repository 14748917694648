const PWM_UPLOAD_STATEMENTS = '/pwm/upload-statements'
const PWM_LOADING = '/pwm'
const PWM_ONBOARDING = '/pwm/onboarding/user'
export const PWM_ONBOARDING_IDENTITY_VERIFICATION = `/pwm/onboarding/identity-verification`
export const PWM_ONBOARDING_IDENTITY_VERIFICATION_HELP = `/pwm/onboarding/identity-verification/help`
export const PWM_ACCOUNT_SETUP_CONFIRMATION = '/pwm/onboarding/account-setup-confirmation'

const routesMap = {
  PWM_UPLOAD_STATEMENTS,
  PWM_LOADING,
  PWM_ONBOARDING,
  PWM_ONBOARDING_IDENTITY_VERIFICATION,
  PWM_ONBOARDING_IDENTITY_VERIFICATION_HELP,
  PWM_ACCOUNT_SETUP_CONFIRMATION,
}

export default routesMap
