export default {
  RETIREMENT_GOALS: 'retirementGoals',
  INVESTMENT_GOALS: 'investmentGoals',
  ACCOUNTS: 'accounts',
  CASHFLOWS: 'cashflows',
  AUTO_DEPOSIT: 'autoDeposit',
  EXTERNAL_ACCOUNTS: 'externalAccounts',
  HOLDINGS: 'holdings',
  SECURITY_WEIGHTINGS: 'securityWeightings',
  AGGREGATE_SECURITY_WEIGHTINGS: 'aggregateSecurityWeightings',
  MODELS: 'models',
  GOAL_PROJECTION_YEARS: 'goalProjectionYears',
  ACCOUNT_PROJECTION_YEARS: 'accountProjectionYears',
  ROLLOVERS: 'rollovers',
  FUNDING_DEPOSIT_FLOWS: 'fundingDepositFlows',
  FUNDING_TRANSFER_IRA_FLOWS: 'fundingTransferIRAFlows',
  FUNDING_TRANSFER_BROKERAGE_FLOWS: 'fundingTransferBrokerageFlows',
  FUNDING_ROLLOVER_401K_FLOWS: 'fundingRollover401kFlows',
  FUNDING_INTERNAL_TRANSFER_FLOWS: 'internalTransfer',
  FUNDING_STAGE_DATA: 'fundingStageData',
  ORIGIN_ACCOUNTS: 'originAccounts',
  IRA_CONTRIBUTION_SUMMARIES: 'iraContributionSummaries',
}
