import { call, put, select, take, takeEvery, all } from 'redux-saga/effects'
import { redirect } from 'redux-first-router'
import { get } from 'lodash'

import { closeModal } from 'app/actions/modals'
import { SET_USER } from 'app/actions/actionTypes'

import { getCancellationWorkflowState } from 'app/requests/cancellation'

import { setCancellationState } from 'app/redux/cancellationState/reducer'

import { routesIndex as R } from 'app/routes/index'
import {
  FRAUD_RESTRICTED_ROUTES,
  CANCELLATION_SCHEDULED_RESTRICTED_ROUTES,
} from 'app/routes/restrictedRoutes'

// Already migrated to Redux Toolkit
import watchForeignAccount from 'app/redux/foreignAccount/sagas'
import { watchLoadGlobal } from 'app/redux/global/sagas'
import watchMembershipTiers from 'app/redux/membershipTiers/sagas'
import watchNotifications from 'app/redux/notifications/sagas'
import watchOnboarding from 'app/redux/onboarding/sagas'
import { routeChange } from 'app/redux/routes/reducer'
import watchSleeveHistoryData from 'app/redux/sleeveHistory/sagas'
import watchTesting from 'app/redux/testing/sagas'
import watchTransactionSummary from 'app/redux/transactionSummary/sagas'

// Still needs Redux Toolkit migration
import watchAuth from 'app/sagas/auth/auth'
import watchClient from 'app/sagas/client'
import watchInvestmentPlan from 'app/sagas/investmentPlan/investmentPlan'
import watchMembership from 'app/sagas/membership/membership'
import watchComposedActions from 'app/sagas/composed'

import { memberIsFraudRestricted } from 'app/utils/user'
import { isScheduledCancelState } from 'app/utils/cancellation'

import { updateSift } from 'js/sift'

// the payload here is the route
export const onRouteChange = function* ({ payload }) {
  let {
    auth: { user },
    cancellationState: { currentState },
  } = yield select()

  if (!user) {
    yield take(SET_USER)
    ;({
      auth: { user },
    } = yield select())
  }

  // canStartCancelWorkflow is false if there is an active workflow
  // we want to fetch the currentState if there is an active workflow but no
  // currentState value
  if (!currentState && !get(user, 'user_state.canStartCancelWorkflow', false)) {
    const cancellationState = yield call(getCancellationWorkflowState)
    yield put(setCancellationState(cancellationState))
    ;({
      cancellationState: { currentState },
    } = yield select())
  }

  const scheduledCancellationRestriction =
    isScheduledCancelState(currentState) &&
    Object.keys(CANCELLATION_SCHEDULED_RESTRICTED_ROUTES).indexOf(payload) !== -1

  const actionIsRestricted =
    memberIsFraudRestricted(user) && Object.keys(FRAUD_RESTRICTED_ROUTES).indexOf(payload) !== -1

  // Redirects selected routes when member has restriction
  if (actionIsRestricted || scheduledCancellationRestriction) {
    yield put(
      redirect({
        type: R.DASHBOARD_OVERVIEW,
      })
    )
  } else {
    // Close any open modal
    const getOpenModals = (state) => state.modals.openModals
    const modalNames = yield select(getOpenModals)

    if (modalNames.length) {
      for (let i = 0; i < modalNames.length; i++) {
        yield put(closeModal(modalNames[i]))
      }
    }

    // Notifies Sift of route change
    yield call(updateSift)
  }
}

const watchRouteChanged = function* () {
  yield takeEvery(routeChange.type, onRouteChange)
}

export default function* startForeman() {
  yield all([
    call(watchAuth),
    call(watchClient),
    call(watchComposedActions),
    call(watchForeignAccount),
    call(watchMembershipTiers),
    call(watchInvestmentPlan),
    call(watchLoadGlobal),
    call(watchMembership),
    call(watchNotifications),
    call(watchOnboarding),
    call(watchTesting),
    call(watchTransactionSummary),
    call(watchSleeveHistoryData),
    call(watchRouteChanged),
  ])
}
