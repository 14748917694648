import makeRoutesIndex from 'app/routes/makeRoutesIndex'
import { setAccounts } from 'app/actions/investmentPlan'
import { getAccounts } from 'app/requests/investmentPlan'
import { formatAccounts } from 'app/utils/accounts'

/* Loads investing accounts if missing */
const loadAccounts = async (dispatch, getState) => {
  const {
    investmentPlan: { accounts },
  } = getState()

  if (accounts && accounts.length) return
  await getAccounts('holdings,cashflows,auto_deposits').then(data => {
    const mappedAccounts = formatAccounts(data.data, data.included)
    dispatch(setAccounts(mappedAccounts))
  })
}

export const DASHBOARD_OVERVIEW = '/dashboard'
const DASHBOARD_CARD_SUMMARY = '/dashboard/account_summary/:accountType?'
const DASHBOARD_EDIT_GOAL = '/dashboard/:goalType/edit'


// TODO: Review route on ELVST-24034
const DASHBOARD_GOAL = {
  path: '/dashboard/:goal/:tab?/:action?',
  thunk: (dispatch, getState) => loadAccounts(dispatch, getState),
}


const routesMap = {
  DASHBOARD_OVERVIEW,
  DASHBOARD_CARD_SUMMARY,
  DASHBOARD_EDIT_GOAL,
  DASHBOARD_GOAL,
}

export default routesMap

export const routesIndex = makeRoutesIndex(routesMap)
