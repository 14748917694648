import { select, put, all, takeLatest } from 'redux-saga/effects'
import { routeChange } from 'app/redux/routes/reducer'

import { getLocationQuery } from 'app/selectors/location'
import { objectHasKeyEqualTo } from 'app/utils/misc'

import { forceCipFailure } from './reducer'

export const onShouldForceCipFailure = function*() {
  try {
    const query = yield select(getLocationQuery)

    // We're checking the string 'true' instead of the boolean true
    // because redux-first-router stores all query param values as strings
    if (query && objectHasKeyEqualTo(query, 'force_cip_failure', 'true')) {
      yield put(forceCipFailure())
    }
  } catch (error) {
    console.warn('Error forcing CIP failure', error)
  }
}

export const watchTesting = function*() {
  yield all([yield takeLatest(routeChange.type, onShouldForceCipFailure)])
}

export default watchTesting
