import Big from 'big.js'
import resourceTypes from 'app/content/resourceTypes'
import { depositOrder, INVESTMENT_ACCOUNT, all as ACCOUNT_TYPES } from 'app/content/accountTypes'
import { get, find } from 'lodash'

export const isIRAAccount = (account) => {
  const accountType = account.account_type || account.accountType
  const iraAccountTypes = ['roth_ira_account', 'traditional_ira_account', 'sep_ira_account']

  return iraAccountTypes.indexOf(accountType) > -1
}

export const is40xAccount = (account) => {
  const accountType = account.account_type || account.accountType
  const account40xTypes = [
    'account_401k',
    'account_403b',
    'roth_401k_account',
    'traditional_401k_account',
    'roth_403b_account',
    'traditional_403b_account',
  ]

  return account40xTypes.indexOf(accountType) > -1
}

export const getLabelByAccountType = (accountType, defaultLabel = '') =>
  get(
    find(ACCOUNT_TYPES, (obj) => obj.key === accountType),
    'label',
    defaultLabel
  )

export const formatAccount = (data, included) => {
  if (
    data.type !== resourceTypes.ACCOUNTS &&
    data.type !== resourceTypes.EXTERNAL_ACCOUNTS &&
    data.type !== resourceTypes.CASHFLOWS &&
    data.type !== resourceTypes.AUTO_DEPOSIT
  ) {
    // TODO throw error: invalid type
    return null
  }

  const mappedRelationships = {}

  for (const rel in data.relationships) {
    const relationshipData = data.relationships[rel].data
    // util
    const getResource = (id, type) => included.find((i) => i.type === type && i.id === id)

    if (Array.isArray(relationshipData) && relationshipData.length) {
      // We are provisionally extracting here the autodeposits data from the new autoDeposits
      // relationship, which is arriving as an array
      // We are then forcing the data under the old relationship key ('autodeposit') for front end clarity.
      // TODO: Revise nce API filters autoDeposit in the back
      if (rel === 'autoDeposits') {
        const [autoDepositData] = relationshipData
        const resource = getResource(autoDepositData.id, autoDepositData.type)

        if (resource) {
          mappedRelationships.autoDeposit = formatResource(resource)
        }
      } else {
        mappedRelationships[rel] = relationshipData.map((r) => {
          const resource = getResource(r.id, r.type)

          if (resource) {
            return formatResource(resource)
          }

          return r
        })
      }
    } else if (Object.keys(relationshipData).length) {
      const resource = getResource(relationshipData.id, relationshipData.type)

      if (resource) {
        mappedRelationships[rel] = formatResource(resource)
      }
    }
  }

  return {
    id: data.id,
    isExternal: data.type === resourceTypes.EXTERNAL_ACCOUNTS,
    ...data.attributes,
    ...mappedRelationships,
  }
}

export const formatAccounts = (data = [], included = []) =>
  data.map((a) => formatAccount(a, included))

export const formatRawAccounts = (includedData) => {
  let accounts = []
  if (includedData) {
    accounts = includedData.filter(
      (a) => a.type === resourceTypes.ACCOUNTS || a.type === resourceTypes.EXTERNAL_ACCOUNTS
    )
  }
  return formatAccounts(accounts)
}

export const formatResource = (resource) => {
  switch (resource.type) {
    case resourceTypes.HOLDINGS:
      return formatHolding(resource)
    case resourceTypes.CASHFLOWS:
      return formatCashflows(resource)
    case resourceTypes.AUTO_DEPOSIT:
      return formatAutoDeposit(resource)
    default:
      return resource
  }
}

export const formatHolding = (data) => {
  if (data.type !== resourceTypes.HOLDINGS) {
    // TODO throw error: invalid type
    return null
  }

  return {
    id: data.id,
    ...data.attributes,
  }
}

export const formatCashflows = (data) => {
  if (data.type !== resourceTypes.CASHFLOWS) {
    return null
  }

  return {
    id: data.id,
    ...data.attributes,
  }
}

export const formatAutoDeposit = ({ attributes, ...data }) => {
  if (data.type !== resourceTypes.AUTO_DEPOSIT) {
    return null
  }

  return {
    ...data,
    ...attributes,
  }
}

export const getAccountBalance = (account) => {
  if (account.holdings) {
    return account.holdings.reduce(
      (acc, h) => (h.marketValue ? acc.plus(h.marketValue) : acc),
      new Big(0)
    )
  } else {
    return new Big(0)
  }
}

export const getAccountCashBalance = (account) => getHoldings(account)

export const getAccountSecuritiesBalance = (account) => getHoldings(account)

const getHoldings = (account) => {
  if (account.holdings) {
    return account.holdings
      .filter((h) => h.ticker !== 'FDIC.CASH')
      .reduce((acc, h) => (h.marketValue ? acc.plus(h.marketValue) : acc), new Big(0))
  } else {
    return new Big(0)
  }
}

export const sortAccounts = (accounts) => {
  const accountOrder = depositOrder.map((a) => a.key)
  return accounts.sort((a, b) => {
    const aRank = accountOrder.indexOf(a.accountType)
    const bRank = accountOrder.indexOf(b.accountType)
    // if same account type, show Ellevest accounts before outside accounts
    if (aRank === bRank) return a.isExternal ? 1 : -1
    return aRank < bRank ? -1 : 1
  })
}

export const isEmployeeIRAAccount = (account) => {
  const accountType = account.account_type || account.accountType
  const employeeIRAAccountTypes = ['roth_ira_account', 'traditional_ira_account']

  return employeeIRAAccountTypes.indexOf(accountType) > -1
}

export const isSepIRAAccount = (account) => {
  const accountType = account.account_type || account.accountType
  return accountType === 'sep_ira_account'
}

export const getAccountLabel = (account) => {
  if (!account.account_type) return ''

  return account.account_type
    .replace(/_/g, ' ')
    .replace('ira', 'IRA')
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const getGoalByInvestingAccount = (account, goals) => {
  const goal = goals.find((goal) => goal.goal_id === account.goalId)

  return goal || {}
}

export const getAccountsByGoalId = (goalId, accounts) =>
  accounts.filter((acc) => acc.goalId === goalId) || []

export const hasInvestmentAccount = (selectedGoals = []) =>
  selectedGoals.some((goal) => {
    if (!goal || !goal.ellevest_accounts || !goal.ellevest_accounts.length) return false
    return goal.ellevest_accounts.some((acc) => acc.account_type === INVESTMENT_ACCOUNT.key)
  })

export const isRetirementAccount = (account) =>
  isIRAAccount(account) ||
  is40xAccount(account) ||
  account.account_type === 'other_tax_deferred_account'
