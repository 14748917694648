import { redirect } from 'redux-first-router'

const DOCUMENT_UPLOADER_401K = {
  path: '/upload/401k',
  thunk: dispatch => {
    dispatch(
      redirect({
        type: 'DOCUMENT_UPLOADER',
        payload: { type: 'rollovers' },
      })
    )
  },
}

export const DOCUMENT_UPLOADER_ROOT = '/upload'
export const DOCUMENT_UPLOADER = `${DOCUMENT_UPLOADER_ROOT}/:type?`
export const DOCUMENT_UPLOADER_BANKING = `${DOCUMENT_UPLOADER_ROOT}/banking`
export const DOCUMENT_UPLOADER_IDENTITY = `${DOCUMENT_UPLOADER_ROOT}/identity`
export const VERIFICATION_STATION = '/verification'
export const MEMBERSHIP_RESTRICTED = `${DOCUMENT_UPLOADER_ROOT}/membership_restricted`

export default {
  DOCUMENT_UPLOADER_401K,
  DOCUMENT_UPLOADER,
  VERIFICATION_STATION,
  MEMBERSHIP_RESTRICTED,
}
