import { redirect } from 'redux-first-router'

const ROOT = {
  path: '/',
  thunk: (dispatch) => {
    // TODO - if user is logged in, display dashboard
    dispatch(redirect({ type: 'SIGN_IN' }))
  },
}

const SIGN_IN = '/people/sign_in'
const SIGN_OUT = '/people/sign_out'
const SIGN_UP = '/onboarding'

const PEOPLE = '/people'

const REDEEM_TOKEN = {
  path: '/redeem/:token',
  thunk: (dispatch) => {
    dispatch(redirect({ type: 'SIGN_UP' }))
  },
}

export default {
  PEOPLE,
  ROOT,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  REDEEM_TOKEN,
}
