import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  accountInfo: {
    firstName: '',
    middleName: '',
    lastName: '',
    zipcode: '',
    phoneNumber: '',
  },
  financialPlanInfo: {
    dateOfBirth: '',
    gender: '',
    educationLevel: '',
    maritalStatus: '',
    currentSalary: '',
    householdIncome: '',
    children: '',
  },
  billingFrequencyInfo: {
    recurlyPlanCode: '',
    couponCode: '',
  },
  paymentInfo: {
    paymentMethod: '',
    paymentToken: undefined,
  },
  investing: {
    enableImpactPortfolio: false,
    monthlyDeposit: undefined,
  },
  isLoading: false,
  isOnboardingDataSuccess: false,
  isOnboardingDataFailed: false,
  membership: {
    madlibsInfo: {
      onboarding_state: 'madlibs',
    },
    isEditingPersonalInformation: false,
  },
  billingPlans: undefined,
  billingPlansSuccess: false,
  billingPlansFailed: false,
  onboardingStep: undefined,
  onboardingStepSuccess: false,
  onboardingStepFailed: false,
  promoCode: '',
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentOnboardingInvestingGoal(state, action) {
      state.investing.currentGoal = action.payload
    },
    toggleEnableImpactPortfolio(state) {
      state.investing.enableImpactPortfolio = !state.investing.enableImpactPortfolio
    },
    setInvestingMonthlyDeposit(state, action) {
      const monthlyDeposit = action.payload
      state.investing.monthlyDeposit = monthlyDeposit
    },
    removeInvestingMonthlyDeposit(state) {
      state.investing.monthlyDeposit = undefined
    },
    removeImpactPortfolio(state) {
      state.investing.enableImpactPortfolio = false
    },
    addToMadlibsInfo(state, action) {
      state.membership.madlibsInfo = {
        ...state.membership.madlibsInfo,
        ...action.payload,
      }
    },
    setPromoCode(state, action) {
      state.promoCode = action.payload
    },
    clearPromoCode(state) {
      state.promoCode = ''
    },
    setIsEditingPersonalInformation(state, action) {
      state.membership.isEditingPersonalInformation = action.payload
    },
    // OV2...
    getOnboardingData(state) {
      return {
        ...state,
        isLoading: true,
        isOnboardingDataSuccess: false,
        isOnboardingDataFailed: false,
      }
    },
    setOnboardingDataSuccess(state, action) {
      const [accountInfo = {}, financialPlanInfo = {}] = action.payload || []

      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...accountInfo,
        },
        financialPlanInfo: {
          ...state.financialPlanInfo,
          ...financialPlanInfo,
        },
        isLoading: false,
        isOnboardingDataSuccess: true,
        isOnboardingDataFailed: false,
      }
    },
    setOnboardingDataFailed(state) {
      return {
        ...state,
        isLoading: false,
        isOnboardingDataSuccess: false,
        isOnboardingDataFailed: true,
      }
    },
    setBillingFrequencyInfoSuccess(state, action) {
      const billingFrequencyInfo = action.payload

      return {
        ...state,
        billingFrequencyInfo: {
          ...state.billingFrequencyInfo,
          ...billingFrequencyInfo,
        },
      }
    },
    setAccountInfoSuccess(state, action) {
      const accountInfo = action.payload

      // TODO: Revise endpoint so it always returns phone
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...accountInfo,
          phoneNumber: accountInfo.phoneNumber || state.accountInfo.phoneNumber,
        },
      }
    },
    setPaymentInfoSuccess(state, action) {
      const paymentInfo = action.payload

      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          ...paymentInfo,
        },
      }
    },
    setFinancialPlanInfoSuccess(state, action) {
      const financialPlanInfo = action.payload

      return {
        ...state,
        financialPlanInfo: {
          ...state.financialPlanInfo,
          ...financialPlanInfo,
        },
      }
    },
    getBillingPlans(state) {
      return {
        ...state,
        isLoading: true,
        billingPlansSuccess: false,
        // if this is true, display error modal on checkout form.
        billingPlansFailed: false,
      }
    },
    setBillingPlansSuccess(state, action) {
      const billingPlans = action.payload

      return {
        ...state,
        billingPlans,
        billingPlansSuccess: true,
        billingPlansFailed: false,
        isLoading: false,
      }
    },
    setBillingPlansFailed(state) {
      return {
        ...state,
        billingPlansFailed: true,
        isLoading: false,
      }
    },
    getOnboardingStep(state) {
      return {
        ...state,
        isLoading: true,
        onboardingStepSuccess: false,
        onboardingStepFailed: false,
      }
    },
    setOnboardingStep(state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    setOnboardingStepSuccess(state, action) {
      const onboardingStep = action.payload

      return {
        ...state,
        onboardingStep,
        onboardingStepSuccess: true,
        onboardingStepFailed: false,
        isLoading: false,
      }
    },
    setOnboardingStepFailed(state) {
      return {
        ...state,
        isLoading: false,
        onboardingStepSuccess: false,
        onboardingStepFailed: true,
      }
    },
  },
})

export const {
  setCurrentOnboardingInvestingGoal,
  toggleEnableImpactPortfolio,
  setInvestingMonthlyDeposit,
  removeInvestingMonthlyDeposit,
  removeImpactPortfolio,
  addToMadlibsInfo,
  setPromoCode,
  clearPromoCode,
  setIsEditingPersonalInformation,
  getOnboardingData,
  setOnboardingDataSuccess,
  setOnboardingDataFailed,
  setPaymentInfoSuccess,
  setAccountInfoSuccess,
  setBillingFrequencyInfoSuccess,
  setFinancialPlanInfoSuccess,
  getBillingPlans,
  setBillingPlansSuccess,
  setBillingPlansFailed,
  getOnboardingStep,
  setOnboardingStep,
  setOnboardingStepSuccess,
  setOnboardingStepFailed,
} = onboardingSlice.actions

export default onboardingSlice.reducer
