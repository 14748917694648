const authenticationErrorTypes = {
  NO_USER: 'NO_USER',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_RESTRICTED: 'USER_RESTRICTED',
  USER_NOT_CONFIRMED: 'USER_NOT_CONFIRMED',
  USER_NOT_CLIENT: 'USER_NOT_CLIENT',
  COMPROMISED_PWD_FAILURE: 'compromised_pwd_failure',
}

const transactionErrorTypes = {
  USER_NOT_ESIGNED: 'USER_NOT_ESIGNED',
  GOAL_NOT_FUNDED: 'GOAL_NOT_FUNDED',
  ACCOUNT_NOT_ESIGNED: 'ACCOUNT_NOT_ESIGNED',
  MISSING_BANK_LINK: 'MISSING_BANK_LINK',
  IRA_MAXED_OUT: 'IRA_MAXED_OUT',
}

const investmentPlanErrorTypes = {
  PLAN_NOT_FOUND: 'PLAN_NOT_FOUND',
  EMPTY_PROJECTIONS: 'EMPTY_PROJECTIONS',
}

const fundingErrorTypes = {
  FUNDING_FLOW_NOT_FOUND: 'FUNDING_FLOW_NOT_FOUND',
  NEEDS_CIP_VERIFICATION: 'NEEDS_CIP_VERIFICATION',
  CIP_RESTRICTED: 'CIP_RESTRICTED',
  FOLIO_REJECTED_SSN: 'FOLIO_REJECTED_SSN',
  FOLIO_NETWORK_ERROR: 'FOLIO_NETWORK_ERROR',
  INVALID_FLOW_DROPOFF: 'INVALID_FLOW_DROPOFF',
}

export default {
  ...authenticationErrorTypes,
  ...transactionErrorTypes,
  ...investmentPlanErrorTypes,
  ...fundingErrorTypes,
}

export const AuthErrorCodes = {
  COMPROMISED_PWD_FAILURE: 'compromised_pwd_failure',
  DEACTIVATED: 'deactivated',
  LAST_ATTEMPT: 'last_attempt',
  INACTIVE_PWM_USER: 'inactive_pwm_user',
  INVALID: 'invalid',
  LOCKED: 'locked',
  LOCKED_BY_PASSWORD: 'locked_by_password',
  NEARING_LOCKOUT: 'nearing_lockout',
  NOT_FOUND_IN_DATABASE: 'not_found_in_database',
  TWO_FACTOR_AUTH_FAILURE: 'two_factor_auth_failure',
}

export class EVError extends Error {
  constructor(type) {
    super(`EVError: ${type}`)
    this.name = 'EVError'
    this.type = type
  }
}

export class AuthError extends Error {
  constructor(type, ...params) {
    super(`AuthError: ${type}`)
    this.name = 'AuthError'
    this.type = type
    this.details = params
  }
}

export class InvestmentPlanError extends Error {
  constructor(type) {
    super(`InvestmentPlanError: ${type}`)
    this.name = 'InvestmentPlanError'
    this.type = type
  }
}

export class TransactionError extends Error {
  constructor(type) {
    super(`TransactionError: ${type}`)
    this.name = 'TransactionError'
    this.type = type
  }
}

export class ProjectionsError extends Error {
  constructor(type) {
    super(`ProjectionsError: ${type}`)
    this.name = 'ProjectionsError'
    this.type = type
  }
}

export class FundingError extends Error {
  constructor(type) {
    super(`FundingError: ${type}`)
    this.name = 'FundingError'
    this.type = type
  }
}

export class APIError extends Error {
  constructor(type) {
    super(`APIError: ${type}`)
    this.name = 'APIError'
    this.type = type
  }
}

export class JsonAPIError extends Error {
  constructor(type, params) {
    super(`APIError: ${type}`)
    this.name = 'APIError'
    this.type = type
    this.details = params
  }
}
