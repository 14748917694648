// easing functions http://goo.gl/5HLl8
Math.easeInOutQuad = function (t, b, c, d) {
  // eslint-disable-next-line no-param-reassign
  t /= d / 2
  if (t < 1) {
    return (c / 2) * t * t + b
  }
  // eslint-disable-next-line
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

Math.easeInCubic = function (t, b, c, d) {
  // eslint-disable-next-line no-param-reassign
  const tc = (t /= d) * t * t
  return b + c * tc
}

Math.inOutQuintic = function (t, b, c, d) {
  // eslint-disable-next-line no-param-reassign
  const ts = (t /= d) * t
  const tc = ts * t
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc)
}

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (() =>
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60)
  })()

const animateScroll = (start, change, moveFn, callback, duration) => {
  let currentTime = 0
  const increment = 20

  const animate = () => {
    // increment the time
    currentTime += increment
    // find the value with the quadratic in-out easing function
    const val = Math.easeInOutQuad(currentTime, start, change, duration)
    // move the document.body
    moveFn(val)
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animate)
    } else {
      if (callback && typeof callback === 'function') {
        // the animation is done so lets callback
        callback()
      }
    }
  }
  animate()
}

export const scrollTo = (to, callback, duration = 500) => {
  const move = (amount) => {
    document.documentElement.scrollTop = amount
    document.body.parentNode.scrollTop = amount
    document.body.scrollTop = amount
  }
  const position = () =>
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop

  const start = position()
  const change = to - start

  animateScroll(start, change, move, callback, duration)
}

export const scrollToElement = (el, to, callback, duration = 500) => {
  const move = (amount) => {
    el.scrollTop = amount
  }

  const start = el.scrollTop
  const change = to - start
  animateScroll(start, change, move, callback, duration)
}
