import { push } from 'redux-first-router'
import { SETTINGS_PATHS } from 'app/constants/settingsConstants'

export const ACCOUNTS_ROOT = '/accounts'
export const SETTINGS_ROOT = '/settings'

const SETTINGS_DOCUMENTS = `${SETTINGS_ROOT}/documents/:path?`

export const MY_SERVICE = `${SETTINGS_ROOT}/my-service`
const SETTINGS_MY_SERVICE = `${SETTINGS_ROOT}/my-service/:path?`

const SETTINGS = {
  path: `${SETTINGS_ROOT}/:path?`,
  thunk: (dispatch, getState) => {
    const { location } = getState()
    // redirects to login settings by deafult
    if (!location.payload.path) push(`${SETTINGS_ROOT}/${SETTINGS_PATHS.LOGIN}`)
    // redirects to investing documents when statements url is hit
    if (location.payload.path === SETTINGS_PATHS.STATEMENTS) {
      push(`${SETTINGS_ROOT}/documents/${SETTINGS_PATHS.INVESTING_DOCUMENTS}`)
    }
  },
}

export default {
  SETTINGS,
  SETTINGS_DOCUMENTS,
  SETTINGS_MY_SERVICE,
}
