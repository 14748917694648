import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lastActivationEvent: null,
}

const activationSlice = createSlice({
  name: 'activation',
  initialState,
  reducers: {
    setLastActivationEvent(state, action) {
      state.lastActivationEvent = action.payload.activationEvent
    },
  },
})

export const { setLastActivationEvent } = activationSlice.actions

export default activationSlice.reducer
