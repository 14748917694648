import { createSlice } from '@reduxjs/toolkit'

import { IExperimentsReducer } from './schema'

export const initialState: IExperimentsReducer = {
  experiments: [],
}

const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    setExperiments(state, action) {
      return {
        ...state,
        experiments: [...action.payload],
      }
    },
  },
})

export const { setExperiments } = experimentsSlice.actions

export default experimentsSlice.reducer
