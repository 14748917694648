import { applyMiddleware, combineReducers, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { createWhitelistFilter } from 'redux-persist-transform-filter'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { connectRoutes } from 'redux-first-router'
import restoreScroll from 'redux-first-router-restore-scroll'
import queryString from 'query-string'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import routes, { routesIndex as R } from 'app/routes/index'

import { scrollTo } from 'js/elementScrollTo'

// Already migrated to Redux Toolkit
import activation from 'app/redux/activation/reducer'
import client from 'app/redux/client/reducer'
import experiments from 'app/redux/experiments/reducer'
import foreignAccount from 'app/redux/foreignAccount/reducer'
import global, { loadGlobalData } from 'app/redux/global/reducer'
import membershipTiers from 'app/redux/membershipTiers/reducer'
import notifications from 'app/redux/notifications/reducer'
import onboarding from 'app/redux/onboarding/reducer'
import routesReducer, { routeChange } from 'app/redux/routes/reducer'
import sleeveHistory from 'app/redux/sleeveHistory/reducer'
import system from 'app/redux/system/reducer'
import testing from 'app/redux/testing/reducer'
import transactionSummary from 'app/redux/transactionSummary/reducer'
import cancellationState from 'app/redux/cancellationState/reducer'

// Still needs Redux Toolkit migration
import auth from 'app/reducers/auth'
import modals from 'app/reducers/modals'
import investmentPlan from 'app/reducers/investmentPlan'
import draftPlan from 'app/reducers/draftPlan'
import funding from 'app/reducers/funding'
import planner from 'app/reducers/planner'
import rootSaga from 'app/sagas'
import dashboard from 'app/reducers/dashboard'
import page from 'js/analytics/page'

const onBeforeChange = (dispatch, getState, actionData) => {
  const { type } = actionData.action

  dispatch(routeChange(type))
}

// Callback that happens *after* route has changed
const onAfterChange = () => {
  page()
  // eslint-disable-next-line
  if (window._mfq && Array.isArray(window._mfq)) {
    window._mfq.push(['newPageView'])
  }
}

// Scroll behavior (animated on Dashboard)
const onRouteScroll = (prev, locationState) => {
  if (window.location.hash) {
    return false
  }
  if (prev.type === R.DASHBOARD_GOAL && locationState.type === R.DASHBOARD_GOAL) {
    // eslint-disable-next-line
    scrollTo( 0, () => {}, 400)

    return false
  }

  return true
}

const reduxRouter = connectRoutes(routes, {
  restoreScroll: restoreScroll({
    shouldUpdateScroll: onRouteScroll,
  }),
  onBeforeChange,
  onAfterChange,
  querySerializer: queryString,
  // Disable automatic initial dispatch here so we can handle it with sagas!
  initialDispatch: false,
})

const rootReducer = combineReducers({
  system,
  auth,
  cancellationState,
  experiments,
  notifications,
  transactionSummary,
  modals,
  investmentPlan,
  draftPlan,
  foreignAccount,
  funding,
  global,
  planner,
  dashboard,
  membershipTiers,
  routes: routesReducer,
  location: reduxRouter.reducer,
  client,
  testing,
  onboarding,
  activation,
  sleeveHistory,
})
const sagaMiddleware = createSagaMiddleware()
const middlewares = [reduxRouter.middleware, sagaMiddleware]

// Turn on Redux console logging
// eslint-disable-next-line no-undef
if (process.env.REDUX_LOGGER) {
  const logger = createLogger({
    collapsed: true,
  })

  middlewares.push(logger)
}

const persistConfig = {
  key: 'root',
  storage,
  // TODO: update the below language when redux-persist is updated
  // see https://github.com/rt2zz/redux-persist/pull/1213
  stateReconciler: autoMergeLevel2,
  whitelist: ['onboarding', 'system'],
  transforms: [
    createWhitelistFilter('onboarding', ['investing', 'promoCode']),
    createWhitelistFilter('system', [
      'restrictionModalsSeen',
      'lastInviteAFriendModalSession',
      'sessionCount',
    ]),
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  enhancers: [compose(reduxRouter.enhancer, applyMiddleware(...middlewares))],
  // We'll disable the serializableCheck for now since we have a lot of work to
  // do to clean that up and we don't want the logs polluting the console during testing
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
})

sagaMiddleware.run(rootSaga)
// Manually trigger the initial dispatch here so we can handle it with sagas!
reduxRouter.initialDispatch()

store.dispatch(loadGlobalData())

export const persistor = persistStore(store)
export default store
