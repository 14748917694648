import { createSlice } from '@reduxjs/toolkit'
import { CancellationState } from 'app/requests/cancellation'

export interface ICancellationState {
  currentState: CancellationState
}

export const initialState: ICancellationState = {
  currentState: null,
}

const cancellationStateSlice = createSlice({
  name: 'cancellationState',
  initialState,
  reducers: {
    setCancellationState(state, action) {
      const { payload } = action

      return {
        ...state,
        currentState: payload.aasm_state,
      }
    },
  },
})

export const { setCancellationState } = cancellationStateSlice.actions

export default cancellationStateSlice.reducer
