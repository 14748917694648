import update from 'immutability-helper'
import { handleUserIncludedData } from 'app/utils/auth'

import { amendUserFolioAccount } from 'app/utils/formattingUtils'

import * as C from 'app/actions/actionTypes'

const defaultState = {
  user: null,
  initialDataLoaded: false,
  isLoggedIn: false,
  authFlowOpen: false,
  authFlowSuccessHandler: null,
}

const auth = (state = defaultState, action) => {
  switch (action.type) {
    case C.AUTH_FAILED: {
      // Authentication was attempted and failed
      const newState = update(state, {
        initialDataLoaded: {
          $set: true,
        },
        isLoggedIn: {
          $set: false,
        },
        user: {
          $set: null,
        },
      })

      return newState
    }

    case C.SET_USER: {
      // Authentication succeeded
      const { user } = action
      // Map to legacy user object
      // Over time this will become unnecessary
      const { data, included } = user
      const {
        employmentDetails,
        firstName,
        lastName,
        middleName,
        personalDetails,
      } = data.attributes

      let newUser = {
        user_id: data.id,
        first_name: data.attributes.firstName,
        full_name: data.attributes.fullName,
        email: data.attributes.email,
        invites: data.attributes.invites,
        unconfirmedEmail: data.attributes.unconfirmedEmail,
        phoneNumber: data.attributes.phoneNumber,
        person: {
          employment: { ...employmentDetails },
          details: { ...personalDetails },
          firstName,
          lastName,
          middleName,
        },
        user_state: {
          canStartOver: data.attributes.canStartOver,
          hasESigned: data.attributes.hasEsigned,
          confirmed_email: data.attributes.isConfirmed,
          is_client: data.attributes.isClient,
          isEllevestMember: data.attributes.isEllevestMember,
          canUseStatements: data.attributes.hasEsigned,
          canUseRollovers: true,
          needsIdentityUpload: data.attributes.needsIdentityUpload,
          cip_status: data.attributes.cipStatus,
          inRolloverFlow: data.attributes.inRolloverFlow,
          rewardAmount: data.attributes.rewardAmount,
          promotionAmount: data.attributes.promotionAmount,
          finraRestricted: data.attributes.finraRestricted,
          existsWithFolio: data.attributes.existsWithFolio,
          fundingStarted: data.attributes.fundingStarted,
          flowType: data.attributes.flowType,
          authenticationFeatureEnabled: data.attributes.authenticationFeatureEnabled,
          phoneNumberUnconfirmed: data.attributes.phoneNumberUnconfirmed,
          isEllevestClient: data.attributes.isEllevestClient,
          membershipRestriction: data.attributes.membershipRestriction,
          isEnrolledInCashBack: data.attributes.isEnrolledInCashBack,
          canStartCancelWorkflow: data.attributes.canStartCancelWorkflow,
        },
        promotionDetails: data.attributes.promotionDetails || null,
        ira_deposit_limit: data.attributes.iraDepositLimit,
        sep_ira_deposit_limit: data.attributes.sepIRADepositLimit,
        account_40x_limit: data.attributes.account40xLimit,
        featureFlags: data.attributes.featureFlags,
        selectedBillingAccessLevel: data.attributes.selectedBillingAccessLevel,
        currentAccessLevel: data.attributes.currentAccessLevel,
        canModifyBillingAccess: data.attributes.canModifyBillingAccess,
        referral_source: data.attributes.referralSource,
        registeringAppType: data.attributes.registeringAppType,
        this_is_redux: true,
        mfaPreference: data.attributes.mfaPreference,
      }

      // Map folio member data to resemble old folioMember values
      if (included && included.length) {
        newUser = handleUserIncludedData(newUser, included)
      }

      const newState = update(state, {
        user: {
          $set: newUser,
        },
        isLoggedIn: {
          $set: true,
        },
        initialDataLoaded: {
          $set: true,
        },
      })

      return newState
    }

    case C.UPDATE_USER_SUCCESS: {
      const { newData } = action

      return {
        ...state,
        user: Object.assign({}, state.user, newData),
      }
    }

    case C.UPDATE_FOLIO_ACCOUNT_SUCCESS: {
      const { account } = action

      const folioMember = state.user && state.user.folioMember

      if (folioMember) {
        const user = amendUserFolioAccount(state.user, folioMember, account)

        if (user) {
          return {
            ...state,
            user,
          }
        }
      }

      return state
    }

    case C.OPEN_AUTH_FLOW: {
      const { successHandler } = action

      return {
        ...state,
        authFlowOpen: true,
        authFlowSuccessHandler: successHandler,
      }
    }

    case C.CLOSE_AUTH_FLOW: {
      return {
        ...state,
        authFlowOpen: false,
        authFlowSuccessHandler: null,
      }
    }

    default:
      return state
  }
}

export default auth
