import { setAccounts } from 'app/actions/investmentPlan'
import { getAccounts } from 'app/requests/investmentPlan'
import { formatAccounts } from 'app/utils/accounts'

/* Loads investing accounts if missing */
const loadAccounts = async (dispatch) => {
  // get latest from investing accounts
  await getAccounts('holdings,cashflows,auto_deposits').then(async (data) => {
    const mappedAccounts = await formatAccounts(data.data, data.included)
    await dispatch(setAccounts(mappedAccounts))
  })
}

const CENTRALIZED_FUNDING_ONE_TIME_TRANSFER = '/one-time-transfer'
const CENTRALIZED_FUNDING_RECURRING_DEPOSITS = {
  path: '/auto-deposits',
}
const CENTRALIZED_FUNDING_RECURRING_DEPOSIT = {
  path: '/auto-deposits/:depositType',
  thunk: (dispatch, getState) => loadAccounts(dispatch, getState),
}

const CENTRALIZED_FUNDING_DIRECT_DEPOSIT = '/direct-deposit'

export default {
  CENTRALIZED_FUNDING_ONE_TIME_TRANSFER,
  CENTRALIZED_FUNDING_RECURRING_DEPOSITS,
  CENTRALIZED_FUNDING_RECURRING_DEPOSIT,
  CENTRALIZED_FUNDING_DIRECT_DEPOSIT,
}
