import { deattribute, deserialise } from 'kitsu-core/legacy'
import { get, camelCase, snakeCase } from 'lodash'

const applyResourceAdapter = (resource) => {
  switch (resource.type) {
    default:
      return resource
  }
}

const applyCasingFormat = (target, keyFormatter) => {
  const valueFormatter = (value) => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.map(valueFormatter)
      } else if (value) {
        // Object
        return applyCasingFormat(value, keyFormatter)
      }
    }

    return value
  }

  return Object.entries(target).reduce(
    (result, [key, value]) => Object.assign(result, { [keyFormatter(key)]: valueFormatter(value) }),
    {}
  )
}

export const useCamelCasing = (target) => applyCasingFormat(target, camelCase)

export const useSnakeCasing = (target) => applyCasingFormat(target, snakeCase)

export const formatResource = async (data) => {
  const formattedData = await deattribute(data)

  if (Array.isArray(formattedData)) {
    return formattedData.map((d) => applyResourceAdapter(d))
  } else {
    return applyResourceAdapter(formattedData)
  }
}

export const formatResourceWithIncluded = async (data) => {
  const formattedData = await deserialise(data)

  const dataItems = formattedData.data

  if (Array.isArray(dataItems)) {
    return dataItems.map((d) => applyResourceAdapter(d))
  } else {
    return applyResourceAdapter(dataItems)
  }
}

// JsonAPI
export const getAttributesFromResponse = (response) => get(response.data, 'attributes', {})

export const getAttributesArrayFromResponse = (response) =>
  response.data.map((d) => get(d, 'attributes', {}))

// New endpoint errors will arrive in this format:
// { code: "<invalid">, source: { pointer: <input name>}, detail?: "customer-facing message"}
// getMappedErrors will map them using the pointer as key
// { <input name>: { code: "<invalid">, detail?: "" }}
export const getMappedErrors = (error) => {
  let map = {}
  if (error.details) {
    const { details } = error
    for (const error of details) {
      const { source, code, ...restError } = error
      const { pointer, ...restSource } = source || {}
      const errorValues = { ...restError, ...restSource, code }
      if (!pointer) {
        map.general = errorValues
      } else if (!map[pointer]) {
        map[pointer] = errorValues
      }
    }
  } else {
    // Need to decide the format of how the error shows up on the form
    map = { general: { code: JSON.stringify(error) } }
  }

  return map
}
