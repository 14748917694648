import { all, call, put, takeLatest } from 'redux-saga/effects'

import { REFRESH_APP, FETCH_USER_AND_INVESTMENT_PLAN } from 'app/actions/actionTypes'

import { getUser } from 'app/requests/auth'
import { setUser } from 'app/actions/auth'

import { getPlan } from 'app/requests/investmentPlan'
import { setInvestmentPlan } from 'app/actions/investmentPlan'
import { onFetchAccounts } from 'app/sagas/investmentPlan/investmentPlan'

import { onFetchNotifications } from 'app/redux/notifications/sagas'
import { onGetForeignAccount } from 'app/redux/foreignAccount/sagas'

export const onFetchUserAndInvestmentPlan = function* () {
  try {
    const [user, investmentPlan] = yield all([call(getUser), call(getPlan)])

    yield put(setUser(user))
    yield put(setInvestmentPlan(investmentPlan))
  } catch (error) {
    console.warn('Error fetching user and investment plan')
    yield
  }
}

export const onRefreshApp = function* ({ callback }) {
  yield all([
    onFetchUserAndInvestmentPlan(),
    onFetchAccounts(),
    onFetchNotifications(),
    onGetForeignAccount(),
  ])

  if (callback) callback()
}

const watchComposedActions = function* () {
  yield all([
    yield takeLatest(FETCH_USER_AND_INVESTMENT_PLAN, onFetchUserAndInvestmentPlan),
    yield takeLatest(REFRESH_APP, onRefreshApp),
  ])
}

export default watchComposedActions
