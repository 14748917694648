import { snakeCase } from 'lodash'
import { isMobile, isTablet } from 'react-device-detect'
import Cookies from 'js-cookie'
import store from 'app/store'
import page from 'js/analytics/page'

// eslint-disable-next-line no-nested-ternary
const getDeviceType = () => (isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop')
// This was required by VWO, an A/B testing platform to receive Segment events
const getVWOUuid = () => Cookies.get('_vwo_uuid')

const identify = (userId, traits = {}, options = {}, callback = () => {}) => {
  const Segment = window.analytics

  try {
    traits.vwo_uuid = getVWOUuid()
    Segment.identify(userId, traits, options, callback)
  } catch (e) {
    console.warn('Analytics error: identify attempted, Segment not found')
    callback()
  }
}

const track = (
  event,
  properties = {},
  options = {},
  callback = () => {},
  enforceSnakeCasePropertyKeys = false
) => {
  const Segment = window.analytics
  properties['event_name'] = event

  try {
    const state = store.getState()
    const {
      auth,
      experiments,
      location: { pathname },
    } = state

    // TODO: only use Redux variables once entire app is in Redux
    const deviceType = getDeviceType()
    const user = auth.user || window.user
    const userExperiments =
      experiments.experiments.length > 0 ? experiments.experiments : window.experiments || []
    const liveExperiments = userExperiments.reduce((obj, ex) => {
      obj[`${ex.experiment_name}`] = ex.variant_id
      return obj
    }, {})

    let trackData = {
      page: pathname,
      userService: user && (user.currentAccessLevel || user.selectedBillingAccessLevel),
      userIsClient: user && user.user_state.hasESigned,
      deviceType,
      vwo_uuid: getVWOUuid(),
      ...properties,
      ...liveExperiments,
    }

    if (enforceSnakeCasePropertyKeys) {
      const trackDataSnakeCaseKeys = {}
      Object.entries(trackData).forEach(([key, value]) => {
        trackDataSnakeCaseKeys[snakeCase(key)] = value
      })
      trackData = trackDataSnakeCaseKeys
    }

    Segment.track(event, trackData, options, callback)
  } catch (e) {
    console.warn('Analytics error: track attempted, Segment not found', event)
    callback()
  }
}

export default {
  identify,
  track,
  page,
}
