import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { ISleeveHistory } from './schema'

export const initialState: ISleeveHistory = {}

const getErrorState = (action) => {
  const status = get(action, 'payload.response.status')

  switch (status) {
    case 503:
      return { showMaintenanceState: true }
    default:
      return { showErrorState: true }
  }
}

const sleeveHistorySlice = createSlice({
  name: 'sleeveHistory',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchSleeveHistoryData(state, _action) {
      return {
        ...state,
        [`${_action.payload}`]: {
          loading: true,
          loaded: false,
        },
      }
    },
    setSleeveHistoryData(state, action) {
      const { payload } = action
      const { sleeveHistoryData, accountId } = payload

      return {
        ...state,
        [`${accountId}`]: {
          accountId,
          sleeveHistoryData,
          loaded: true,
          loading: false,
        },
      }
    },
    setSleeveHistoryDataFailure(state, _action) {
      const errorState = getErrorState(_action)
      const { accountId } = _action.payload

      return {
        ...state,
        [`${accountId}`]: {
          accountId,
          loaded: false,
          loading: false,
          ...errorState,
        },
      }
    },
  },
})

export const {
  fetchSleeveHistoryData,
  setSleeveHistoryData,
  setSleeveHistoryDataFailure,
} = sleeveHistorySlice.actions

export default sleeveHistorySlice.reducer
