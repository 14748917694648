/*
  Restricted routes are used for redirections
  Not to be included on routes index!
*/
import centralizedFundingRoutes from './centralizedFundingRoutes'
import fundRoutes, { fundFraudRestrictedRoutes } from './fundRoutes'
import { miscFraudRestrictedRoutes } from './miscRoutes'
import onboardingInvestingRoutes, { retirementRoutes } from './onboardingInvestingRoutes'
import learnRoutes from './learnRoutes'

export const FRAUD_RESTRICTED_ROUTES = {
  ...centralizedFundingRoutes,
  ...fundFraudRestrictedRoutes,
  ...miscFraudRestrictedRoutes,
  ...onboardingInvestingRoutes,
  ...learnRoutes,
}

export const CANCELLATION_SCHEDULED_RESTRICTED_ROUTES = {
  ...fundRoutes,
  ...onboardingInvestingRoutes,
  ...retirementRoutes,
}
