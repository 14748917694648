import * as C from 'app/actions/actionTypes'

const defaultState = {
  fundingFlowType: null,
  fundingFlow: null,
  fundingData: null,
  originAccount: null,
  providers: null,
}

const funding = (state = defaultState, action) => {
  switch (action.type) {
    case C.SET_FUNDING_FLOW_TYPE: {
      const { fundingFlowType } = action

      return {
        ...state,
        fundingFlowType,
      }
    }

    case C.SET_FUNDING_FLOW: {
      const { data } = action

      return {
        ...state,
        fundingFlow: data,
      }
    }

    case C.UPDATE_FUNDING_DATA: {
      const { data } = action
      return {
        ...state,
        fundingData: data,
      }
    }

    case C.RESET_FUNDING_DATA: {
      return {
        ...defaultState,
      }
    }

    case C.SET_ORIGIN_ACCOUNT: {
      const { account } = action

      return {
        ...state,
        originAccount: account,
      }
    }

    case C.SET_PROVIDERS: {
      const { providers } = action

      return {
        ...state,
        providers,
      }
    }

    default:
      return state
  }
}

export default funding
