import { clientAppRequest } from 'app/requests/requests'
import moment from 'moment'

const emptyQuery = {
  goal: '',
  transactionType: '',
  dateRange: {
    from: '',
    to: '',
  },
}

const formatDate = (date) => moment(date).format('YY-MM-DD')

export const queryString = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')

const formatQuery = ({ goal, transactionType, dateRange: { from, to } } = emptyQuery) => {
  const query = {}

  if (goal) query.account = goal

  if (transactionType) query['transaction_types'] = transactionType

  if (from && to) {
    query['date_range[from]'] = formatDate(from)
    query['date_range[to]'] = formatDate(to)
  }

  return query
}

export const fetchTransactionSummary = () =>
  clientAppRequest('/api/v2/clients/investment_transactions/summary')

export const filterTransactionsSummary = (query = emptyQuery) => {
  const params = formatQuery(query)
  return clientAppRequest(`/api/v2/clients/investment_transactions/summary?${queryString(params)}`)
}

export const fetchCompletedTransactionsSlice = (page, query, pageSize = 20) => {
  const params = {
    page,
    ['page_size']: pageSize,
    ...formatQuery(query),
  }

  return clientAppRequest(`/api/v2/clients/investment_transactions?${queryString(params)}`)
}

export const fetchRecentTransactions = (accountId) =>
clientAppRequest(`/api/v2/clients/investment_transactions/recent?account=${accountId}`)

export const cancelTransaction = (id, type) =>
  clientAppRequest(`/api/v2/transfers/${id}?transfer_type=${type}`, 'DELETE')
