/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, all } from 'redux-saga/effects'

import ErrorHelper from 'js/ErrorHelper'

import { fetchMembershipTiers } from 'app/requests/membership'
import { formatResourceWithIncluded } from 'app/requests/adapters/apiAdapter'

import { getMembershipTiers, setMembershipTiers, setMembershipTiersFailure } from './reducer'

const notifyError = (error) => {
  if (error.response) {
    error.response.json().then((errors) => {
      ErrorHelper.notifyError(errors)
    })
  } else {
    ErrorHelper.notifyError(error)
  }
}

export const onGetMembershipTiers = function* () {
  try {
    const response = yield call(fetchMembershipTiers)
    const formattedData = yield formatResourceWithIncluded(response)

    yield put(setMembershipTiers(formattedData))
  } catch (e) {
    notifyError(e)
    yield put(setMembershipTiersFailure)
  }
}

const watchMembershipTiers = function* () {
  yield all([takeLatest(getMembershipTiers.type, onGetMembershipTiers)])
}

export default watchMembershipTiers
