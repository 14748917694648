// Announcement notifications
export const DEBIT_CARD_SIGNUP_ANNOUNCEMENT = 'debit_card_signup_announcement'

// Alert notifications
export const VERIFICATION_NOT_RUN = 'verificationNotRun'
export const AWAITING_USER_DOCUMENTS = 'awaitingUserDocuments'
export const AWAITING_VERIFICATION = 'awaitingVerifications'
export const FINRA_RESTRICTED = 'finraRestricted'
export const MICRODEPOSITS_FORM = 'microdepositsForm'
export const AWAITING_MISSING_ACH_INFORMATION = 'awaiting_missing_ach_information'
export const MISSING_FOREIGN_ACCOUNT = 'missingForeignAccount'
export const SECONDARY_DEPOSIT_RECEIVED = 'secondary_deposit_received'
export const PLAID_NAME_MISMATCH = 'plaid_name_mismatch_alert'
export const PLAID_NAME_VERIFICATION_IN_PROGRESS = 'plaid_name_verification_in_progress'
export const MEMBERSHIP_CANCELED = 'membership_canceled_alert'

// Reminder notifications
export const DEPOSIT_TAX_REFUND = 'deposit_tax_refund'
export const MEMBERSHIP_TRANSITION_REMINDER = 'membership_transition_reminder'
export const COVID_MESSAGE = 'covid_message'
export const TIERED_REWARD_CLAIMED = 'tiered_reward_claimed'

// Cashback
export const CASHBACK_REMINDER = 'cashback_reminder'

export const NOTIFICATION_TYPES = {
  REMINDER: 'reminder',
  ALERT: 'alert',
  ANNOUNCEMENT: 'announcement',
}

export const NOTIFICATION_ACTION_TYPES = {
  WEB_MODAL: 'web_modal',
  WEB_SCREEN: 'web_screen',
  WEB_EXTERNAL_LINK: 'web_external_link',
}
