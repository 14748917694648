import * as C from 'app/actions/actionTypes'

const defaultState = {
  goalNavIsOpen: false,
  navigationGoal: null
}

const dashboard = (state = defaultState, action) => {
  switch (action.type) {
    case C.OPEN_GOAL_NAV: {
      const { navigationGoal } = action
      return {
        ...state,
        goalNavIsOpen: true,
        navigationGoal
      }
    }

    case C.CLOSE_GOAL_NAV: {
      return {
        ...state,
        goalNavIsOpen: false
      }
    }

    default:
      return state
  }
}

export default dashboard
