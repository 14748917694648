import * as C from './actionTypes'

export const fetchInvestmentPlan = legacy => ({
  type: C.FETCH_INVESTMENT_PLAN,
  payload: { legacy },
})

export const fetchInvestmentPlanFailed = () => ({
  type: C.FETCH_INVESTMENT_PLAN_FAILED,
})

export const setInvestmentPlan = plan => ({
  type: C.SET_INVESTMENT_PLAN,
  plan,
})

export const setInvestmentPlanInfo = info => ({
  type: C.SET_INVESTMENT_PLAN_INFO,
  info,
})

export const setExternalAccounts = externalAccounts => ({
  type: C.SET_EXTERNAL_ACCOUNTS,
  externalAccounts,
})

export const setExternalAccount = externalAccount => ({
  type: C.SET_EXTERNAL_ACCOUNT,
  externalAccount,
})

export const removeExternalAccount = id => ({
  type: C.REMOVE_EXTERNAL_ACCOUNT,
  id,
})

export const setGoals = goals => ({
  type: C.SET_GOALS,
  goals,
})

export const addGoalByType = goal => ({
  type: C.ADD_GOAL_BY_TYPE,
  goal,
})

export const removeGoalByType = goalType => ({
  type: C.REMOVE_GOAL_BY_TYPE,
  goalType,
})

export const removeGoal = id => ({
  type: C.REMOVE_GOAL,
  id,
})

export const moveGoal = (id, afterId) => ({
  type: C.MOVE_GOAL,
  id,
  afterId,
})

export const setGoal = goal => ({
  type: C.SET_GOAL,
  goal,
})

export const resetGoal = (id, accountIds = []) => ({
  type: C.RESET_GOAL,
  id,
  accountIds,
})

export const fetchAccounts = accounts => ({
  type: C.FETCH_ACCOUNTS,
  accounts,
})

export const setAccounts = accounts => ({
  type: C.SET_ACCOUNTS,
  accounts,
})

export const setAccount = account => ({
  type: C.SET_ACCOUNT,
  account,
})

export const updateGoalData = goal => ({
  type: C.UPDATE_GOAL_DATA,
  goal,
})

export const setModel = (model, id) => ({
  type: C.SET_MODEL,
  id,
  model,
})

export const setProjections = (projections, id) => ({
  type: C.SET_PROJECTIONS,
  id,
  projections,
})

export const resetProjections = () => ({
  type: C.RESET_PROJECTIONS,
})
