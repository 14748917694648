/* eslint-disable import/prefer-default-export */
/* eslint camelcase: [0, {ignoreDestructuring: true}] */
import { push } from 'redux-first-router'

import {
  AWAITING_USER_DOCUMENTS,
  AWAITING_VERIFICATION,
  FINRA_RESTRICTED,
  MICRODEPOSITS_FORM,
  AWAITING_MISSING_ACH_INFORMATION,
  MISSING_FOREIGN_ACCOUNT,
  NOTIFICATION_TYPES,
} from 'app/constants/notifications'

import { FOREIGN_ACCOUNT } from 'app/content/accountTypes'

import {
  hasForeignAccountData,
  isPendingForeignAccount,
  isMissingAchInformation,
} from 'app/utils/foreignAccount'
import { userRequiresInvestingDocs, usersInvestingVerificationInProcess } from 'app/utils/user'

import { VERIFICATION_STATION } from 'app/routes/uploaderRoutes'

export const formatNotifications = ({ data: { alerts, reminders, announcements } }) => ({
  alerts: mapAlertToType(alerts, NOTIFICATION_TYPES.ALERT),
  reminders: mapAlertToType(reminders, NOTIFICATION_TYPES.REMINDER),
  announcements: mapAlertToType(announcements, NOTIFICATION_TYPES.ANNOUNCEMENT),
})

const mapAlertToType = (notifications, type) => notifications.map((n) => ({ ...n, type }))

export const stateDerivedAlertsValidations = {
  [AWAITING_USER_DOCUMENTS]: ({ user }) => userRequiresInvestingDocs(user),

  [AWAITING_VERIFICATION]: ({ user }) =>
    !userRequiresInvestingDocs(user) && usersInvestingVerificationInProcess(user),

  [FINRA_RESTRICTED]: ({ user: { user_state: userState } }) => {
    if (userState) return userState.finraRestricted
    return false
  },

  [MICRODEPOSITS_FORM]: ({ foreignAccount }) =>
    hasForeignAccountData(foreignAccount) && isPendingForeignAccount(foreignAccount),

  [AWAITING_MISSING_ACH_INFORMATION]: ({ foreignAccount }) =>
    hasForeignAccountData(foreignAccount) && isMissingAchInformation(foreignAccount),

  [MISSING_FOREIGN_ACCOUNT]: ({ foreignAccount }) =>
    !foreignAccount || !hasForeignAccountData(foreignAccount),
}

export const stateDerivedNotificationHash = {
  [AWAITING_USER_DOCUMENTS]: () => ({
    type: NOTIFICATION_TYPES.ALERT,
    preventDismissal: true,
    title: 'Verify info to continue',
    ctaCopy: 'Upload document',
    testId: 'awaitingUserDocumentsLink_cta',
    ctaAction: () => push(VERIFICATION_STATION),
    description:
      'Upload a document to verify your personal info. Then you can finish setting up investing.',
  }),
  [AWAITING_VERIFICATION]: () => ({
    type: NOTIFICATION_TYPES.ALERT,
    preventDismissal: true,
    title: 'Verification in progress',
    ctaCopy: null,
    testId: 'awaitingVerification',
    ctaAction: undefined,
    description: `We got your documents and we’ll verify your personal info in 2-3 business days.`,
  }),
  [FINRA_RESTRICTED]: () => ({
    type: NOTIFICATION_TYPES.ALERT,
    preventDismissal: true,
    title: 'Signature Needed',
    ctaCopy: 'Do it now',
    testId: 'finraRestricted_cta',
    ctaAction: () =>
      window.location.assign(
        'https://support.ellevest.com/hc/en-us/articles/360009503233-How-can-I-get-my-Ellevest-account-approved-by-my-employer-s-compliance-team-'
      ),
    description: `Since you’re linked to the finance industry, we need another signature.`,
  }),
  [MICRODEPOSITS_FORM]: () => ({
    type: NOTIFICATION_TYPES.ALERT,
    preventDismissal: true,
    title: 'Verify Your Bank',
    ctaCopy: 'Verify bank',
    testId: 'microdepositsForm_cta',
    ctaAction: () => push('/settings/bank_account'),
    description: `Verify the two deposits in your linked ${FOREIGN_ACCOUNT.label}.`,
  }),
  [AWAITING_MISSING_ACH_INFORMATION]: () => ({
    type: NOTIFICATION_TYPES.ALERT,
    preventDismissal: true,
    title: 'Finish linking your bank',
    ctaCopy: 'Do it now',
    testId: 'microdepositsForm_cta',
    ctaAction: () => push(VERIFICATION_STATION),
    description: `Upload a statement from your bank account.`,
  }),
  [MISSING_FOREIGN_ACCOUNT]: () => ({
    type: NOTIFICATION_TYPES.ALERT,
    preventDismissal: true,
    title: 'Connect a bank account',
    ctaCopy: 'Do it now',
    testId: 'missingBankLink_cta',
    ctaAction: () => push('/settings/bank_account'),
    description: 'Connect an external bank to make deposits into your accounts.',
  }),
}

export const reminderNotificationGetterByProps = {}

export const getStateDerivedAlerts = (props) => {
  const stateDerivedAlerts = []

  for (const type in stateDerivedAlertsValidations) {
    if (stateDerivedAlertsValidations[type](props)) {
      stateDerivedAlerts.push({ ...stateDerivedNotificationHash[type](props), baseType: type })
    }
  }

  return stateDerivedAlerts
}

export default formatNotifications
