import * as C from './actionTypes'

export const getUser = user => ({
  type: C.GET_USER,
  user,
})

export const setUser = user => ({
  type: C.SET_USER,
  user,
})

export const updateUserSuccess = newData => ({
  type: C.UPDATE_USER_SUCCESS,
  newData,
})

export const authFailed = () => ({
  type: C.AUTH_FAILED,
})

export const openAuthFlow = successHandler => ({
  type: C.OPEN_AUTH_FLOW,
  successHandler,
})

export const closeAuthFlow = () => ({
  type: C.CLOSE_AUTH_FLOW,
})

export const updateFolioAccount = account => ({
  type: C.UPDATE_FOLIO_ACCOUNT_SUCCESS,
  account,
})

export const authSuccess = () => ({
  type: C.AUTH_SUCCESS,
})
