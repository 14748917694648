import makeRoutesIndex from 'app/routes/makeRoutesIndex'

export const MEMBERSHIP_ROOT = '/membership'
export const CANCELATION_ROOT = `${MEMBERSHIP_ROOT}/cancelation`
export const DOWNGRADE_ROOT = `${MEMBERSHIP_ROOT}/downgrade`
export const UPGRADE_ROOT = `${MEMBERSHIP_ROOT}/upgrade`

export const MEMBERSHIP_CANCELATION_INTRO = `${CANCELATION_ROOT}/intro`
export const MEMBERSHIP_CANCELATION_SUCCESS = `${CANCELATION_ROOT}/success`
export const MEMBERSHIP_CANCELATION_ERROR = `${CANCELATION_ROOT}/error`
export const MEMBERSHIP_CANCELATION_PROMOTION = `${CANCELATION_ROOT}/promotion`
export const MEMBERSHIP_CANCELATION_PROMOTION_SUCCESS = `${MEMBERSHIP_CANCELATION_PROMOTION}/success`
export const MEMBERSHIP_CANCELATION_PROMOTION_ERROR = `${MEMBERSHIP_CANCELATION_PROMOTION}/error`
export const MEMBERSHIP_CANCELATION_SURVEY = `${CANCELATION_ROOT}/survey`
export const MEMBERSHIP_ELIGIBILITY = `${CANCELATION_ROOT}/eligibility`
export const MEMBERSHIP_DOWNGRADE_LOADING = `${DOWNGRADE_ROOT}/loading`

export const MEMBERSHIP_UPGRADE_INTRO = `${UPGRADE_ROOT}/intro`
export const MEMBERSHIP_UPGRADE_ERROR = `${UPGRADE_ROOT}/error`
export const MEMBERSHIP_UPGRADE_SUCCESS = `${UPGRADE_ROOT}/success`

// enhanced cancellation flow
export const MEMBERSHIP_CANCELLATION_IRA_OVERVIEW = `${CANCELATION_ROOT}/IRA/overview`
export const MEMBERSHIP_CANCELLATION_IRA_WITHDRAW = `${CANCELATION_ROOT}/IRA/withdraw`
export const MEMBERSHIP_CANCELLATION_CHECKLIST_STEP_ONE = `${CANCELATION_ROOT}/delete-recurring`
export const MEMBERSHIP_CANCELLATION_CHECKLIST_STEP_TWO = `${CANCELATION_ROOT}/cancel-direct-deposits`
export const MEMBERSHIP_CANCELLATION_CHECKLIST_STEP_THREE = `${CANCELATION_ROOT}/withdraw-balance`
export const MEMBERSHIP_CANCELLATION_CHECKLIST_SUBMIT = `${CANCELATION_ROOT}/submit`

const routesMap = {
  MEMBERSHIP_CANCELATION_INTRO,
  MEMBERSHIP_CANCELATION_SUCCESS,
  MEMBERSHIP_CANCELATION_ERROR,
  MEMBERSHIP_CANCELATION_PROMOTION,
  MEMBERSHIP_CANCELATION_PROMOTION_SUCCESS,
  MEMBERSHIP_CANCELATION_PROMOTION_ERROR,
  MEMBERSHIP_CANCELATION_SURVEY,
  MEMBERSHIP_ELIGIBILITY,
  MEMBERSHIP_DOWNGRADE_LOADING,
  MEMBERSHIP_UPGRADE_INTRO,
  MEMBERSHIP_UPGRADE_ERROR,
  MEMBERSHIP_UPGRADE_SUCCESS,
  MEMBERSHIP_CANCELLATION_IRA_OVERVIEW,
  MEMBERSHIP_CANCELLATION_IRA_WITHDRAW,
  MEMBERSHIP_CANCELLATION_CHECKLIST_STEP_ONE,
  MEMBERSHIP_CANCELLATION_CHECKLIST_STEP_TWO,
  MEMBERSHIP_CANCELLATION_CHECKLIST_STEP_THREE,
  MEMBERSHIP_CANCELLATION_CHECKLIST_SUBMIT,
}

export default routesMap

export const routesIndex = makeRoutesIndex(routesMap)
