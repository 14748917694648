import 'whatwg-fetch'

/**
 * Traveller.js
 *
 * Thin wrapper around window.fetch for making async requests.
 * Using, whatwg-fetch as a polyfill for fetch
 *
 * - Handles getting and sending CSRF token from the document meta tag
 * - Throws error if fetch request fails
 * - Request is formatted in JSON and expects response body in JSON
 * - Tries to parse JSON response if present
 *
 * @type {string} url - required
 * @type {string} method - optional, default: 'GET'
 * @type {bool} crossOrigin - optional, default: false
 * @type {object} options - optional
 *
 * TODO - Add error handling if parseSuccessJSONResponse fails
 */

// Pre-flight
export const getCSRFToken = () => {
  const metaTags = document.getElementsByTagName('meta')
  for (let i = 0; i < metaTags.length; i++) {
    if (metaTags[i].getAttribute('name') === 'csrf-token') {
      return metaTags[i].getAttribute('content')
    }
  }

  return null
}

// Post-flight
export class FetchError extends Error {
  constructor(response) {
    super(`${response.url} - ${response.status}`)
    this.name = 'FetchError'
    this.response = response
  }
}

// global.fetch digests the 400s errors as part of the response
// without catching them, hence we want to check the response status
// to identify and manually trigger the errors
export const checkResponseStatus = (response) => {
  if (response.ok && response.status !== 299) {
    return response
  } else {
    throw new FetchError(response)
  }
}

// Stop gap solution for 20x responses with no body sent in the request
// This prevents a JSON parsing error thrown if no body
export const parseSuccessJSONResponse = (response) =>
  response.text().then((text) => (text ? JSON.parse(text) : {}))

// Exports
export const traveller = async ({ url, method = 'GET', options = {}, crossOrigin = false }) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  if (crossOrigin) {
    options.mode = 'cors'
    options.credentials = 'include'
  } else {
    headers['x-csrf-token'] = getCSRFToken()
    options.credentials = 'same-origin'
  }

  return await fetch(url, { method, headers, ...options })
    .then(checkResponseStatus)
    .then(parseSuccessJSONResponse)
}

export default traveller
