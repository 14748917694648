import { createSlice } from '@reduxjs/toolkit'

import { IForeignAccountReducer } from './schema'
import { getForeignAccountRestrictions } from './utils'

export const initialState: IForeignAccountReducer = {
  foreignAccount: undefined,
  foreignAccountLoaded: false,
  foreignAccountMissing: true,
  foreignAccountRestrictions: {
    restricted: false,
    restrictedBy: undefined,
    documentationGroup: undefined,
  },
  isLoading: false,
}

const foreignAccountSlice = createSlice({
  name: 'foreignAccount',
  initialState,
  reducers: {
    getForeignAccount(state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    getForeignAccountFailed(state) {
      return {
        ...state,
        isLoading: false,
      }
    },
    setForeignAccount(state, action) {
      const foreignAccount = action.payload
      let foreignAccountRestrictions = { ...state.foreignAccountRestrictions }
      if (foreignAccount) {
        foreignAccountRestrictions = getForeignAccountRestrictions(foreignAccount, foreignAccountRestrictions)
      }
      return {
        ...state,
        foreignAccount,
        foreignAccountLoaded: true,
        foreignAccountMissing: !foreignAccount,
        foreignAccountRestrictions,
        isLoading: false,
      }
    },
  },
})

export const {
  getForeignAccount,
  getForeignAccountFailed,
  setForeignAccount,
} = foreignAccountSlice.actions

export default foreignAccountSlice.reducer
