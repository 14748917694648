import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ITransactionSummary,
  ITransactionSummaryReducer,
  ITransactionRecord,
  IFilteredTransactions,
  IFilterParams,
} from './schema'

const initialState: ITransactionSummaryReducer = {
  upcomingTransactions: [],
  inProgressTransactions: [],
  completedTransactions: [],
  investmentsBreakdown: {
    totalBalance: 0,
    netReturns: 0,
    goalBalances: 0,
    outsideGoalSecurities: 0,
    outsideGoalsCash: 0,
  },
  loaded: false,
  loading: false,
  loadingMoreCompleted: false,
  completedTransactionsIndex: 1,
  totalCompletedPages: 0,
}

const transactionSummarySlice = createSlice({
  name: 'transactionSummary',
  initialState,
  reducers: {
    getTransactionSummary(state, _action: PayloadAction<IFilterParams>) {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    },
    getTransactionSummaryFailure(state, _action: PayloadAction<Record<string, unknown>>) {
      return { ...state, loading: true, loaded: false }
    },
    setTransactionSummary(state, action: PayloadAction<ITransactionSummary>) {
      return {
        ...state,
        ...action.payload,
        completedTransactionsIndex: 1,
        loading: false,
        loaded: true,
      }
    },
    filterTransactionSummary(state, _action: PayloadAction<IFilterParams>) {
      return { ...state, loading: true, loaded: false }
    },
    setFilteredTransactionSummary(state, action: PayloadAction<IFilteredTransactions>) {
      return {
        ...state,
        loading: false,
        loaded: true,
        ...action.payload,
        completedTransactionsIndex: 1,
      }
    },
    setFilteredTransactionSummaryFailure(state, _action: PayloadAction<Record<string, unknown>>) {
      return { ...state, loading: false, loaded: true }
    },
    getCompletedTransactionsSlice(state, _action: PayloadAction<IFilterParams>) {
      return { ...state, loadingMoreCompleted: true }
    },
    getCompletedTransactionsSliceFailure(state, _action: PayloadAction<Record<string, unknown>>) {
      return { ...state, loadingMoreCompleted: false }
    },
    appendCompletedTransactionsSlice(state, action: PayloadAction<Array<ITransactionRecord>>) {
      return {
        ...state,
        loadingMoreCompleted: false,
        completedTransactions: [...state.completedTransactions, ...action.payload],
        completedTransactionsIndex: state.completedTransactionsIndex + 1,
      }
    },
  },
})

export const {
  getTransactionSummary,
  getTransactionSummaryFailure,
  setTransactionSummary,
  getCompletedTransactionsSlice,
  appendCompletedTransactionsSlice,
  getCompletedTransactionsSliceFailure,
  filterTransactionSummary,
  setFilteredTransactionSummary,
  setFilteredTransactionSummaryFailure,
} = transactionSummarySlice.actions

export default transactionSummarySlice.reducer
