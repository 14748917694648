import { get } from 'lodash'

/** js */
import ErrorHelper from 'js/ErrorHelper'

import errorTypes, { AuthError, AuthErrorCodes, JsonAPIError } from 'app/content/errorTypes'

import routes from 'app/routes/index'

import { signOutAndRedirect, redirectToSignIn } from 'app/utils/auth'

import { traveller } from './traveller'

export const formatData = (data) => JSON.stringify(data)

const renewSession = (response) => {
  if (window.sessionHandler) {
    window.sessionHandler.renewSession()
  }

  return response
}

// See ticket: https://ellevest.atlassian.net/browse/ELVST-27322 for more information on how to test this function
export const parseResponseError = async (e) => {
  if (!e.response) {
    throw e
  }

  const status = get(e.response, 'status')
  const url = get(e.response, 'url')

  if (status === 500) {
    const body = get(e.response, 'body')

    ErrorHelper.notifyError(`Server Error with body: ${body}`, e.response)
    throw e
  }

  /** AUTH CUSTOM ERRORS */
  const hasUnauthorizedStatus = status === 401
  const hasUnprocessableEntityStatus = status === 422

  /*
   *  https://developer.mozilla.org/en-US/docs/Web/API/Response/json
   *  The json() method of the Response interface takes a Response stream and reads it to completion.
   *  It returns a promise which resolves with the result of parsing the body text as JSON.
   *  Caveat: json() can only be runned once on the response. Here we need to run it to see if we need to throw
   *  an authentication error. However, if it is not the case, we need to return the response intact, otherwise
   *  Belle won't be able to consume the error body. Hence here we need to clone the response
   **/
  const clonedResponse = e.response.clone()

  await clonedResponse.json().then((body) => {
    // error code...
    let errorCode = get(body, 'code') // errorcode invalid

    // JsonApi Enpoint errors
    if (status === 299) {
      const errorData = body && get(body, 'errors')

      throw new JsonAPIError(errorCode, errorData)
    }

    // gets error data
    const errorData = body && getErrorData(body)
    const hasStringErrors = typeof errorData === 'string'

    // checks for code inside body error
    if (errorData && errorData.code) {
      errorCode = errorData.code
    }

    // defines error type...
    const hasTwoFactorAuthFailure = errorCode === AuthErrorCodes.TWO_FACTOR_AUTH_FAILURE
    const hasAuthenticationErrorCode =
      errorCode && Object.values(AuthErrorCodes).includes(errorCode)

    // redundancy left in purpose for readability and proper handling of errors!
    // handles 401 errors...
    if (hasUnauthorizedStatus) {
      if (hasTwoFactorAuthFailure || hasStringErrors) {
        throw body
      } else {
        throw new AuthError(errorTypes.USER_LOGGED_OUT)
      }
    }
    // handles 422 with authentication errors
    if (hasUnprocessableEntityStatus && hasAuthenticationErrorCode) {
      if (url.includes(routes.SIGN_IN)) {
        throw body
      } else if (errorCode === AuthErrorCodes.DEACTIVATED) {
        throw new AuthError(errorTypes.USER_DEACTIVATED, errorData)
      } else {
        throw new AuthError(errorCode, errorData)
      }
    }

    // handles 422 errors for user creation (ex. compromised pass)
    if (hasUnprocessableEntityStatus && url.includes(routes.PEOPLE)) {
      throw body
    }

    if (!errorData || hasStringErrors) {
      throw body
    }

    // for any other error..
    throw e
  })
}

const getErrorData = (body) => {
  const error = get(body, 'error')
  const errors = get(body, 'errors')

  let bodyError = error

  if (errors) {
    if (errors instanceof Array) {
      bodyError = get(body, 'errors[0]')
    } else {
      bodyError = errors
    }
  }

  return bodyError
}

export const respondToError = (e, requireAuth, stopRedirectOnError) => {
  if (stopRedirectOnError) throw e

  const errorType = get(e, 'type')

  if (!errorType) {
    throw e
  }

  if (errorType === errorTypes.USER_DEACTIVATED) {
    signOutAndRedirect()
  }

  if (requireAuth && errorType === errorTypes.USER_LOGGED_OUT) {
    redirectToSignIn()
  }

  // Always continue error propagation
  throw e
}

export const request = async (url, method, options, requireAuth, stopRedirectOnError = false) => {
  const response = await traveller({ url, method, options })
    .then(renewSession)
    .catch(parseResponseError)
    .catch((e) => respondToError(e, requireAuth, stopRedirectOnError))

  return response
}

export const marketingAppRequest = (url, method, options, requireAuth = true) => {
  const marketingDomain = window.marketing_app_url

  return request(`${marketingDomain}${url}`, method, options, requireAuth)
}

export const clientAppRequest = (
  url,
  method,
  options,
  requireAuth = true,
  stopRedirectOnError = false
) => request(url, method, options, requireAuth, stopRedirectOnError)
