import * as C from './actionTypes'

export const openModal = (modalName, modalData = {}) => ({
  type: C.OPEN_MODAL,
  modalName,
  modalData,
})

export const closeModal = modalName => ({
  type: C.CLOSE_MODAL,
  modalName,
})
