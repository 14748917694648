/* eslint-disable camelcase */
import { clientAppRequest, formatData } from './requests'

interface MembershipEligibilityResponse {
  can_quick_cancel: boolean
  can_claim_promotion: boolean
}

export const validateMembershipCancellation = (): Promise<MembershipEligibilityResponse> =>
  clientAppRequest('/api/v1/person_memberships/me/valid_for_cancel', 'GET')

// quick cancel request
export const cancelMembership = (): Promise<string> =>
  clientAppRequest('/api/v1/person_memberships/me/cancel', 'PATCH')

export enum CancellationTicketType {
  IRA_CLOSURE = 'ira_closure',
}

// for users that are eligible to request cancellation via zendesk
// eligible users: non-funded IRA users & funded IRA users that have the ira_zendesk_cancellation ff enabled
// NOTE: this endpoint will be deprecated & replaced w/ setCancellationState
export const requestCancellation = (ticketType?: CancellationTicketType): Promise<string> =>
  clientAppRequest(
    `/api/v2/person_memberships/me/request_cancelation${
      ticketType ? `?ticket_type=${ticketType}` : ``
    }`,
    'POST'
  )

export enum CancellationState {
  REMINDER_SCHEDULED = 'reminder_scheduled',
  SCHEDULED = 'scheduled',
  STARTED = 'started',
  NEEDS_REVIEW = 'needs_review',
  AUTOMATIC_CANCELATION = 'automatic_cancelation',
  FINISHED = 'finished',
}

/**
 * Sets the user cancellation state to any of the accepted cancellation states
 * accepted states are: reminder_scheduled and started
 * @param cancellationState
 * @returns {}
 */
export const setCancellationState = (
  cancellationState: CancellationState,
  ticketType?: CancellationTicketType
): Promise<{ state: CancellationState; ticketType?: CancellationTicketType }> =>
  clientAppRequest('/api/v2/cancelation_workflows', 'POST', {
    body: formatData({ state: cancellationState, ticket_type: ticketType }),
  })

/**
 * Gets the users cancellations state
 * @returns {CancellationState}
 */
export const getCancellationWorkflowState = (): Promise<{ aasm_state: CancellationState }> =>
  clientAppRequest('/api/v2/cancelation_workflows/me', 'GET')
