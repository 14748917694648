/**
 * To add a new route:
 *
 * 1) Add route to the appopriate /routes/* file (organized by flow/layout). If
 * the route is a one-off it might be fine to add it directly to this index for
 * now.
 *
 * 2) Depending on which flow/layout the route belongs to, add a switch case to
 * app/RouteRenderer.jsx. This will handle rendering the high-level component
 * for the route inside the global Layout.jsx. If the route is a one-off then
 * you may just be rendering the new page component inside of this file.
 *
 * 3) If the route is being added to an existing flow/layout (such as
 * Onboarding, Funding Flow, Settings, etc.) it must be rendered inside of
 * that high-level component (ie., if adding a Settings page, inside of
 * Settings/App.jsx).
 */

import accountsRoutes from './accountsRoutes'
import authRoutes from './authRoutes'
import centralizedFundingRoutes from './centralizedFundingRoutes'
import dashboardRoutes from './dashboardRoutes'
import financialPlanningRoutes from './financialPlanningRoutes'
import fundRoutes from './fundRoutes'
import makeRoutesIndex from './makeRoutesIndex'
import membershipRoutes from './membershipRoutes'
import miscRoutes from './miscRoutes'
import onboardingRoutes from './onboardingRoutes'
import onboardingLegacyRoutes from './onboardingLegacyRoutes'
import onboardingInvestingRoutes from './onboardingInvestingRoutes'
import onboardingMembershipRoutes from './onboardingMembershipRoutes'
import settingsRoutes from './settingsRoutes'
import uploaderRoutes from './uploaderRoutes'
import updateInformationRoutes from './updateInformationRoutes'
import pwmRoutes from './pwmRoutes'
import learnRoutes from './learnRoutes'
import cancellationRoutes from './cancellationRoutes'
import firstGoalInvestingRoutes from './firstGoalInvestingRoutes'

const routesMap = {
  ...accountsRoutes,
  ...authRoutes,
  ...centralizedFundingRoutes,
  ...dashboardRoutes,
  ...financialPlanningRoutes,
  ...firstGoalInvestingRoutes,
  ...fundRoutes,
  ...membershipRoutes,
  ...miscRoutes,
  ...onboardingRoutes,
  ...onboardingLegacyRoutes,
  ...onboardingInvestingRoutes,
  ...onboardingMembershipRoutes,
  ...settingsRoutes,
  ...uploaderRoutes,
  ...updateInformationRoutes,
  ...pwmRoutes,
  ...learnRoutes,
  ...cancellationRoutes,
}

export default routesMap

export const routesIndex = makeRoutesIndex(routesMap)

/*
// LEGACY ROUTES
/dashboard
/dashboard/retirement_goal/edit
/dashboard/retirement_goal
/dashboard/retirement_goal/summary
/dashboard/retirement_goal/advice
/dashboard/retirement_goal/portfolio
/dashboard/retirement_goal/portfolio/edit
/dashboard/retirement_goal/portfolio/external_accounts
/dashboard/retirement_goal/portfolio/abc123
/dashboard/retirement_goal/transactions
/dashboard/retirement_goal/transactions/abc123
/dashboard/retirement_goal/settings
/dashboard/retirement_goal/settings/abc123
*/
